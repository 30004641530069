import { gql } from 'apollo-boost';

/* ========== Queries ========== */

export const EXPORT_REPORT = (date) => gql`
  {
    exportToExcel (data: { date: "${date}" }) {
      id
      __typename @include(if: false)
    }
  }
`;

export const DEVELOPER_ANALYSIS = (data) => gql`
  {
    developerAnalysis (data: { user_id: "${data.user_id}", client_id: "${data.client_id}", proj_id: "${data.proj_id}", date: { start: "${data.date.start}", end: "${data.date.end}" } }) {
      id
      point_nr
      instructions
      status
      time_estimate
      proj_name
      clientname
      timelogs {
        id
        point_id 
        hours_worked
        date_received
        __typename @include(if: false)
      }
      __typename @include(if: false)
    }
  }
`;

export const CURRENT_USER_QUERY = gql`
  {
    currentUser {
      id
      first_name
      last_name
      email
      role
      subscription_id
      update_seen
      first_tutorial
      active_security
      __typename @include(if: false)
    }
  }
`;

export const GROUP_QUERY = gql`
  {
    groups {
      id
      user_type_id
      name
      sort
      users {
        id
        first_name
        last_name
        sort
      }
      __typename @include(if: false)
    }
  }
`;

export const DASHBOARD_ANALAYSIS_QUERY = gql`
  {
    assignedTasks {
      id
      name
      sort
      group {
        id
        name
        sort
        __typename @include(if: false)
      }
      points {
        id
        proj_id
        proj_name
        client_id
        clientname
        total_time_estimate
        total_hours_used_individual
        open_status
        question_status
        old_open
        old_questions
        order
        proj_team_primary_id
        __typename @include(if: false)
      }
      project {
        id 
        points {
          id
          __typename @include(if: false)
        }
        __typename @include(if: false)
      }
      __typename @include(if: false)
    }

    unassignedTasks {
      id
      proj_id
      proj_name
      clientname
      total_time_estimate
      open_status
      question_status
      __typename @include(if: false)
    }

    unassignedProjects {
      id
      proj_name
      clientname
      project_lead
      date_created
      __typename @include(if: false)
    }
  }
`;

export const TIMELOGS_QUERY = (date_received) => gql`
  {
    timelogs (data: { date_received: "${date_received}" }) {
      value
      point_id
      point_nr
      user_id
      status
      priority
      proj_name
      client_name
      start_time
      end_time
      date_received
      instructions
      __typename @include(if: false)
    }
  }
`;

export const PLOTTED_DATES_QUERY = (date_received, isSummary) => gql`
  {
    plotted_dates (data: { date_received: "${date_received}", isSummary: "${isSummary}" }) {
      id
      dates_plotted
      date_received
      __typename @include(if: false)
    }
  }
`;

export const TIMELOGS_SUMMARY_QUERY = (date) => gql`
  {
    timelogsSummary (data: { date_start: "${date.start}", date_end: "${date.end}" }) {
      id
      name
      user_type_id
      timelogs {
        id
        point_id
        point_nr
        status
        title
        deadline
        priority
        creator
        instructions
        proj_id
        proj_name
        clientname
        hours_worked
        date_received
        __typename @include(if: false)
      }
      __typename @include(if: false)
    }
  }
`;


export const POINTS_TIME_LOG = (filter, status, offset, search) => gql`
  {
    feedbacks (filter: { id: ${filter.id}, type: "${filter.type}", isActivePoint: ${filter.isActivePoint}, time_log: ${filter.time_log}, priority: "${filter.priority}"}, status: "${status}", offset: ${offset}, search: "${search}") {
      id
      proj_id
      title
      proj_name
      clientname
      versions {
        id
        version_name
        points { 
          id
          feedback_id
          point_nr
          version_name
          version
          time_estimate
          hours_worked
          priority
          instructions
          status
          last_change {
            name
            description
          }
          __typename @include(if: false)
        }
        __typename @include(if: false)
      }
      __typename @include(if: false)
    }
  }
`;

export const FEEDBACKS_QUERY = (filter, status, offset, search) => gql`
  {
    feedbacks (filter: { id: ${filter.id}, type: "${filter.type}" }, status: "${status}", offset: ${offset}, search: "${search}") {
      id
      proj_id
      title
      proj_name
      clientname
      date_saved
      versions {
        id
        version_name
        points { 
          id
          feedback_id
          point_nr
          version_name
          version
          time_approve
          time_estimate
          hours_worked
          priority
          instructions
          inst_engtrans
          status
          deadline
          creator_id
          creator
          ack
          date
          date_created
          last_change {
            name
            description
          }
          files {
            id
            point_id
            filename
            __typename @include(if: false)
          }
          comments {
            id
            user_id
            type
            name
            comments
            date_commented
            project_role
            __typename @include(if: false)
          }
          team {
            id 
            name
            project_role
            is_working
            date_updated
            __typename @include(if: false)
          }
          __typename @include(if: false)
        }
        __typename @include(if: false)
      }
      __typename @include(if: false)
    }

    feedbacksCount (filter: { id: ${filter.id}, type: "${filter.type}" }, status: "${status}", search: "${search}") {
      totalCount
      __typename @include(if: false)
    }

    statusCount (filter: { id: ${filter.id}, type: "${filter.type}" }) {
      no_status
      status
      total_time_estimate
      __typename @include(if: false)
    }

    currentPage (page: ${offset}) {
      page
      __typename @include(if: false)
    }
  }
`;

export const FEEDBACK_COUNT_QUERY = (filter, status) => gql`
  {
    feedbacksCount (filter: { id: ${filter.id}, type: "${filter.type}" }, status: "${status}") {
      totalCount
      __typename @include(if: false)
    }
  } 
`;

export const TIMELOG_HISTORY = (id) => gql`
  {
    logHistory (id: ${id}) {
      id
      name
      hours_worked
      date_received
    }
  }
`;

export const LAST_CHANGE_HISTORY = (id) => gql`
  {
    changeHistory (id: ${id}) {
      id
      name
      description
      date_received
    }
  }
`;

export const FEEDBACK_QUERY = (id) => gql`
  {
    feedback (id: ${id}) {
      id
      title
      proj_id
      proj_name
      clientname
      date_saved
      creator_id
      creator
      versions {
        id
        version_name
        __typename @include(if: false)
      }
      team {
        id
        name
        email
        project_role
        __typename @include(if: false)
      }
      points {
        id
        point_nr
        feedback_id
        version_name
        version
        time_approve
        time_estimate
        hours_worked
        priority
        instructions
        inst_engtrans
        status
        deadline
        creator_id
        creator
        ack
        date
        date_created
        last_change {
          name
          description
        }
        files {
          id
          point_id
          filename
        }
        comments {
          id
          user_id
          type
          name
          comments
          date_commented
          project_role
          __typename @include(if: false)
        }
        team {
          id 
          name
          project_role
          is_working
          __typename @include(if: false)
        }
        __typename @include(if: false)
      }
      __typename @include(if: false)
    }
  } 
`;

export const STATUS_QUERY = (filter) => gql`
  {
    statusCount (filter: { id: ${filter.id}, type: "${filter.type}" }) {
      no_status
      status
      __typename @include(if: false)
    }
  } 
`;

export const USERS_QUERY = gql`
  {
    users {
      id
      first_name
      last_name
      email
      status
      client_id
      user_type_id
      user_type {
        user_type
        __typename @include(if: false)
      }
      group {
        id
        name
        sort
        __typename @include(if: false)
      }
      sort
      __typename @include(if: false)
    }

    clients (filter: "false") {
      id
      clientnr
      clientname
      __typename @include(if: false)
    }

    userStatusCount {
      total_status
      active_status
      inactive_status
      __typename @include(if: false)
    }
  } 
`;

export const CLIENTS_QUERY = gql`
  {
    clients (filter: "false" ){
      id
      clientnr
      clientname
      open_projects
      closed_projects
      archived_projects
      __typename @include(if: false)
    }

    projectStatusCount {
      total_projects
      open_projects
      closed_projects
      archived_projects
      __typename @include(if: false)
    }
  } 
`;

export const NOTIFICATIONS_QUERY = (offset) => gql`
  {
    notifications (offset: ${offset}) {
      id
      point_id
      header
      message
      creator_id
      creator_name
      stats
      type
      location
      date_received
      proj_id
      status
      priority
      proj_name
      creator
      deadline
      __typename @include(if: false)
    }

  notifCount {
    totalCount
    __typename @include(if: false)
  }
  
}
`;

export const IS_USER_ASSIGNED = (id) => gql`
  {
    isUserAssigned (id: ${id}) {
      isUserAssigned
      __typename @include(if: false)
    }  
}
`;

export const PROJECTS_DASHBOARD_QUERY = gql`
  {
    projects (data: { isTimelog: "false" }) {
      id
      proj_nr
      proj_name
      client_id
      date_created
      __typename @include(if: false)
    }

    users {
      id
      name
      email
      status
      client_id
      user_type_id
      __typename @include(if: false)
    }

    clients (filter: "false"){
      id
      clientname
      __typename @include(if: false)
    }

    groups {
      id
      name
      sort
      users {
        id
        first_name
        last_name
        sort
      }
      __typename @include(if: false)
    }

  }
`;

export const PROJECTS_OPEN_POINTS = (id) => gql`
  {
    projectOpenForTesting (id: ${id}) {
      id
      proj_nr
      proj_name
      status
      has_open_or_testing_points
    }
  }
`;

export const PROJECTS_TEST_QUERY = gql`{
  projects (data: { isTimelog: "false" }) {
    id
    proj_nr
    proj_name
    status
    client_id
    client {
      clientname
    }
    project_versions {
      id
      version_name
      __typename @include(if: false)
    }
    team {
      id
      name
      client_id
      project_role
      proj_user_type_id
      user_type_id
      proj_team_id
      __typename @include(if: false)
    }
    date_created
    __typename @include(if: false)
  }
}
`;

export const PROJECTS_QUERY = gql`
  {
    users {
      id
      name
      email
      status
      client_id
      user_type_id
      __typename @include(if: false)
    }

    clients (filter: "false"){
      id
      clientname
      __typename @include(if: false)
    }

    projectStatusCount {
      total_projects
      open_projects
      closed_projects
      __typename @include(if: false)
    }
  } 
`;

export const PROJECT_CLIENT_QUERY = (filterProjects) => gql`
  {
    projects (data: { filterProjects: "${filterProjects}" }) {
      id
      proj_name
      client_id
      client {
        clientname
        __typename @include(if: false)
      }
      project_versions {
        id
        version_name
        __typename @include(if: false)
      }
      team {
        id
        name
        project_role
        proj_user_type_id
        __typename @include(if: false)
      }
      __typename @include(if: false)
    }

    clients (filter: "true"){
      id
      clientname
      __typename @include(if: false)
    }
  }
`;

export const POINT_ANALYSIS_QUERY = (filter, table) => gql`
{
  points (filter: { id: ${filter.id}, type: "${filter.type}", user_id: "${filter.user_id}", status: "${filter.status}" }, table: "${table}" ) {
    id
    point_nr
    proj_id
    clientname
    proj_name
    feedback_id
    title
    version_name
    version
    time_approve
    time_estimate
    hours_worked
    priority
    instructions
    inst_engtrans
    status
    deadline
    creator_id
    creator
    ack
    date
    date_created
    last_change {
      name
      description
    }
    files {
      id
      point_id
      filename
    }
    comments {
      id
      user_id
      type
      name
      comments
      date_commented
      project_role
      __typename @include(if: false)
    }
    team {
      id 
      name
      project_role
      is_working
      date_updated
      __typename @include(if: false)
    }
    __typename @include(if: false)
  }
}
`;

export const POINT_COUNT = (filter) => gql`
{
  pointCount (filter: { id: ${filter.id}, type: "${filter.type}" }) {
    assignedPoints
    unassignedPoints
    closedPoints
    timeEstimatePoints
    openRequests
    __typename @include(if: false)
  }
}
`;

export const POINT_NOTIF_QUERY = (point_id) => gql`
{   
  point (id: ${point_id}) {
    id
    point_nr
    proj_id
    clientname
    proj_name
    feedback_id
    title
    version_name
    version
    time_approve
    time_estimate
    hours_worked
    priority
    instructions
    inst_engtrans
    status
    deadline
    creator_id
    creator
    ack
    date
    date_created
    last_change {
      name
      description
    }
    files {
      id
      point_id
      filename
    }
    comments {
      id
      user_id
      type
      name
      comments
      date_commented
      project_role
      __typename @include(if: false)
    }
    team {
      id 
      name
      project_role
      is_working
      date_updated
      __typename @include(if: false)
    }
    project_versions {
      id
      version_name
      __typename @include(if: false)
    }
    proj_team {
      id
      name
      project_role
      __typename @include(if: false)
    }
    __typename @include(if: false)
  }
}`;

export const POINT_QUERY = (filter, table) => {
  let columns = '';
  if (table === '1' || table === '4') { // latest updated points
    columns = `
      point_nr
      clientname
      proj_name
      instructions
      creator
      ${
        (
          table === '1' ?
          `
            team{
              id 
              name
              project_role
            }
          `:
          ''
        )
      }
      status
      priority
      date`;
  } else if (table === '2' || table === '3') {
    columns = `
      point_nr
      clientname
      proj_name
      instructions
      creator
      status
      priority`;
  }

  return gql`
    {
      points (filter: { id: ${filter.id}, type: "${filter.type}", limit: ${filter.limit}, offset: ${filter.offset} }, table: "${table}" ) {
        id
        point_nr
        proj_id
        client_id
        clientname
        proj_name
        feedback_id
        title
        version_name
        version
        time_approve
        time_estimate
        hours_worked
        priority
        instructions
        inst_engtrans
        status
        deadline
        creator_id
        creator
        ack
        date
        date_created
        last_change {
          name
          description
        }
        files {
          id
          point_id
          filename
        }
        comments {
          id
          user_id
          type
          name
          comments
          date_commented
          project_role
          __typename @include(if: false)
        }
        team {
          id 
          name
          project_role
          is_working
          date_updated
          __typename @include(if: false)
        }
        __typename @include(if: false)
      }
    }
  `;
}

/* ========== Subscription ========== */
export const NEW_NOTIF = gql`
  subscription($data: JSON!) {
    newNotif(data: $data) {
      id
      header
      message
      creator_id
      creator_name
      stats
      type
      location
      date_received
      proj_id
      status
      priority
      proj_name
      creator
      deadline
      __typename @include(if: false)
    }
  }
`;

export const NEW_POINTS_DASHBOARD = gql`
  subscription($data: JSON!) {
    newPointsDashboard(data: $data) {
      id
      point_nr
      proj_id
      clientname
      proj_name
      feedback_id
      title
      version_name
      version
      time_approve
      time_estimate
      hours_worked
      priority
      instructions
      inst_engtrans
      status
      deadline
      creator_id
      creator
      ack
      date
      date_created
      last_change {
        name
        description
      }
      files {
        id
        point_id
        filename
      }
      comments {
        id
        user_id
        type
        name
        comments
        date_commented
        project_role
        __typename @include(if: false)
      }
      team {
        id 
        name
        project_role
        is_working
        __typename @include(if: false)
      }
      __typename @include(if: false)
    }
  }
`;

export const NEW_COMMENT = gql`
  subscription {
    newComment {
      id
      point_id
      user_id
      name
      comments
      date_commented
      project_role
      __typename @include(if: false)
    }
  }
`;

export const SHOW_NOTIF = gql`
  subscription($data: JSON!) {
    showNotif(data: $data) {
      id
      __typename @include(if: false)
    }
  }
`;

export const UPDATED_POINT = gql`
  subscription($data: JSON!) {
    updatedPoint(data: $data) {
      id
      proj_id
      client_id
      version_name
      version
      time_approve
      time_estimate
      hours_worked
      priority
      instructions
      inst_engtrans
      status
      deadline
      ack
      date
      team {
        id 
        name
        project_role
        is_working
        date_updated
        __typename @include(if: false)
      }
      __typename @include(if: false)
    }
  }
`;

export const NEW_POINT = gql`
  subscription {
    newPoint {
      id
      proj_id
      clientname
      proj_name
      feedback_id
      point_nr
      version_name
      version
      time_approve
      time_estimate
      hours_worked
      priority
      instructions
      inst_engtrans
      status
      deadline
      creator_id
      creator
      ack
      date
      date_created
      files {
        id
        point_id
        filename
        __typename @include(if: false)
      }
      comments {
        id
        user_id
        type
        name
        comments
        date_commented
        project_role
        __typename @include(if: false)
      }
      team {
        id 
        name
        project_role
        __typename @include(if: false)
      }
      __typename @include(if: false)
    }
  }
`;

export const NEW_FEEDBACK = gql`
  subscription($data: JSON!) {
    newFeedback(data: $data) {
      id
      proj_id
      client_id
      title
      proj_name
      clientname
      date_saved
      versions {
        id
        version_name
        points { 
          id
          feedback_id
          point_nr
          version_name
          version
          time_approve
          time_estimate
          hours_worked
          priority
          instructions
          inst_engtrans
          status
          deadline
          creator_id
          creator
          ack
          date
          date_created
          last_change {
            name
            description
          }
          files {
            id
            point_id
            filename
            __typename @include(if: false)
          }
          comments {
            id
            user_id
            type
            name
            comments
            date_commented
            project_role
            __typename @include(if: false)
          }
          team {
            id 
            name
            project_role
            is_working
            __typename @include(if: false)
          }
          __typename @include(if: false)
        }
        __typename @include(if: false)
      }
      __typename @include(if: false)    
    }
  }
`;

/* ========== Mutation ========== */


/* GROUPS */
export const UPSERT_GROUP = gql`
  mutation($data: JSON!) {
    upsertGroup(data: $data) {
      id
      name
      sort

      __typename @include(if: false)
    }
  } 
`;

export const DELETE_GROUP = gql`
  mutation($ids: JSON!) {
    deleteGroup(ids: $ids) {
      id
      name
      __typename @include(if: false)
    }
  }
`;

/* TIUME LOGS */
export const UPSERT_TIMELOGS = gql`
  mutation($data: JSON!) {
    upsertTimelogs(data: $data) {
      status
      message
      error_type  
      __typename @include(if: false)
    }
  } 
`;

/* FEEDBACKS */
export const NOTIF_SEEN = gql`
  mutation($data: JSON!) {
    updateNotif(data: $data) {
      id 
      __typename @include(if: false)
    }
  } 
`;


export const UPSERT_FEEDBACK = gql`
  mutation($data: JSON!) {
    updateFeedback(data: $data) {
      id
      title
      __typename @include(if: false)
    }
  }
`;

export const UPSERT_REQUEST = gql`
  mutation($data: JSON!) {
    upsertRequest(data: $data) {
      status
      message
      error_type 
      __typename @include(if: false)
    }
  }
`;

export const SUBMIT_FEEDBACK = gql`
  mutation($data: JSON!) {
    submitFeedback(data: $data) {
      id
      point_nr
      feedback_id
      proj_id
      __typename @include(if: false)
    }
  }
`;

export const DELETE_FEEDBACK = gql`
  mutation($data: JSON!) {
    deleteFeedback(data: $data) {
      id
      title
      __typename @include(if: false)
    }
  }
`;

export const CREATE_FEEDBACK_POINTS = gql`
  mutation($data: JSON!, $feedback: JSON!, $lang: String!) {
    createFeedbackPoints(data: $data, feedback: $feedback, lang: $lang) {
      id
      proj_id
      client_id
      points {
        id
        point_nr
      }
      __typename @include(if: false)
    }
  }
`;

/* COMMENTS */
export const UPSERT_COMMENT = gql`
  mutation($data: JSON!) {
    upsertComment(data: $data) {
      id
      user_id
      type
      name
      comments
      date_commented
      project_role
      __typename @include(if: false)
    }
  }
`;

export const DELETE_COMMENT = gql`
  mutation($ids: JSON!) {
    deleteComment(ids: $ids) {
      id
      __typename @include(if: false)
    }
  }
`;

/* POINTS */
export const UPDATE_POINT = gql`
  mutation($data: JSON!, $type: JSON!) {
    updatePoint(data: $data, type: $type) {
      id
      proj_id
      clientname
      proj_name
      feedback_id
      point_nr
      version_name
      version
      time_approve
      time_estimate
      hours_worked
      priority
      instructions
      inst_engtrans
      status
      deadline
      creator_id
      creator
      ack
      date
      date_created
      last_change {
        name
        description
      }
      files {
        id
        point_id
        filename
        __typename @include(if: false)
      }
      comments {
        id
        user_id
        type
        name
        comments
        date_commented
        project_role
        __typename @include(if: false)
      }
      team {
        id 
        name
        project_role
        is_working
        date_updated
        __typename @include(if: false)
      }
      __typename @include(if: false)
    }
  }
`;

export const UPSERT_POINT = gql`
  mutation($data: JSON!, $feedback: JSON!) {
    upsertPoint(data: $data, feedback: $feedback) {
      id
      proj_id
      clientname
      proj_name
      feedback_id
      point_nr
      version_name
      version
      time_approve
      time_estimate
      hours_worked
      priority
      instructions
      inst_engtrans
      status
      deadline
      creator_id
      creator
      ack
      date
      date_created
      files {
        id
        point_id
        filename
        __typename @include(if: false)
      }
      comments {
        id
        user_id
        type
        name
        comments
        date_commented
        project_role
        __typename @include(if: false)
      }
      team {
        id 
        name
        project_role
        is_working
        date_updated
        __typename @include(if: false)
      }
      __typename @include(if: false)
    }
  }
`;

export const DELETE_REQUEST = gql`
  mutation($ids: JSON!) {
    deleteRequest(ids: $ids) {
      status
      message
      __typename @include(if: false)
    }
  }
`;

export const DELETE_POINT = gql`
  mutation($ids: JSON!) {
    deletePoint(ids: $ids) {
      id
      __typename @include(if: false)
    }
  }
`;

/* USERS */
export const AUTHENTICATE_TOKEN = gql`
  mutation($data: JSON!) {
    authenticateToken(data: $data) {
      msg
      status
      __typename @include(if: false)
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation($data: JSON!) {
    resetPassword(data: $data) {
      msg
      status
      __typename @include(if: false)
    }
  }
`;

export const UPDATE_EMAIL_AUTHENTICATION = gql`
  mutation($data: Boolean!) {
    updateActiveSecurity(data: $data) {
      msg
      status
      __typename @include(if: false)
    }
  }
`;

export const VERIFY_USER = gql`
  mutation($email: String!, $verifyUserID: String!, $verificationKey: String!, $type: String!) {
    verifyUser(email: $email, verifyUserID: $verifyUserID, verificationKey: $verificationKey, type: $type) {
      status
      errorType
      token
      refreshToken
      msg
      user {
        id
        first_name
        last_name
        email
        role
        subscription_id
        company_name
        first_login
        update_seen
        first_tutorial
      }
      __typename @include(if: false)
    }
  }
`;

export const CREATE_USER = gql`
  mutation($data: JSON!) {
    createUser(data: $data) {
      id
      first_name
      last_name
      email
      status
      client_id
      user_type_id
      user_type {
        user_type
        __typename @include(if: false)
      }
      group {
        id
        name
        sort
        __typename @include(if: false)
      }
      sort
      __typename @include(if: false)
    }
  }
`;

export const SEND_EMAIL = gql`
  mutation($data: JSON!) {
    sendEmail(data: $data) {
      status
      message
      __typename @include(if: false)
    }
  }
`;

export const UPDATE_USER = gql`
  mutation($data: JSON!, $id: Int!) {
    updateUser(data: $data, id: $id) {
      id
      first_name
      last_name
      email
      status
      user_type_id
      group {
        id
        name
        sort
        __typename @include(if: false)
      }
      sort
      __typename @include(if: false)
    }
  }
`;

export const UPDATE_GROUP_USER = gql`
  mutation($data: JSON!) {
    updateGroupUser(data: $data) {
      id
      __typename @include(if: false)
    }
  }
`;

export const DELETE_USER = gql`
  mutation($ids: JSON!) {
    deleteUser(ids: $ids) {
      id
      first_name
      last_name
      email
      status
      user_type_id
      __typename @include(if: false)
    }
  }
`;

/* CLIENTS */
export const CREATE_CLIENT = gql`
  mutation($data: JSON!) {
    createClient(data: $data) {
      id
      clientnr
      clientname
      open_projects
      closed_projects
      archived_projects
      __typename @include(if: false)
    }
  }
`;

export const UPDATE_CLIENT = gql`
  mutation($data: JSON!, $id: Int!) {
    updateClient(data: $data, id: $id) {
      id
      clientnr
      clientname
      open_projects
      closed_projects
      archived_projects
      __typename @include(if: false)
    }
  }
`;

export const DELETE_CLIENT = gql`
  mutation($ids: JSON!) {
    deleteClient(ids: $ids) {
      id
      clientnr
      clientname
      open_projects
      closed_projects
      archived_projects
      __typename @include(if: false)
    }
  }
`;

/* PROJECTS */
export const UPDATE_PROJECT_STATUS = gql`
  mutation($data: JSON!) {
    updateProjectStatus(data: $data) {
      id
      __typename @include(if: false)
    }
  }
`;

export const UPDATE_PRIORITY = gql`
  mutation($data: JSON!) {
    updatePriority(data: $data) {
      id
      __typename @include(if: false)
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation($data: JSON!) {
    createProject(data: $data) {
      id
      proj_nr
      proj_name
      status
      client {
        clientname
      }
      project_versions {
        id
        version_name
        __typename @include(if: false)
      }
      team {
        id
        name
        project_role
        user_type_id
        proj_user_type_id
        __typename @include(if: false)
      }
      __typename @include(if: false)
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation($data: JSON!, $id: Int!) {
    updateProject(data: $data, id: $id) {
      id
      proj_nr
      proj_name
      status
      client_id
      client {
        clientname
      }
      project_versions {
        id
        version_name
        __typename @include(if: false)
      }
      team {
        id
        name
        client_id
        project_role
        user_type_id
        proj_user_type_id
        __typename @include(if: false)
      }
      __typename @include(if: false)
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation($ids: JSON!) {
    deleteProject(ids: $ids) {
      id
      status
      __typename @include(if: false)
    }
  }
`;

export const UPDATE_ACCOUNT_SETTINGS = gql`
  mutation($data: JSON!) {
    updateAccountInfo(data: $data) {
      status
      token
      refreshToken
      user {
        id
        first_name
        last_name
        email
        role
        subscription_id
        company_name
        first_login
      }
      __typename @include(if: false)
    }
  }
`;

export const IS_TOUR_OPEN = gql`
  mutation($data: JSON!) {
    isTourOpen(data: $data) {
      status
      msg
      __typename @include(if: false)
    }
  }
`;

export const LOGIN = gql`
  mutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      status
      errorType
      token
      refreshToken
      msg
      user {
        id
        first_name
        last_name
        email
        role
        subscription_id
        company_name
        first_login
        update_seen
        first_tutorial
      }
      __typename @include(if: false)
    }
  }
`;