import React, { useEffect, useContext, useState } from 'react'
import { message, TimePicker, Button as AntdButton, Popconfirm, Form, DatePicker, Icon } from 'antd';
import { MuiPickersUtilsProvider, TimePicker as MTimePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment'

import { UPSERT_TIMELOGS } from '../../queries';
import { ApolloContext } from 'react-apollo';

export default ({ isFromTimelog, data, context, reducer: { state, dispatch}, momentTime: { momentTime, setMomentTime }, popover: { popover, setPopover }, tpOpen: { tpOpen, setTpOpen }, form: { getFieldDecorator, validateFields, setFields, setFieldsValue } }) => {
  const { client } = useContext(ApolloContext)
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [time, setTime] = useState({
    start_time: {
      isOpen: false
    },
    end_time: {
      isOpen: false
    }
  })
  const config = {
    rules: [{ type: 'object', required: true, message: context.translation.select_time[context.locale] }],
  };


  const handleTimeChange = (index, property) => (time, timeString) => isFromTimelog? setMomentTime(momentTime.map((log, i) => i === index? {...log, [property]: moment(time, 'HH:mm')}: log)) : setMomentTime(moment(time, 'HH:mm'))

  function handleConfirm() {
    setPopover(state.timelogs.map(e => false))
    dispatch({ type: 'SET_STATE', payload: { timelogs: state.timelogs.filter((e, i) => i !== data.index) } })
  }
  
  function statusOfNewData(i, status) {
    if (i === data.index) {
      // If loaded timelog is existing, proceed comparison
      if (!!state.prev_timelogs[i]) {
        // If loaded timelog is not equals to the newly updated timelog, mark as unsaved
        if (times(momentTime[i].start_time) !== times(state.prev_timelogs[i].start_time) || times(momentTime[i].end_time) !== times(state.prev_timelogs[i].end_time)) {
          return 'unsaved'
        } 
      } else {
        // If loaded timelog doesn't exist, mark as unsaved
        return 'unsaved'
      }
    } 

    return 'saved'
  }

  function times(property) {
    return !!property ? property.format('HH:mm') : ''
  }

  function handleSubmit(e) {
    e.preventDefault();

    validateFields((err, fieldsValue) => {
      console.log(err)
      if (err) {
        return;
      }

      const start_time = fieldsValue['start-time'];
      const end_time = fieldsValue['end-time'];

      // console.log(start_time)
      // console.log(end_time)

      if (!start_time.isBefore(end_time)) {
        setFields({
          ['end-time']: {
            errors: [new Error(context.translation.incorrect_end_time[context.locale])],
          },
        });

        return;
      }
      
      if (isFromTimelog) {
        dispatch({ 
          type: 'SET_STATE', 
          payload: { 
            timelogs: state.timelogs.map((log, i) => {
              return i === data.index? {
                ...log, 
                start_time: start_time, 
                end_time: end_time,
                stat: statusOfNewData(i, log.status)
              } : log
            })
          }
        })
      } else {
        saveTimelog({ point_id: data.point_id, start_time: moment(start_time).format('HH:mm'), end_time: moment(end_time).format('HH:mm'), date_received: fieldsValue['log-date'].format('YYYY-MM-DD') })
      }
      setPopover(isFromTimelog ? state.timelogs.map(e => false) : false)
      return
    })
  }

  function saveTimelog(payload) {
    const hide = message.loading(context.translation.saving_changes[context.locale], 0);

    client.mutate({ mutation: UPSERT_TIMELOGS, variables: { data: { timelogs: [payload], date: moment().format('YYYY-MM-DD'), isFromTimelog: false } } }).then(({data}) => {
      setFieldsValue({
        ['start-time']: '',
        ['end-time']: ''
      });

      // Update hours worked on state points and editedItem.hours_wokred
      const duration = moment(payload.end_time, 'HH:mm').diff(moment(payload.start_time, 'HH:mm'))
      const hours_worked = moment(state.editedItem.hours_worked || '00:00', 'HH:mm').add(duration).format('HH:mm')

      dispatch({
        type: 'SET_STATE',
        payload: {
          points: state.points.map(e => e.id == state.editedItem.id? {...e, hours_worked} : e),
          editedItem: {
            ...state.editedItem,
            hours_worked
          }
        }
      })

      hide()
      message.success(context.translation.success_logs[context.locale])
    }).catch(error => {
      hide()
      message.error(error.toString())  
    })
  }

  const timepickerHandler = property => e => {
    e.stopPropagation()
    
    updateTimePicker('open', property)
  }

  function updateFields(additional = {}, payload = null) {
    if (!payload) {
      payload = {
        ['start-time']: isFromTimelog ? momentTime[data.index].start_time : momentTime.start_time,
        ['end-time']: isFromTimelog ? momentTime[data.index].end_time : momentTime.end_time,
      }
    }

    setFieldsValue({
      ...payload,      
      ...additional
    });
  }

  // Manually click timepicker (Issue when using open attribute on material-ui is: onOpen doesn't work. It only triggers when the component itself is clicked)
  function updateTimePicker(type, property) {
    console.log(momentTime[property])
    setTime(val => {
      if (type === 'close')
        property = val.start_time.isOpen ? 'start_time' : 'end_time'

      return {
        ...val,
        [property]: {
          isOpen: !val[property].isOpen
        },
        value: isFromTimelog ? momentTime[data.index][property] : momentTime[property]
      }
    })

    if (type === 'open') {
      setTimeout(() => {
        document.getElementById('Mtime-picker').click()
      }, 100);

    }

  }

  useEffect(() => {
    const additional = !isFromTimelog ? { ['log-date']: moment(new Date(), 'YYY-MM-DD') } : {}

    updateFields(additional)
  }, [])

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <MTimePicker
          id="Mtime-picker"
          value={time.value}
          onClose={() => {
            updateTimePicker('close', null)
          }}
          onChange={dateTime => {
            const property = time.start_time.isOpen ? 'start_time' : 'end_time'
            handleTimeChange(data.index, property)(dateTime, null)
            updateFields({}, { [property.replace('_', '-')]: moment(dateTime, 'HH:mm') })
          }}
          // onAccept={() => handleTimeChange(data.index, time.start_time.isOpen ? 'start_time' : 'end_time')()}
          style={{ display: 'none' }}
        />
      </MuiPickersUtilsProvider>

      <Form layout="inline" onSubmit={handleSubmit}>
        <div className="popover-content">
        {
          !isFromTimelog && (
            <Form.Item 
              style={{ display: 'block', marginRight: 0 }}
              wrapperCol={{ xs: { span: 24 } }}
            >
              {
                getFieldDecorator('log-date', config)(
                  <DatePicker 
                    placeholder={context.translation.select_date[context.locale]} 
                    style={{ width: '100%' }}
                  />  
                )
              }
            </Form.Item>
          )
        }
        <Form.Item>
          {
            getFieldDecorator('start-time', config)(
              <TimePicker 
                id="start-time"
                open={tpOpen[0]}
                allowClear={false}
                suffixIcon={<Icon type="clock-circle" onClick={timepickerHandler('start_time')} />}
                onOpenChange={() => setTpOpen([!tpOpen[0], tpOpen[1]])}
                onChange={handleTimeChange(data.index, 'start_time')}
                use12Hours 
                format="h:mm a" 
                placeholder={context.translation.start_time[context.locale]} 
                addon={() => (
                  <AntdButton size="small" type="primary" onClick={() => setTpOpen([!tpOpen[0], tpOpen[1]])}>
                    Ok
                  </AntdButton>
                )}
                onFocus={() => setTpOpen([!tpOpen[0], tpOpen[1]])}
                onKeyDown={(event) => {
                  if(event.key === 'Enter'){
                    setTpOpen([!tpOpen[0], !tpOpen[1]])
                    }
                  }}
              />  
            )
          }
        </Form.Item>
          {' '}
        <Form.Item style={{ marginRight: 0 }}>
          {
            getFieldDecorator('end-time', config)(
              <TimePicker 
                id="end-time"
                open={tpOpen[1]}
                allowClear={false}
                suffixIcon={<Icon type="clock-circle" onClick={timepickerHandler('end_time')} />}
                onOpenChange={() => setTpOpen([tpOpen[0], !tpOpen[1]])}
                onChange={handleTimeChange(data.index, 'end_time')}
                use12Hours 
                format="h:mm a" 
                placeholder={context.translation.end_time[context.locale]} 
                addon={() => (
                  <AntdButton size="small" type="primary" onClick={() => setTpOpen([tpOpen[0], !tpOpen[1]])}>
                    Ok
                  </AntdButton>
                )}
                onFocus={() => setTpOpen([tpOpen[0], !tpOpen[1]])}
                onKeyDown={(event) => {
                  if(event.key === 'Enter'){
                    setTpOpen([tpOpen[0], !tpOpen[1]])
                    setTimeout(() => document.getElementById(`savedata-log-${data.index}`).focus(), 300)
                  }
                }}
              />
            )
          }
        </Form.Item>
        </div>
      
        <div className="popover-actions">
          {
            isFromTimelog && 
              (
                <Popconfirm
                  placement="leftBottom"
                  title={context.translation.are_you[context.locale]}
                  onConfirm={() => handleConfirm()}
                  okText="Yes"
                  cancelText="No"
                >
                  <AntdButton type="danger">{context.translation.delete[context.locale]}</AntdButton>
                </Popconfirm>
              )
          }
          
          <AntdButton id={`savedata-log-${data.index}`} style={{ width: isFromTimelog? '50%': '100%' }} className="savedata-log" type="primary" htmlType="submit">
            {context.translation.save[context.locale]}
          </AntdButton>
        </div>
      </Form>
    </>
)
}