import React, { useEffect, useReducer, useContext } from 'react';
import { withRouter, BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Loading, ComponentNotFound, Sidebar, SpringStyle } from '../../common';
import { useSpring, animated } from 'react-spring'
import SettingsReducer from './SettingsReducer'

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import SettingsAccount from './SettingsAccount'
import SettingsSecurity from './SettingsSecurity'
import SettingsSidebar from './SettingsSidebar'

/* Import Context Provider */
import { GlobalContext } from '../../../contexts/GlobalContext';

const initialErrorState = {
  email: {
    invalid: false,
    message: ''
  },
  old_password: {
    invalid: false,
    message: ''
  },
  password: {
    invalid: false,
    message: ''
  },
  first_name: {
    invalid: false,
    message: ''
  },
  last_name: {
    invalid: false,
    message: ''
  }
}

const initialState = {
  first_name: '',
  last_name: '',
  email: '',
  old_password: '',
  password: '',
  c_password: '',
  company_name: '',
  errors: {
    ...initialErrorState
  },
  defaultErrors: {
    ...initialErrorState
  },
  isLoading: false
}

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'inline-block',
    verticalAlign: 'top',
    width: '80%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  }
}));

export default withRouter(({history, match}) => {
  const classes = useStyles();
  const type = match.params.type
  const [state, dispatch] = useReducer(SettingsReducer, initialState)
  const context = useContext(GlobalContext)

  useEffect(() => {
    context.dispatch({ type: 'SET_STATE', payload: { showSidebar: false } })

    return () => { // Trigger if component will unmount
      context.dispatch({ type: 'SET_STATE', payload: { showSidebar: true } }) // Hide Sidebar      
    }
  }, [])

  return (
    <div id="settings-container">
      <Router>
        <animated.div style={{...useSpring(SpringStyle), display: 'inline-block' }}>
          <SettingsSidebar type={type} /> 
        </animated.div>

        <Paper className={classes.paper}>
          <Switch>
              <Route path="/settings/account" render={ props => <SettingsAccount reducer={{ state, dispatch }} />} />
              <Route path="/settings/security" render={ props => <SettingsSecurity history={history} reducer={{ state, dispatch }} />} />

              <Route component={ComponentNotFound} />
          </Switch>
        </Paper>
      </Router>
    </div>
  );
})