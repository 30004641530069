import React, { useReducer, useContext, useEffect, useState } from 'react';
import { Timeline, Icon, Popover, Button, message } from 'antd';
import { Container, Row, Col } from 'reactstrap';
import TimelogReducer from './TimelogReducer'
import { faClock, faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import { Empty, Spinner2 } from '../../common';
import moment from 'moment'
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import FileDownload from 'js-file-download';

/* Import Context Provider */
import { AuthContext } from '../../../contexts/AuthContext';
import { ApolloContext } from 'react-apollo';
import { GlobalContext } from '../../../contexts/GlobalContext';

import { TIMELOGS_SUMMARY_QUERY } from '../../../queries';
import axios from 'axios'


const badgeColorStatus = [
  { name: 'Open', border: '1px solid #CF0F22', backgroundColor: '#FAE6E8', color: '#CF0F22', fontWeight: '400', borderRadius: '2px' },
  { name: 'Question', border: '1px solid #219FC7', backgroundColor: '#E8F5F9', color: '#219FC7', fontWeight: '400', borderRadius: '2px' },
  { name: 'For Testing', border: '1px solid #5B3694', backgroundColor: '#EEEAF4', color: '#5B3694', fontWeight: '400', borderRadius: '2px' },
  { name: 'Postpone', border: '1px solid #E43988', backgroundColor: '#FDEBF3', color: '#E43988', fontWeight: '400', borderRadius: '2px' },
  { name: 'Closed', border: '1px solid #53AD57', backgroundColor: '#EDF7EE', color: '#53AD57', fontWeight: '400', borderRadius: '2px' },
]

const badgeColorPriority = [
  { name: 'High', border: '1px solid #CF0F22', backgroundColor: '#FAE6E8', color: '#CF0F22', fontWeight: '400', borderRadius: '2px' },
  { name: 'Mid', border: '1px solid #FF8B00', backgroundColor: '#FFF3E5', color: '#FF8B00', fontWeight: '400', borderRadius: '2px' },
  { name: 'Low', border: '1px solid #FFC400', backgroundColor: '#FFF9E5', color: '#FFC400', fontWeight: '400', borderRadius: '2px' },
]

const initialState = {
  timelogsSummary: [],
  selectedDate: new Date()
}

const Content = ({ logs, context }) => {
  return (
    <div id="point-card" className="timelogs-card">
      {
        logs.map((point, index) => (
          <div className="content-card" key={index}>
            {/* Top section */}
            <div className="top">
              <div className="left">
                <span className={`status badge badge-sm ${'TEMPORARY' === 'Draft' && "bg-light"}`} style={{ ...badgeColorStatus.find(e => e.name === point.status) }}>{context.translation.status[point.status.toLowerCase().replace(' ', '_')][context.locale]}</span>
                <span className={`priority badge badge-sm`} style={{ ...badgeColorPriority.find(e => e.name === point.priority) }}>{context.translation[point.priority.toLowerCase()][context.locale]}</span>
              </div>
        
              <div className="right">
                <small style={{ marginRight: '5px' }}><b>{point.hours_worked}</b></small>
                <b>#{++index}</b>
              </div>
            </div>
        
            {/* Body */}
            <div className="body">
              <h6 className="title">{ point.title }</h6>
              <pre className="pre-instructions">{ point.instructions }</pre>
            </div>
        
            {/* Footer */}
            <div className="footer">
              <div className="left">
                <div className="inner-left">
                  <small><FontAwesomeIcon icon={faUserCircle} style={{ verticalAlign: 'middle' }}/> { point.creator }</small>
                  <small><FontAwesomeIcon icon={faClock} style={{ verticalAlign: 'middle' }}/> <Moment style={{ verticalAlign: 'middle' }} locale={context.locale} interval={60000} fromNow>{ point.date }</Moment></small>
                </div>
                {
                  point.deadline !== 'N/A' && point.deadline != null && 
                    <span className={`deadline badge badge-sm`}><span className="deadline-label">{ context.translation.deadline[context.locale] }</span> <span className="deadline-moment">{ point.deadline }</span></span>
                }
              </div>
            </div>
          </div>
        ))
      }
    </div>
  )
}

const Title = ({ logs, context }) => (
  <>
    <span>{context.translation.log_details[context.locale]}</span> 

    <span style={{ float: 'right' }}>
      <Icon type="clock-circle-o" style={{ fontSize: '16px', color: '#1890FF', verticalAlign: 'middle', marginRight: '5px' }} /> 
      <b>{ logs.reduce(({hours_worked: a}, {hours_worked: b}) => ({hours_worked: moment(a, 'HH:mm').add(moment(b, 'HH:mm').format('HH'), 'hours').add(moment(b, 'HH:mm').format('mm'), 'minutes').format('HH:mm')})).hours_worked }</b>
    </span>
  </>
)

export default ({ context, dateState: { dateValues, setDateValues, setDateReceived, dateReceived } }) => {
  const [state, dispatch] = useReducer(TimelogReducer, initialState)
  const [loading, setLoading] = useState(false)
  const { client } = useContext(ApolloContext)
  const global = useContext(GlobalContext)
  const fabAction = () => {
    // document.getElementById('export-me').click()
    axios.get(global.uri2 + '/api/excel/' + dateValues[0].dateString + '/' + dateValues[1].dateString, { responseType: 'blob' }).then(res => {
      FileDownload(res.data, `Time Report (${dateValues[0].dateString + '-' + dateValues[1].dateString}).xlsx`)
    })
  }

  // const handleDateChange = async (date) => {
  //   axios.get(global.uri2 + '/api/excel/' + date, { responseType: 'blob' }).then(res => {
  //     FileDownload(res.data, 'report.xlsx')
  //   })

  //   // const { data } = await context.client.mutate({ mutation: EXPORT_REPORT, variables: { date } });
  // };

  useEffect(() => {
    // Displaying of save button
    context.dispatch({ type: 'SET_STATE', 
      payload: { 
        tooltipTittle: context.translation.month_end_report[context.locale], 
        showFab: true,
        print: true,
        fabAction
      } 
    }) // Show FAB      

    return () => { // Trigger if component will unmount
      context.dispatch({ type: 'SET_STATE', payload: { showFab: false, print: false } }) // Hide FAB      
    }
  }, [])

  useEffect(() => {
    loadSummary(dateValues)
  }, [dateValues])

  async function loadSummary(payload) {
    context.dispatch({ type: 'SET_STATE', 
      payload: { fabAction } 
    }) // Show FAB   

    setLoading(true)
    client.query({ query: TIMELOGS_SUMMARY_QUERY({ start: payload[0].dateString, end: payload[1].dateString }) }).then(({data: { timelogsSummary }}) => {
      let result = []

      /* ==============================================
          Reconstructed data on client side because 
          sequelize suck! 
          (Can't optimize the speed of query)
         ============================================== */

      // Reconstruct data (clients)
      const temp_result = timelogsSummary.map(user => {
        return {
          ...user,
          timelogs: user.timelogs.reduce(function(rv, x) {
            let res = { logs: [] };
            res.proj_name = x.proj_name;
            res.clientname = x.clientname;
            res.logs.push(x);
            if (rv.find(r => r.logs.find(s => s.proj_name == x.proj_name))) {
              rv = rv.map(r => {
                if (r.proj_name == x.proj_name) {
                    r.logs.push(x)
                }
                
                return r;
              })
            } else {
              rv.push(res)
            }
        
            rv = rv.map(r => ({ ...r, hours_worked: r.logs.reduce(({hours_worked: a}, {hours_worked: b}) => ({hours_worked: moment(a, 'HH:mm').add(moment(b, 'HH:mm').format('HH'), 'hours').add(moment(b, 'HH:mm').format('mm'), 'minutes').format('HH:mm')})).hours_worked }))
            
            return rv;
          }, [])
        }
      })

      console.log(temp_result)

      // Reconstruct data (by date)
      temp_result.forEach(user => {
        user.timelogs.forEach(tlog => {
          tlog.logs.forEach(log => {
            let found_user = {}

            // If date doesn't exist on result array -> push data 
            if (!result.find(res => res.date_received == log.date_received)) { 
              result.push({
                date_received: log.date_received,
                users: []
              })
            } 

            // If user doesn't exist on result array
            if (result.some(res => res.users.every(r_user => r_user.id != user.id) && (res.date_received == log.date_received))) {
              result.find(res => res.date_received == log.date_received).users.push({ id: user.id, name: user.name, timelogs: [] })
            } 

            // Check if current project_name in loop from temp_result doesn't exist on result -> push data to result array
            let isNotExist = result.some(res => {
              found_user = res.users.find(r_user => r_user.id == user.id)
              if (found_user) 
                return found_user.timelogs.every(r_log => r_log.proj_name != tlog.proj_name) && (res.date_received == log.date_received)
              else 
                return false
            })

            if (isNotExist) {
              found_user.timelogs.push({ 
                proj_name: tlog.proj_name, 
                clientname: tlog.clientname,
                logs: []
              })
            }


            // Check if current log in loop from temp_result doesn't exist on result -> push data to result array
            isNotExist = result.some(res => {
              found_user = res.users.find(r_user => r_user.id == user.id)
              if (found_user) 
                return (found_user = found_user.timelogs.find(rt_log => rt_log.logs.every(r_log => r_log.id != log.id) && (rt_log.proj_name == tlog.proj_name))) && (res.date_received == log.date_received)
              else 
                return false
            })

            if (isNotExist) {
              found_user.logs.push(log)
            }
          })
        })
      })

      // Insert users that has no timelogs
      temp_result.forEach(user => {
        result.forEach(date => {
          if (date.users.every(u => u.id !== user.id)) {
            date.users.push({ ...user, timelogs: [] })
          }
        })
      })

      result = result.map(data => ({
        ...data,
        users: data.users.map(user => ({
          ...user,
          timelogs: user.timelogs.map(tlog => ({
            ...tlog,
            hours_worked: tlog.logs.reduce(({hours_worked: a}, {hours_worked: b}) => ({hours_worked: moment(a, 'HH:mm').add(moment(b, 'HH:mm').format('HH'), 'hours').add(moment(b, 'HH:mm').format('mm'), 'minutes').format('HH:mm')})).hours_worked,
          }))
        }))
      })).sort(function(a, b){
        let date1 = a.date_received, date2 = b.date_received

        if (moment(date2).isAfter(date1)) //sort string ascending
            return -1 
        if (moment(date1).isAfter(date2))
            return 1
        return 0 //default return value (no sorting)
      })

      console.log(result)

      let h = 0, m = 0, r = 0, time = ''

      result.forEach(date => {
        date.users.forEach(user => {
          user.timelogs.forEach(log => {
            
              h += parseInt(log.hours_worked.split(':')[0])
              m += parseInt(log.hours_worked.split(':')[1])
            
          })
        })
      })
        
      r = m / 60 
      m = m % 60
      h = parseInt(h) + parseInt(r)

      time = h + ':' + m

      console.log(time)

      dispatch({ type: 'SET_STATE', payload: { timelogsSummary: result.reverse() } })
      setLoading(false)
    })
  }

  // function exportToExcel() {
  //   client.query({ query: TIMELOGS_EXPORT }).then(({data}) => {
  //     console.log('DONE');
  //   }).catch(error => {
  //     message.error(context.translation.error_occurred[context.locale]);
  //   })
  // }
  
  return (
    <Container>
      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          id='export-me'
          views={["year", "month"]}
          minDate={new Date("2019-03-01")}
          maxDate={new Date()}
          okLabel={context.translation.export[context.locale]}
          value={state.selectedDate}
          onChange={handleDateChange}
          style={{ visibility: 'hidden', position: 'absolute', zIndex: -1 }}
        />
      </MuiPickersUtilsProvider> */}

      <Row className="justify-content-center mt-4">
        <Col md="8" sm="12">
          { 
            !loading? 
              state.timelogsSummary.length !== 0 ? 
                (
                  <>
                    {/* <Button type="primary" icon="download" style={{ position: 'absolute', right: 0, top: '10px' }} onClick={exportToExcel()}>
                      { context.translation.download[context.locale] }
                    </Button><br /> */}
                    {
                      state.timelogsSummary.map((data, i) => (
                        <React.Fragment key={i}>
                          <h4 style={{ display: 'inline-block', marginRight: '5px' }}>{ moment(data.date_received).format('LL') }</h4>
                          {
                            moment(data.date_received).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') && 
                              (<span>({ context.translation.today[context.locale] })</span>)
                          }
                          <br />

                          {
                            data.users.map((user, a) => (
                              <Col xs="12" key={a}>
                                <span className="user_timeline" style={{ fontWeight: 600 }}>{user.name}</span>
                                <Timeline className="mt-3">
                                  {
                                    user.timelogs.length !== 0 && 
                                      user.timelogs.map((log, index) => (
                                        <Popover key={index} placement="left" content={<Content logs={log.logs} context={context} />} title={<Title logs={log.logs} context={context} />}>
                                          <Timeline.Item><Row><Col>{log.clientname}</Col> <Col>{log.proj_name}</Col> <Col xs="auto">{ log.logs.reduce(({hours_worked: a}, {hours_worked: b}) => ({hours_worked: moment(a, 'HH:mm').add(moment(b, 'HH:mm').format('HH'), 'hours').add(moment(b, 'HH:mm').format('mm'), 'minutes').format('HH:mm')})).hours_worked }</Col></Row></Timeline.Item>
                                        </Popover>
                                      ))
                                  }
                                  <Timeline.Item color="gray"><Row><Col></Col> <Col></Col> <Col xs="auto" style={{ fontWeight: 700 }}>
                                    <Icon type="clock-circle-o" style={{ fontSize: '16px', color: '#1890FF', verticalAlign: 'middle' }} />{' '}
                                    {user.timelogs.reduce(({hours_worked: a}, {hours_worked: b}) => ({hours_worked: moment(a, 'HH:mm').add(moment(b, 'HH:mm').format('HH'), 'hours').add(moment(b, 'HH:mm').format('mm'), 'minutes').format('HH:mm')}), { hours_worked: '00:00' }).hours_worked}
                                  </Col></Row></Timeline.Item>
                                </Timeline>
                              </Col>
                            ))
                          }
                        </React.Fragment>
                      )) 
                    }
                  </>
                )
                : 
                <Empty style={{marginTop: '80px', backgroundColor: 'transparent'}} otherLogo />
                : <Spinner2 />
          }
        </Col>
      </Row>
    </Container>
  );
}