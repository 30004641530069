import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import clamp from 'lodash-es/clamp'
import swap from 'lodash-move'
import { useDrag } from 'react-use-gesture'
import { useSprings, animated, useTrail } from 'react-spring'
import { AlignJustify } from 'react-feather'
import { Popover, TimePicker, Button as AntdButton, Popconfirm, Form } from 'antd';
import { Empty, Spinner2, PopoverContent } from '../../common';
import moment from 'moment';


const badgeColorStatus = [
  { name: 'Open', border: '1px solid #CF0F22', backgroundColor: '#FAE6E8', color: '#CF0F22', fontWeight: '400', borderRadius: '2px' },
  { name: 'Question', border: '1px solid #219FC7', backgroundColor: '#E8F5F9', color: '#219FC7', fontWeight: '400', borderRadius: '2px' },
  { name: 'For Testing', border: '1px solid #5B3694', backgroundColor: '#EEEAF4', color: '#5B3694', fontWeight: '400', borderRadius: '2px' },
  { name: 'Postpone', border: '1px solid #E43988', backgroundColor: '#FDEBF3', color: '#E43988', fontWeight: '400', borderRadius: '2px' },
  { name: 'Closed', border: '1px solid #53AD57', backgroundColor: '#EDF7EE', color: '#53AD57', fontWeight: '400', borderRadius: '2px' },
]

const badgeColorPriority = [
  { name: 'High', border: '1px solid #CF0F22', backgroundColor: '#FAE6E8', color: '#CF0F22', fontWeight: '400', borderRadius: '2px' },
  { name: 'Mid', border: '1px solid #FF8B00', backgroundColor: '#FFF3E5', color: '#FF8B00', fontWeight: '400', borderRadius: '2px' },
  { name: 'Low', border: '1px solid #FFC400', backgroundColor: '#FFF9E5', color: '#FFC400', fontWeight: '400', borderRadius: '2px' },
]

const fn = (order, down, originalIndex, curIndex, y) => index =>
  down && index === originalIndex
    ? { y: curIndex * 100 + y, scale: 1.01, zIndex: '1', shadow: 15, immediate: n => n === 'y' || n === 'zIndex' }
    : { y: order.indexOf(index) * 100, scale: 1, zIndex: '0', shadow: 1, immediate: false }

export default Form.create()(({ reducer, context, form }) => {
  const {state, dispatch} = reducer
  const [tpOpen, setTpOpen] = useState([false, false])
  const [popover, setPopover] = useState([])
  const [momentTime, setMomentTime] = useState([])
  const order = {current: state.timelogs.map((_, index) => index)} // Store indicies as a local ref, this represents the item order
  const [springs, setSprings] = useSprings(state.timelogs.length, fn(order.current)) // Create springs, each corresponds to an item, controlling its transform, scale, etc.
  const bind = useDrag(({ args: [originalIndex], down, movement: [, y] }) => {
    const curIndex = order.current.indexOf(originalIndex)
    const curRow = clamp(Math.round((curIndex * 100 + y) / 100), 0, state.timelogs.length - 1)
    const newOrder = swap(order.current, curIndex, curRow)
    setSprings(fn(newOrder, down, originalIndex, curIndex, y)) // Feed springs new style data, they'll animate the view without causing a single render
    if (!down) order.current = newOrder
  })

  useEffect(() => {
    if (state.timelogs.length !== 0)
      setMomentTime(state.timelogs.map((log, i) => ({start_time: time(log, i, 'start_time'), end_time: time(log, i, 'end_time')})))
    else 
      setMomentTime([])

    }, [state.timelogs.length, popover])

  const trail = useTrail(state.timelogs.length, {
    from: { marginLeft: -20, opacity: 0, transform: 'translate3d(0,-40px,0)' },
    to: { marginLeft: 20, opacity: 1, transform: 'translate3d(0,0px,0)' }
  })

  const handleVisibleChange = index => visible => {
    setPopover(state.timelogs.map((e,i) => i === index? visible: false));
    setTpOpen([false, false])
  }

  function time(log, index, property) {
    return log.stat === 'saved'? log[property] : !!momentTime[index]? momentTime[index][property]: ''
  }

  const hours_worked = () => {
    let hours = 0, min = 0, res = moment('00:00', 'HH:mm');
    
    state.timelogs.forEach(log => {      
      const duration = !!log.start_time && !!log.end_time ? log.end_time.diff(log.start_time): moment('00:00', 'HH:mm');
      res = res.add(duration)
    })
    hours = res.format('H')
    min = res.format('m')
    // user.timelogs.reduce(({hours_worked: a}, {hours_worked: b}) => ({hours_worked: moment(a, 'HH:mm').add(moment(b, 'HH:mm').format('HH'), 'hours').add(moment(b, 'HH:mm').format('mm'), 'minutes').format('HH:mm')})).hours_worked
    return hours != 0 || min != 0?(
      <>
        <span style={{ fontSize: '14px', fontWeight: 400 }}>
          {context.translation.hours_worked[context.locale]}
        </span> <br />
        {hours} {hours > 1 ? 'hours' : 'hour'} { min !== 0 ? min > 1 ? ('and ' + min + ' mins') : ('and ' + min + ' min'): ''}
      </>
    ): ''
  }

  return (
    <>
      { 
        state.loadingLogs? <Spinner2 style={{marginTop: '230px'}}/> : 
        springs.length === 0 ? <Empty style={{marginTop: '230px', backgroundColor: 'transparent'}} otherLogo/> :
        (
          <>
            <div className="timelog-list" style={{ height: state.timelogs.length * 100 }}>
              {springs.map(({ zIndex, shadow, y, scale }, i) => (
                  <animated.div
                    key={i}
                    style={{
                      zIndex,
                      boxShadow: shadow.to(s => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`),
                      y,
                      scale,
                      backgroundColor: (state.timelogs[i].stat !== 'saved')? '#fffcf4': '#fff'
                    }}
                    className="d-flex align-items-center"
                    // children={}
                  >
                    <div {...bind(i)} className="draggable"><AlignJustify /></div>

                    <Popover 
                      id="popup-timelog"
                      content={<PopoverContent isFromTimelog={true} context={context} reducer={{ state, dispatch }} form={form} momentTime={{ momentTime: momentTime, setMomentTime }} popover={{ popover: popover[i], setPopover }} tpOpen={{ tpOpen, setTpOpen }} data={{ selected: state.timelogs[i], index: i }} />} 
                      placement="top" 
                      trigger="click"
                      title={context.translation.log_point[context.locale] + state.timelogs[i].point_nr} 
                      visible={popover[i]}
                      onVisibleChange={handleVisibleChange(i)}
                    >
                      <div className="timelog-item d-flex align-items-center">
                          <Row className="timelog-item-header">
                            <Col xs={3}><p>{context.translation.instruction[context.locale]}</p></Col>
                            <Col xl={2} xs={1}><p>{context.translation.nav.project[context.locale]}</p></Col>
                            <Col xs={2}><p>{context.translation.client[context.locale]}</p></Col>
                            <Col><p>{context.translation.start_time[context.locale]}</p></Col>
                            <Col><p>{context.translation.end_time[context.locale]}</p></Col>
                            <Col><p>Status</p></Col>
                            <Col><p>{context.translation.priority[context.locale]}</p></Col>
                          </Row>
                        <Row>
                          <Col xs={3}><span>{state.timelogs[i].label}</span></Col>
                          <Col xl={2} xs={1}><span>{state.timelogs[i].proj_name}</span></Col>
                          <Col xs={2}><span>{state.timelogs[i].client_name}</span></Col>
                          <Col><span>{!!state.timelogs[i].start_time? moment(state.timelogs[i].start_time, 'HH:mm:ss').format('h:mm a'): ''}</span></Col>
                          <Col><span>{!!state.timelogs[i].end_time? moment(state.timelogs[i].end_time, 'HH:mm:ss').format('h:mm a'): ''}</span></Col>
                          <Col><span className={`badge badge-sm ${state.timelogs[i].status === 'Draft' && "bg-light"}`} style={{ ...badgeColorStatus.find(e => e.name === state.timelogs[i].status) }}>{context.translation.status[state.timelogs[i].status.toLowerCase().replace(' ', '_')][context.locale]}</span></Col>
                          <Col><span className={`badge badge-sm`} style={{ ...badgeColorPriority.find(e => e.name === state.timelogs[i].priority) }}>{context.translation[state.timelogs[i].priority.toLowerCase()][context.locale]}</span></Col>
                        </Row>
                      </div>
                    </Popover>
                  </animated.div>
              ))}
            </div>
            <Row className="mt-3">
              <Col style={{ textAlign: 'center' }}>
                <span style={{ fontSize: '1.5em', fontWeight: 500 }}>
                  {hours_worked()}
                </span>
              </Col>
            </Row>
          </>
        )
      }
    </>
  );
})
