import React, { useState, useContext, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { message } from 'antd';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Page, Card, Modal, Toast, Spinner2, Empty } from '../../common';
import {
  Row,
  Col,
  Alert,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { ApolloContext } from 'react-apollo';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { AuthContext } from '../../../contexts/AuthContext';
import {
  CLIENTS_QUERY,
  CREATE_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
} from '../../../queries';

export default () => {
  const initialErrorState = {
    clientnr: {
      invalid: false,
      message: '',
    },
    clientname: {
      invalid: false,
      message: '',
    },
  };
  const { SearchBar } = Search;
  const [clients, setClients] = useState([]);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(true);
  const [selected, setSelected] = useState([]);
  const [modalType, setModalType] = useState();
  const [errors, setErrors] = useState(initialErrorState);
  const [form, setForm] = useState({ client_id: '', client_name: '' })
  const [statusCount, setStatusCount] = useState({});
  const { client } = useContext(ApolloContext);
  const context = useContext(GlobalContext);
  const { user } = useContext(AuthContext);
  const selectedRow =
    user.role === 'Administrator'
      ? {
          selectRow: {
            mode: 'checkbox',
            selected,
            onSelect: (row, isSelect, rowIndex, e) => {
              if (isSelect) setSelected([...selected, row.id]);
              else setSelected(selected.filter((x) => x !== row.id));
            },
            onSelectAll: (isSelect, rows, e) => {
              const ids = rows.map((r) => r.id);
              if (isSelect) setSelected(ids);
              else setSelected([]);
            },
          },
        }
      : {};

  useEffect(() => {
    context.dispatch({
      type: 'SET_STATE',
      payload: {
        loc: 'essentials',
        showFab: false,
        tooltipTittle: context.translation.add_client[context.locale],
        fabAction: () => {
          setModal(!modal);
          setModalType('create');
        },
      },
    }); // Hide FAB

    setLoading((data) => !data);
    client
      .query({ query: CLIENTS_QUERY })
      .then(({ data }) => {
        setLoading((data) => !data);
        setClients(data.clients);
        setStatusCount(data.projectStatusCount[0]);
      })
      .catch((error) => setLoading((data) => !data));
  }, [client, modal]);

  useEffect(() => {
    return () => {
      // Trigger if component will unmount
      context.dispatch({
        type: 'SET_STATE',
        payload: {
          loc: '',
          showFab: false,
          isInteraction: false,
          modal1: false,
        },
      }); // Hide FAB
    };
  }, []);

  /* MODAL OK METHOD */
  const confirmDelete = async (e) => {
    e.preventDefault();
    selected.sort((a, b) => a - b);

    const hide = message.loading(
      context.translation.saving_changes[context.locale],
      0
    );
    try {
      const { data } = await client.mutate({
        mutation: DELETE_CLIENT,
        variables: { ids: selected },
      });
      setClients(clients.filter((x) => !selected.includes(x.id)));
      setSelected([]);

      hide();
      message.success(context.translation.msg_client_deleted[context.locale]);
      // Toast.showToast('success', context.translation.msg_client_deleted[context.locale])
    } catch (error) {
      const err = error.toString().split(':');
      console.log(err);
      hide();
      message.error(`${err[3]}`);
      // Toast.showToast('error', `${err[3]}`)
    }

    return true;
  };

  const confirmCreate = async (e) => {
    e.preventDefault();
    setErrors(initialErrorState);
    const hide = message.loading(
      context.translation.saving_changes[context.locale],
      0
    );
    try {
      const formData = new FormData(document.forms.namedItem('modal-form'));
      let payload = {};
      formData.forEach((value, key) => (payload[key] = value));

      const { data } = await client.mutate({
        mutation: CREATE_CLIENT,
        variables: { data: payload },
      });
      setClients([...clients, data.createClient]);
      hide();
      message.success(context.translation.msg_client_success[context.locale]);
      // Toast.showToast('success', context.translation.msg_client_success[context.locale])
      return true;
    } catch (error) {
      hide();
      error.message = error.message.replace('GraphQL error: ', '');
      const error_list = ['clientnr', 'clientname'];
      let error_msg = JSON.parse(error.message);
      error_list.forEach((e) => {
        if (error_msg[e])
          if (error_msg[e].invalid)
            error_msg[e].message =
              context.translation.errors.client[e][context.locale];
      });
      setErrors({ ...errors, ...error_msg });
      return false;
    }
  };

  const modalSettings = {
    method: {
      delete: confirmDelete,
      create: confirmCreate,
    },
    title: {
      delete: context.translation.delete_client[context.locale],
      create: context.translation.add_client[context.locale],
    },
    body: {
      delete: context.translation.msg_delete_client[context.locale],
      create: (
        <>
          <FormGroup>
            <Label for="clientnr">
              {context.translation.client[context.locale]} ID
            </Label>
            <Input
              name="clientnr"
              id="clientnr"
              onChange={(event) => {
                if (context.isTourOpen) {
                  if (event.target.value !== '' && !context.isInteraction) {
                    context.dispatch({ type: 'SET_STATE', payload: { isInteraction: true } })
                  } else if (event.target.value === '' && context.isInteraction) {
                    context.dispatch({ type: 'SET_STATE', payload: { isInteraction: false } })
                  }
                }
              }}
              invalid={errors.clientnr.invalid}
              bsSize="sm"
              required
            />
            <FormFeedback>{errors.clientnr.message}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="clientname">
              {context.translation.client_name[context.locale]}
            </Label>
            <Input
              name="clientname"
              id="clientname"
              onChange={(event) => {
                if (context.isTourOpen) {
                  if (event.target.value !== '' && !context.isInteraction) {
                    context.dispatch({ type: 'SET_STATE', payload: { isInteraction: true } })
                  } else if (event.target.value === '' && context.isInteraction) {
                    context.dispatch({ type: 'SET_STATE', payload: { isInteraction: false } })
                  }
                }
              }}
              required
              invalid={errors.clientname.invalid}
              bsSize="sm"
              required
            />
            <FormFeedback>{errors.clientname.message}</FormFeedback>
          </FormGroup>
        </>
      ),
    },
  };

  function addClient() {
    setModal(true);
    setModalType('create');

    if (context.isTourOpen)
      context.dispatch({ type: 'SET_STATE', payload: { goToStep: 5 } });
  }
  /* MODAL OK METHOD END */

  const updateClient = (newValue, row, column, done) => {
    const hide = message.loading(
      context.translation.saving_changes[context.locale],
      0
    );
    return client
      .mutate({
        mutation: UPDATE_CLIENT,
        variables: {
          data: {
            [column.dataField]: newValue,
            type: { [column.dataField]: row[column.dataField] },
          },
          id: parseInt(row.id),
        },
      })
      .then(({ data }) => {
        clients.forEach((e) => {
          if (e.id === data.updateClient.id) e = data.updateClient;
        });

        setClients(clients);
        hide();
        message.success(context.translation.msg_client_success[context.locale]);
        // Toast.showToast('success', context.translation.msg_client_success[context.locale]);
        return done();
      })
      .catch((error) => {
        hide();
        const err = error.toString().split(':');
        console.log(err);
        return done({ valid: false, message: err[3] });
      });
  };

  /* TABLE SETTINGS */
  const columns = [
    {
      dataField: 'id',
      text: 'id',
      hidden: true,
    },
    {
      dataField: 'clientnr',
      text: 'ID',
      sort: true,
      validator: (newValue, row, column, done) => {
        if (newValue) {
          updateClient(newValue, row, column, done);
        } else {
          return {
            valid: false,
            message: context.translation.error_client_id[context.locale],
          };
        }
        return {
          async: true,
        };
      },
    },
    {
      dataField: 'clientname',
      text: context.translation.client_name[context.locale],
      sort: true,
      validator: (newValue, row, column, done) => {
        if (newValue) {
          updateClient(newValue, row, column, done);
        } else {
          return {
            valid: false,
            message: context.translation.error_client_name[context.locale],
          };
        }
        return {
          async: true,
        };
      },
    },
    {
      dataField: 'open_projects',
      text: context.translation.open_projects[context.locale],
      sort: true,
      editable: false,
    },
    {
      dataField: 'closed_projects',
      text: context.translation.closed_projects[context.locale],
      sort: true,
      editable: false,
    },
  ];

  const beforeSaveCell = async (oldValue, newValue, row, column, done) => {
    const hide = message.loading(
      context.translation.saving_changes[context.locale],
      0
    );

    if (oldValue !== newValue) {
      try {
        const { data } = await client.mutate({
          mutation: UPDATE_CLIENT,
          variables: {
            data: { [column.dataField]: newValue },
            id: parseInt(row.id),
          },
        });
        clients.forEach((e) => {
          if (e.id === data.updateClient.id) e = data.updateClient;
        });

        setClients(clients);
        hide();
        message.success(context.translation.msg_client_updated[context.locale]);
        // Toast.showToast('success', context.translation.msg_client_updated[context.locale]);
      } catch (error) {
        console.log(error);
        hide();
        message.error(error);
        // Toast.showToast('error', error);
      }
    }
  };
  /* TABLE SETTINGS END */
  return (
    <Page
      title={context.translation.client[context.locale]}
      components={
        <span style={{ position: 'absolute', right: '1.3em' }}>
          <span className="mr-2">
            <span
              style={{ fontSize: '.875rem', fontWeight: 'normal' }}
              className="badge badge-info"
            >
              {context.translation.total_projects[context.locale]}:{' '}
              {statusCount.total_projects}
            </span>
          </span>
          <span className="mr-2">
            <span
              style={{ fontSize: '.875rem', fontWeight: 'normal' }}
              className="badge badge-primary"
            >
              {context.translation.open_projects[context.locale]}:{' '}
              {statusCount.open_projects}
            </span>
          </span>
          <span className="mr-2">
            <span
              style={{ fontSize: '.875rem', fontWeight: 'normal' }}
              className="badge badge-secondary"
            >
              {context.translation.closed_projects[context.locale]}:{' '}
              {statusCount.closed_projects}
            </span>
          </span>
        </span>
      }
    >
      <Row className="d-flex align-items-center mb-3 mt-2">
        {/* <Col xs={12} className="d-flex align-items-center" style={{ position: 'relative' }}>
          <span className="mr-2"><span style={{ fontSize: '.875rem', fontWeight: 'normal' }} className="badge badge-info"> Total Projects: { statusCount.total_projects }</span></span>
          <span className="mr-2"><span style={{ fontSize: '.875rem', fontWeight: 'normal' }} className="badge badge-primary">Open Projects: { statusCount.open_projects }</span></span>
          <span className="mr-2"><span style={{ fontSize: '.875rem', fontWeight: 'normal' }} className="badge badge-secondary">Closed Projects: { statusCount.closed_projects }</span></span>

          <button onClick={() => { setModal(!modal); setModalType('create') }} className="btn btn-sm btn-outline-exact" style={{ position: 'absolute', right: '1.15em' }}>
            <i className="fas fa-user-plus"></i> Add Client
          </button>
        </Col> */}

        <Col xs="5" className="d-flex align-items-center">
          <Modal
            location={4}
            saveLabel={
              modalType === 'delete' &&
              context.translation.proceed[context.locale]
            }
            title={modalSettings.title[modalType]}
            modal={modal}
            setModal={setModal}
            accessFunction={modalSettings.method[modalType]}
          >
            {modalSettings.body[modalType]}
          </Modal>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Card>
            {/* <h5>Client List</h5><br /> */}
            <ToolkitProvider
              keyField="id"
              bootstrap4
              columns={columns}
              data={clients}
              search
            >
              {(props) => (
                <>
                  <Alert
                    color="info"
                    isOpen={visible}
                    toggle={() => setVisible(false)}
                  >
                    <i className="fas fa-info-circle"></i>{' '}
                    {context.translation.msg_double_click[context.locale]}
                  </Alert>
                  {/* <button onClick={() => {setModal(!modal); setModalType('delete')}} className="btn btn-sm btn-exactplace" >
                      <i className="fas fa-trash-alt"></i> Delete
                    </button> */}
                  {/* <Tooltip title="Add">
                      <IconButton aria-label="Add" onClick={() => { setModal(!modal); setModalType('create') }}>
                        <AddIcon
                        />
                      </IconButton>
                    </Tooltip> */}
                  <Fab
                    id="step-5"
                    variant="extended"
                    size="small"
                    color="primary"
                    aria-label="Add"
                    onClick={addClient}
                  >
                    <AddIcon />
                    <span>
                      {context.translation.add_client[context.locale]}
                    </span>
                  </Fab>{' '}
                  <Tooltip title="delete">
                    <IconButton
                      className={`${
                        selected.length !== 0 ? 'scale-in-center' : 'scale-hide'
                      }`}
                      aria-label="Delete"
                      onClick={() => {
                        setModal(!modal);
                        setModalType('delete');
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <SearchBar
                    placeholder={context.translation.search[context.locale]}
                    {...props.searchProps}
                  />
                  <BootstrapTable
                    {...props.baseProps}
                    {...selectedRow}
                    cellEdit={cellEditFactory({
                      mode: 'dbclick',
                      blurToSave: true,
                      // beforeSaveCell
                    })}
                    noDataIndication={() =>
                      loading ? <Spinner2 /> : <Empty />
                    }
                    bordered={false}
                    hover
                  />
                </>
              )}
            </ToolkitProvider>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};
