import React, { useContext, useEffect, useReducer, useState } from 'react';
import { withRouter } from 'react-router-dom'
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import { Card, Page, Filter } from '../../common';
import FeedbackList from './FeedbackList';
import FeedbackFilter from './FeedbackFilter';
import FeedbackReducer from './FeedbackReducer'
import Modal from './form/FeedbackFormModal'
import PushNotification from '../../../PushNotification'

import { useSubscription } from 'react-apollo-hooks';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { ApolloContext } from 'react-apollo';
import { FEEDBACKS_QUERY, PROJECT_CLIENT_QUERY, NEW_FEEDBACK, UPDATED_POINT } from '../../../queries';


const initialEditedItem = {
  user: { id: 0, name: '' },
  instructions: '',
  status: 'Open', 
  deadline: '',
  time_estimate: '',
  hours_worked: '',
  version: 0,
  priority: 'Low',
  comments: [],
  users: [],
  deadline: moment(),
  deadlineDisplay: 'N/A',
  comment: '',
}

const editField = {
  editPoint: false,
  editInstructions: false,
  editStatus: false,
  editTimeEstimate: false,
  editHoursWorked: false,
  editDeadline: false,
  editPriority: false,
  editAssignedUsers: false,
  editVersion: false,
}

const initialStatusCount = [
  {no_status: 0, status: "Archive", total_time_estimate: '00:00'},
  {no_status: 0, status: "Closed", total_time_estimate: '00:00'},
  {no_status: 0, status: "Draft", total_time_estimate: '00:00'},
  {no_status: 0, status: "For Testing", total_time_estimate: '00:00'},
  {no_status: 0, status: "Open", total_time_estimate: '00:00'},
  {no_status: 0, status: "Question", total_time_estimate: '00:00'},
  {no_status: 0, status: "Postpone", total_time_estimate: '00:00'}
]

const initialState = {
  ...editField,
  paginationLoading: false,
  loading: false,
  tableLoading: false,
  modal: false,
  modalType: '',
  feedbacks: [],
  prevFeedbacks: [],
  expand: [],
  statusCount: [...initialStatusCount],
  files: [],
  removefiles: [],
  project: {
    id: -1,
    name: ''
  },
  gallery: [],
  members: [],
  editedItem: {...initialEditedItem},
  editPoint: false,
  uploadNewFiles: false,
  loadURI: '',
  rowData: {files: []},
  dupFiles: [],
  versions: [],
  deleteType: '',
  isFeedbackList: 'feedback',
  status: 'Open',
  feedbackPageLength: 0,
  prevFeedbackPageLength: 0,
  page: 1,
  count: 20
}

let feedbacks = []

export default withRouter((props) => {
  const context = useContext(ApolloContext)
  const globalCtx = useContext(GlobalContext);
  const [state, dispatch] = useReducer(FeedbackReducer, initialState)
  console.log(state)
  console.log(state.feedbacks.filter(e => e.versions.length !== 0).map(e => ({...e, versions: e.versions.map(ver => ({...ver, points: ver.points}))})))
  const { status } = props.match.params 
  const [subscription, setSubscription] = useState()
  const newFeedback = useSubscription(NEW_FEEDBACK, { variables: { data: globalCtx.selectedFilter } });
  const updatedPoint = useSubscription(UPDATED_POINT, { variables: { data: { subscription } } });
  // console.log(newFeedback)
  // console.log(updatedPoint)

  useEffect(() => {
    if (newFeedback.data)
      dispatch({ type: 'SET_STATE', payload: { feedbacks: [{...newFeedback.data.newFeedback}, ...state.feedbacks] } })
  }, [newFeedback.data])

  useEffect(() => {
    // console.log(state.staus)
    if (updatedPoint.data)
      dispatch({ type: 'SET_STATE', 
        payload: { 
        feedbacks: state.feedbacks.map(e => ({
            ...e,
            versions: e.versions.map(version => ({
              ...version,
              points: state.status.toLowerCase() === 'all' || state.status.toLowerCase() == updatedPoint.data.updatedPoint.status.toLowerCase()? 
              version.points.map(point => (point.id == updatedPoint.data.updatedPoint.id)? { ...point, ...updatedPoint.data.updatedPoint }: { ...point }):
              version.points.filter(point => point.id != updatedPoint.data.updatedPoint.id)
            })).filter(e => e.points.length !== 0)
          }))
        }
      })
  }, [updatedPoint.data])

  useEffect(() => {
    (async () => {
      setSubscription(await PushNotification())
    })()

    context.client.query({ query: PROJECT_CLIENT_QUERY('true') }).then(({ data }) => {
      // console.log(data);
      const { projects, clients } = data;

      globalCtx.dispatch({ type: 'SET_PROJECT_CLIENT_DATA', projects, clients })
    })

    dispatch({ type: 'SET_STATE', payload: { paginationLoading: true, status } })
    loadTable(globalCtx.selectedFilter, 9, 0)
    if (globalCtx.isOpen)
      globalCtx.dispatch({ type: 'SET_STATE', payload: { isOpen: !globalCtx.isOpen, isFeedbackList: true } })

    return () => { // Trigger if component will unmount
      if (globalCtx.uploadFiles)
        globalCtx.dispatch({ type: 'SET_STATE', payload: { uploadFiles: false, files: [], isFeedbackList: false } })
      else 
        globalCtx.dispatch({ type: 'SET_STATE', payload: { isFeedbackList: false } })
    }
  }, [])

  useEffect(() => {
    if (!state.modal)
      dispatch({ type: 'SET_STATE', payload: { editedItem: {...initialEditedItem}, files: [], removefiles: [], gallery: [], editPoint: false, ...editField } })
  }, [state.modal])

  useEffect(() => {
    if (state.feedbacks.length !== 0 && state.loading === true)
      dispatch({ type: 'SET_STATE', payload: { loading: false, tableLoading: false } })
  }, [state.feedbacks.length])

  // useEffect(() => {
  //   console.log(state.feedbacks)
  // }, [state.feedbacks])

  useEffect(() => {
    (async () => {
      if (globalCtx.fetchingFeedbacks) {
        await loadTable(globalCtx.selectedFilter, 9, state.count)
        dispatch({ type: 'SET_STATE', payload: { count: state.count + 20 } })
        globalCtx.dispatch({ type: 'SET_STATE', payload: { fetchingFeedbacks: false } })
      }
    })()
  }, [globalCtx.fetchingFeedbacks])

  const loadTable = async (filter, tab, offset) => {
    const stats = (status.split('_').map(e => e.charAt(0).toUpperCase() + e.substring(1))).toString().replace(',',' ')
    dispatch({ type: 'SET_STATE', payload: { loading: true, tableLoading: true, feedbacks: [] } })
    feedbacks = []

    // console.log(filter)

    /* Get feedback count before querying by batch */
    // if (data.feedbacksCount[0].totalCount != 0 || data.feedbacksCount[0].totalCount != 1) {
      // let myArray = new Array(data.feedbacksCount[0].totalCount)
      // myArray.fill(1)
    //  const promises = myArray.map(async (e,i) => await context.client.query({ query: FEEDBACKS_QUERY(filter, i*10) }).then(({ data }) => {
    //     feedbacks = [...feedbacks, ...data.feedbacks]
    //     dispatch({ type: 'SET_STATE', payload: { feedbacks } })
    //   }))
  
      // await Promise.all(promises)
      await context.client.query({ query: FEEDBACKS_QUERY(filter, stats, offset, '') }).then(async ({ data }) => {
        feedbacks = [...feedbacks, ...data.feedbacks]
        const statusCount = await initialStatusCount.map(e => {
          const dataCount = data.statusCount.find(es => es.status === e.status)
          return {
            ...e, 
            no_status: data.statusCount.length !== 0? dataCount ? dataCount.no_status : 0 : 0,
            total_time_estimate: data.statusCount.length !== 0? dataCount ? dataCount.total_time_estimate : '00:00' : '00:00'
          }
        })
        dispatch({ type: 'SET_STATE', payload: { prevFeedbacks: feedbacks, feedbacks, statusCount, feedbackPageLength: data.feedbacksCount[0].totalCount, prevFeedbackPageLength: data.feedbacksCount[0].totalCount } })
      })

      if (tab === 9)
        dispatch({ type: 'SET_STATE', payload: { paginationLoading: false } })    

      feedbacks.sort(function(a, b) {
        a = new Date(a.date_saved);
        b = new Date(b.date_saved);
        return a>b ? -1 : a<b ? 1 : 0;
      })
      dispatch({ type: 'SET_STATE', payload: { feedbacks, loading: false, tableLoading: false } })
    // } else {
      // dispatch({ type: 'SET_STATE', payload: { loading: false, tableLoading: false } })
    // } 
  }

  return (
    <Page title={globalCtx.translation.feedback_list[globalCtx.locale]} components={<Filter activeTab={1} loadTable={loadTable} projects={globalCtx.projects} clients={globalCtx.clients} selectedFilter={globalCtx.selectedFilter} dispatch={globalCtx.dispatch} />}>          
      <Modal state={state} dispatch={dispatch} />
      <Row id="feedbacklist" style={{ marginTop: '23px' }}>
        <Col style={{ maxWidth: '15%' }}>
          <FeedbackFilter state={state} dispatch={dispatch} stats={status} initialStatusCount={initialStatusCount} />
        </Col>
        <Col style={{ maxWidth: '85%' }}>
          <Card>
            <FeedbackList 
              reducer={{ 
                loadTable, 
                state, 
                dispatch, 
                feedbacks: state.feedbacks}}
            />
          </Card>
        </Col>
      </Row>
    </Page>
  )
})