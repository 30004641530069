import React, { useState, useEffect, useContext } from 'react';
import Moment from 'react-moment';
import { Badge } from 'reactstrap'
import { Table } from 'antd'
import BootstrapTable from 'react-bootstrap-table-next';
import { Empty, Spinner2 } from '../../../common';
import moment from 'moment';

const badgeColorStatus = [
  { name: 'Open', border: '1px solid #CF0F22', backgroundColor: '#FAE6E8', color: '#CF0F22', fontWeight: '400', borderRadius: '2px' },
  { name: 'Question', border: '1px solid #219FC7', backgroundColor: '#E8F5F9', color: '#219FC7', fontWeight: '400', borderRadius: '2px' },
  { name: 'For Testing', border: '1px solid #5B3694', backgroundColor: '#EEEAF4', color: '#5B3694', fontWeight: '400', borderRadius: '2px' },
  { name: 'Postpone', border: '1px solid #E43988', backgroundColor: '#FDEBF3', color: '#E43988', fontWeight: '400', borderRadius: '2px' },
  { name: 'Closed', border: '1px solid #53AD57', backgroundColor: '#EDF7EE', color: '#53AD57', fontWeight: '400', borderRadius: '2px' },
]

const getDate = (day, locale, filter) => {
  // console.log(day)
  // console.log(filter.date.format('YYYY-MM-DD'))
  // console.log(filter.date.day(1).format('YYYY-MM-DD'))
  return moment(filter.date).day(8 - day).format(locale == 'de' ? 'D.M.YYYY' : 'YYYY-MM-DD')
}

const __Table = (data, sumTime, filter) => ({
  className,
  style,
  children
}) => (
  <table className={className} style={style} key={'tableKey'}>
    {children}

    <tfoot>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        {
          [7,6,5,4,3,2,1].map((e, i) => {
            const time = data.map(s => s[getDate(e, 'en', filter)]).reduce((a, b) => sumTime(a,b), '00:00')

            return (
              <td align="center" key={i}>
                <b>{ time }</b>
              </td>
            )
          })
        }
        <td align="center" style={{ height: '54px' }}>
          <b>{ data.map(s => s.total_hours).reduce((a, b) => sumTime(a,b), '00:00') }</b>
        </td>
      </tr>
    </tfoot>
  </table>
);

export default ({ data, loading, context, sumTime, filter }) => {
  const [expand, setExpand] = useState([])

  // const getDate = (day, locale) => {
  //   let date = new Date()
  //   date.setDate(new Date().getDate() - day)
  //   return date.toLocaleDateString(locale)
  // }

  const columns = [{
    dataIndex: 'point_nr',
    key: 'point_nr',
    title: 'Point Nr.',
    // headerStyle: (colum, colIndex) => ({ width: '60px' })
    width: '60px',
  },  {
    dataIndex: 'client',
    key: 'client',
    title: context.translation.clien[context.locale],
    width: '200px'
  }, {
    dataIndex: 'instructions',
    key: 'instructions',
    title: context.translation.instruction[context.locale],
    // headerStyle: (colum, colIndex) => ({ width: '20%' }),
    width: '300px',
  }, {
    dataIndex: 'status',
    key: 'status',
    title: 'Status',
    // formatter: (cell, row, rowIndex, formatExtraData) => <span className={`badge badge-sm ${cell === 'Draft' && "bg-light"}`} style={{ ...badgeColorStatus.find(e => e.name === cell) }}>{context.translation.status[cell.toLowerCase().replace(' ', '_')][context.locale]}</span>
    render: cell => <span className={`badge badge-sm ${cell === 'Draft' && "bg-light"}`} style={{ ...badgeColorStatus.find(e => e.name === cell) }}>{context.translation.status[cell.toLowerCase().replace(' ', '_')][context.locale]}</span>,
    headerAlign: 'center',
    align: 'center',
    width: '200px'
  }, {
    dataIndex: 'time_estimate',
    key: 'time_estimate',
    title: context.translation.time_estimate[context.locale],
    footer: '',
    footerAttrs: (column, colIndex) => ({ colSpan: 4 }),
    render: cell => <b>{ cell }</b>,
    headerAlign: 'center',
    align: 'center',
    width: '100px'
  }, {
    dataIndex: getDate(7, 'en', filter),
    key: getDate(7, context.locale, filter),
    title: getDate(7, context.locale, filter),
    footer: columnData => columnData.reduce((a, b) => sumTime(a,b), '00:00'),
    render: cell => cell || '-',
    footerAlign: 'center',
    headerAlign: 'center',
    align: 'center',
    width: '110px'
  }, {
    dataIndex: getDate(6, 'en', filter),
    key: getDate(6, context.locale, filter),
    title: getDate(6, context.locale, filter),
    footer: columnData => columnData.reduce((a, b) => sumTime(a,b), '00:00'),
    render: cell => cell || '-',
    footerAlign: 'center',
    headerAlign: 'center',
    align: 'center',
    width: '110px'
  }, {
    dataIndex: getDate(5, 'en', filter),
    key: getDate(5, context.locale, filter),
    title: getDate(5, context.locale, filter),
    footer: columnData => columnData.reduce((a, b) => sumTime(a,b), '00:00'),
    render: cell => cell || '-',
    footerAlign: 'center',
    headerAlign: 'center',
    align: 'center',
    width: '110px'
  }, {
    dataIndex: getDate(4, 'en', filter),
    key: getDate(4, context.locale, filter),
    title: getDate(4, context.locale, filter),
    footer: columnData => columnData.reduce((a, b) => sumTime(a,b), '00:00'),
    render: cell => cell || '-',
    footerAlign: 'center',
    headerAlign: 'center',
    align: 'center',
    width: '110px'
  }, {
    dataIndex: getDate(3, 'en', filter),
    key: getDate(3, context.locale, filter),
    title: getDate(3, context.locale, filter),
    footer: columnData => columnData.reduce((a, b) => sumTime(a,b), '00:00'),
    render: cell => cell || '-',
    footerAlign: 'center',
    headerAlign: 'center',
    align: 'center',
    width: '110px'
  }, {
    dataIndex: getDate(2, 'en', filter),
    key: getDate(2, context.locale, filter),
    title: getDate(2, context.locale, filter),
    footer: columnData => columnData.reduce((a, b) => sumTime(a,b), '00:00'),
    render: cell => cell || '-',
    footerAlign: 'center',
    headerAlign: 'center',
    align: 'center',
    width: '110px'
  }, {
    dataIndex: getDate(1, 'en', filter),
    key: getDate(1, context.locale, filter),
    title: getDate(1, context.locale, filter),
    footer: columnData => columnData.reduce((a, b) => sumTime(a,b), '00:00'),
    render: cell => cell || '-',
    footerAlign: 'center',
    headerAlign: 'center',
    align: 'center',
    width: '110px'
  }, {
    dataIndex: 'total_hours',
    key: 'total_hours',
    title: context.translation.total_hours[context.locale],
    footer: columnData => columnData.reduce((a, b) => sumTime(a,b), '00:00'),
    footerAlign: 'center',
    headerAlign: 'center',
    align: 'center',
    width: '100px'
  }]

  
  return (
    <div style={{ marginTop: '16px' }}>
      {/* <BootstrapTable
        id="table-developer-analysis"
        keyField="id"
        bootstrap4
        columns = { columns }
        data = { data }
        noDataIndication = { () => loading ? <Spinner2 />: <Empty /> }
        bordered = { false }
        style={{ marginTop: '12px' }}
      /> */}
      <Table 
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ x: true }} 
        components={{ table: __Table(data, sumTime, filter) }}
      />
    </div>
  )
};