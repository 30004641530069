import React, { useState, useEffect, useContext } from 'react'
import { Steps, Button, message } from 'antd';
import { Modal } from '../../../../common';
import Client from './steps/Client';
import Project from './steps/Project';
import User from './steps/User';

import { UPSERT_REQUEST } from '../../../../../queries';
import { AuthContext } from '../../../../../contexts/AuthContext';
import { ApolloContext } from 'react-apollo'; 


const { Step } = Steps;

const steps = [
  {
    title: 'clien',
    content: 'First-content',
  },
  {
    title: 'project',
    content: 'Second-content',
  },
  {
    title: 'create_user',
    content: 'Last-content',
  },
];

export default ({ reducer: { state, dispatch }, context, initialErrorState }) => {
  const [current, setCurrent] = useState(0)
  const { client } = useContext(ApolloContext)
  const { user } = useContext(AuthContext)

  function handleNext() {
    // Made an object to reduce duplicate if statement (but different property: client, project) (selected property is based on the "current" state value)
    let selected_property = {
      0: {
        type: 'client',
        id: 'client_id',
        translation: 'search_a_client'
      },
      1: {
        type: 'project',
        id: 'proj_id',
        translation: 'search_a_project'
      }
    }

    // ===== VALIDATION =====
    // If client is existing and didn't choose a client, return error message
    if (current !== 2) {
      if (((current === 0 && state.modalField.client.type !== 'New') ||  // If current step is on client step, check if user selected 'Existing' option
            (current === 1 && ['project', 'client'].every(e => state.modalField[e].type !== 'New'))) &&  // If current step is on project step -> check both project and client type if the user selected 'Existing' option
              state.modalField[selected_property[current].type].id == -1) {  // Validation for for existing client form
                console.log(state.modalField[selected_property[current].type])
        dispatch({ 
          type: 'SET_STATE', 
          payload: { 
            errors: { 
              ...state.errors, 
              [selected_property[current].type]: { 
                ...state.errors[selected_property[current].type], 
                [selected_property[current].id]: { 
                  invalid: true, 
                  message: context.translation[selected_property[current].translation][context.locale] 
                } 
              } 
            } 
          } 
        })
        return 
      } else if (state.modalField.client.type === 'New' && current === 0) { // Validation for new client form
        let isError = false, clientError = {}
  
        if (state.modalField.client.clientnr.trim() === '') {
          clientError = {
            clientnr: { 
              invalid: true, 
              message: context.translation.error_client_id[context.locale] 
            }
          }
          dispatch({ type: 'SET_STATE', payload: { errors: { ...state.errors, client: { ...state.errors.client, clientnr: { invalid: true, message: context.translation.error_client_id[context.locale] } } } } })
          isError = true
        } else {
          clientError = { clientnr: { invalid: false, message: '' } }
        }
        
        if (state.modalField.client.clientname.trim() === '') {
          clientError = {
            ...clientError,
            clientname: { 
              invalid: true, 
              message: context.translation.error_client_name[context.locale] 
            }
          }
          dispatch({ type: 'SET_STATE', payload: { errors: { ...state.errors, client: { ...state.errors.client, clientname: { invalid: true, message: context.translation.error_client_name[context.locale] } } } } })
          isError = true
        } else {
          clientError = { ...clientError, clientname: { invalid: false, message: '' } }
        }
  
        if (isError) {
          dispatch({ type: 'SET_STATE', payload: { errors: { ...state.errors, client: { ...state.errors.client, ...clientError } } } })
          return
        }
      } else if (state.modalField.client.type === 'New' && current === 1) { // Validation for new project form
        if (validateProject()) {
          let validProjectForm = true;
          let projectError = {};

          ['proj_nr', 'proj_name'].forEach(e => {
            console.log(e)
            console.log(state[e])
            if (state[e] == '') {
              projectError[e] = {
                invalid: true,
                message: context.translation.errors.project.empty[e][context.locale]
              }
              validProjectForm = false
            } else  {
              projectError[e] = {
                invalid: false,
                message: ''
              }
            }
          })

          if (Object.keys(projectError).length !== 0) 
            dispatch({ type: 'SET_STATE', payload: { errors: { ...state.errors, project: { ...state.errors.project, ...projectError } } } })

          if (!validProjectForm)
            return
        } else {
          return
        }
      }
    }

    resetErrorState(true)
  } 

  function validateProject() {
    let hasProjectLead = false
    let hasVersion = state.versions.length !== 0
  
    for (let user in state.team) {
      if (state.team[user].proj_user_type_id === 3) {
        hasProjectLead = true
        break
      }
    }
    
    let errorState = {};
    if (!hasProjectLead && !hasVersion)
      errorState = { ...state.errors.project, version: { invalid: true, message: context.translation.errors.project.version[context.locale] }, team: { invalid: true, message: context.translation.errors.project.team[context.locale] } }
    else if (!hasVersion) 
      errorState = { ...state.errors.project, version: { invalid: true, message: context.translation.errors.project.version[context.locale] }}
    else if (!hasProjectLead)
      errorState = { ...state.errors.project, team: { invalid: true, message: context.translation.errors.project.team[context.locale] } }

      console.log(errorState)
  
    dispatch({ type: 'setErrors', payload: { ...errorState } })
  
    return hasProjectLead && hasVersion
  }

  function resetErrorState(isNext) {
    let new_current = isNext ? (current + 1) : (current - 1)

    if (new_current === 1) {
      // Execute only if tab is on "New" (Project Step) for both project and client 
      if (['project', 'client'].some(property => state.modalField[property].type === 'New')) {
        // Adjusting state that is from projectformmodal.js (Reusing Components)
        dispatch({ type: 'SET_STATE', payload: { errors: initialErrorState, role: { id: 3, name: context.translation.project_leader[context.locale] } } })
      }
    } else {
      // Reset state
      dispatch({ type: 'SET_STATE', payload: { errors: initialErrorState, role: '' } })
    }

    // Move step
    setCurrent(new_current)
  }

  async function handleSubmit() {
    if (current === 2) {
      if (!state.isGenerate && state.form.password === state.form.password_confirm) {
        const selectedProject = state.points.find(project => project.id == state.proj_id)
        
        dispatch({ type: 'SET_STATE', payload: { buttonLoading: true } })

        client.mutate({ 
          mutation: UPSERT_REQUEST, 
          variables: { 
            data: { 
              client: state.modalField.client,
              project: {
                id: state.modalField.project.id,
                type: state.modalField.project.type,
                proj_nr: state.proj_nr,
                proj_name: state.proj_nr,
                team: state.team,
                versions: state.versions
              },
              user: state.form,
              proj_id: state.proj_id,
              client_id: state.client_id,
              feedback: selectedProject.points[0].feedback_id, 
              points: selectedProject.points
            } 
          } 
        }).then(({ data }) => {
          dispatch({ 
            type: 'SET_STATE', 
            payload: { 
              points: state.points.filter(project => project.id != state.proj_id), 
              openRequests: parseInt(state.openRequests) - 1,  
              unassignedPoints: !!state.team.find(e => e.user_id == user.id) ? (parseInt(state.unassignedPoints) + parseInt(selectedProject.points.length)) : state.unassignedPoints, // Add counter on unassigned points if curret user logged in is assigned
              modalRequest: false,
              buttonLoading: false 
            } 
          })
          message.success(context.translation.update_successful[context.locale])
  
          return true
        }).catch(err => {
          err.message = err.message.replace('GraphQL error: ', '')
          const { current, ...error } = JSON.parse(err.message)
          const property = Object.keys(error)[0]
    
          if (property === 'client') {
            translateError(error, property, ['clientnr', 'clientname'])
          } else if (property === 'project') {
            translateError(error, property, ['proj_nr', 'proj_name'])
          } else {
            translateError(error, property, ['email'])
          }
    
          let errors = {
            ...state.errors,
            [property]: {
              ...state.errors[property],
              ...error[property]
            }
          }
    
          setCurrent(current)
          dispatch({ type: 'SET_STATE', payload: { buttonLoading: false, errors } })
          console.log(err.message)
        });
      } else {
        dispatch({ type: 'SET_STATE', payload: { buttonLoading: false, errors: { ...state.errors, user: { ...state.errors.user, password: { invalid: true, valid: false, message: context.translation.password_no_match[context.locale] } }} } })
      }
    }

    return false
  }

  function translateError(error, property, list) {
    list.forEach((e) => {
      if (error[property][e])
        if (error[property][e].invalid)
          error[property][e].message = context.translation.errors[property][e][context.locale];
    });
  }

  useEffect(() => {
    if (state.modalRequest) 
      setCurrent(0)
  }, [state.modalRequest])

  return (
    <Modal 
      saveLabel={(state.modalType === 'delete' && context.translation.proceed[context.locale])}
      title={context.translation.accept_feedback[context.locale]} 
      modal={state.modalRequest} 
      width={'650px'}
      setModal={(e) => {dispatch({ type: 'SET_STATE', payload: { modalRequest: e } }) }} 
      uploading={state.uploading}
      hideActions={true}
      accessFunction={handleSubmit} 
    >
       <div>
        <Steps current={current}>
          {steps.map(item => (
            <Step key={item.title} title={context.translation[item.title][context.locale]} />
          ))}
        </Steps>

        <div className="steps-content">
          { 
            current == 0 ? <Client step={current} context={context} state={state} dispatch={dispatch} />
            : current == 1 ? <Project step={current} context={context} state={state} dispatch={dispatch} initialErrorState={initialErrorState} />
            : <User step={current} context={context} state={state} dispatch={dispatch} />
          }
        </div>

        <div className="steps-action">
          {current > 0 && (
            <Button style={{ marginRight: 8 }} onClick={() => resetErrorState(false)}>
              { context.translation.nav.previous[context.locale] }
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button type="primary" onClick={handleNext}>
              { context.translation.next[context.locale] }
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" htmlType="submit" loading={state.buttonLoading}>
              { context.translation.done[context.locale] }
            </Button>
          )}
        </div>
      </div>
    </Modal>
  )
}
