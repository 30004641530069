import React, { useEffect } from 'react';
import { Alert } from 'antd'
import { Input, Col } from 'reactstrap';
import CheckboxTree from 'react-checkbox-tree';
import Scrollbar from 'react-smooth-scrollbar';

import { Card, Empty } from '../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import TimelogFilter from './TimelogFilter'
import { setTimeout } from 'core-js';

let timeout_id = -1;
let loading = false

export default ({ reducer, context, dateReceived, loadPoints, updateNode, filterNode }) => {
  const {state, dispatch} = reducer

  useEffect(() => {
    dispatch({ type: 'SET_STATE', 
      payload: { 
        filteredNodes: state.nodes.map(feedback => {
          return filterNode(state.filter.search, feedback)
        }).filter(feedback => feedback !== null && feedback.children.length !== 0)
      }
    })
  }, [state.filter.search])

  function searchOnChange(event) {
    loading = true
    event.persist()
    clearTimeout(timeout_id)

    timeout_id = setTimeout(() => {
      updateNode(event.target.value, state.nodes, false)
      loading = false
    }, 500) 
  }

  return ( 
    <Card style={{ overflow: 'hidden'}}>
      <div style={{ height: '50px' }}></div>
      <div id="hidden-block">
        <Col>
          <Input id="search-filter" placeholder={context.translation.search[context.locale]} onChange={(event) => searchOnChange(event)} className="mb-1" autoComplete="on" />
        </Col>
      </div>

      <TimelogFilter reducer={{ state, dispatch }} context={context} updateNode={updateNode} loadPoints={loadPoints} />
      
      <Alert message={context.translation.info_timelog[context.locale]} type="info" closable showIcon style={{ marginBottom: '5px' }} />
      { 
        state.filteredNodes.length === 0 && state.filter.active_points && loading && state.loading? 
          <Alert message={context.translation.no_active_points[context.locale]} type="warning" showIcon style={{ marginBottom: '10px' }} />
        : null
      }
      <span style={{ fontWeight: 600, display: 'inline-block', marginBottom: '5px' }}>{context.translation.feedback_list[context.locale]}</span>
      <Scrollbar id="timelog-feedbacklist-scroll" alwaysShowTracks={false} renderByPixels={true} continuousScrolling={true} style={{ minHeight: '63px', height: 'max-content', maxHeight: '600px', width: '100%' }}>
        { state.filteredNodes.length !== 0? 
            <CheckboxTree
              nodes={loading || state.loading? state.loadingNodes: state.filteredNodes}
              checked={state.checked}
              expanded={loading? [1,2,3,4]: state.expanded}
              showExpandAll={true}
              onCheck={checked => {
                dispatch({
                type: 'SET_STATE', 
                payload: { 
                  timelogs : [
                    ...state.timelogs, 
                    ...state.filteredNodes.map(feedback => feedback.children.map(version => version.children.find(point => point.value == checked))).map(versions => versions.filter(point => !!point)[0]).filter(versions => !!versions).map(log => ({...log, date_received: dateReceived.date_string, stat: 'unsaved'}))
                  ] 
                } 
              })
              }}
              onExpand={expanded => dispatch({ type: 'SET_STATE', payload: { expanded } })}
              icons={{
                parentClose: '',
                parentOpen: '',
                check: <FontAwesomeIcon icon={faPlus}/>,
                uncheck: <FontAwesomeIcon icon={faPlus}/>,
                expandAll: <span className="rct-icon rct-icon-expand-all" />,
                collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
              }}
            />
          : 
            <Empty style={{marginTop: '20px'}} /> 
        }
      </Scrollbar>
    </Card>
  );
}