import React, { useEffect, useContext, useReducer } from 'react';
import { Prompt } from 'react-router'
import { Row, Col } from 'reactstrap';
import { Modal } from '../../common';
import { message, Tooltip } from 'antd';
import FeedbackList from './TimelogFeedbackList'
import TimelogList from './TimelogList'
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';

import TimelogReducer from './TimelogReducer'
import { POINTS_TIME_LOG, PROJECT_CLIENT_QUERY, UPSERT_TIMELOGS, TIMELOGS_QUERY } from '../../../queries';

/* Import Context Provider */
import { AuthContext } from '../../../contexts/AuthContext';
import { ApolloContext } from 'react-apollo';

const loadingNodes = [1,2,3,4].map(e => ({
  value: e,
  label: ' ',
  text: ' ',
  showCheckbox: false,
  icon: (<Skeleton width={100} height={20} />),
  children: [
      { text: '', value: '', label: '', icon: (<Skeleton width={100} height={20} />), showCheckbox: false, children: [
          { text: '', value: '', label: '', icon: (<Skeleton width={100} height={20} />), showCheckbox: false },
          { text: '', value: '', label: '', icon: (<Skeleton width={100} height={20} />), showCheckbox: false },
      ] },
      { text: '', value: '', label: '', icon: (<Skeleton width={100} height={20} />), showCheckbox: false, children: [
          { text: '', value: '', label: '', icon: (<Skeleton width={100} height={20} />), showCheckbox: false },
          { text: '', value: '', label: '', icon: (<Skeleton width={100} height={20} />), showCheckbox: false },
      ] },
  ],
}))

const badgeColor = [
  { status: 'Open', backgroundColor: '#FAE6E8', color: '#CF0F22', fontWeight: '400', borderRadius: '2px' },
  { status: 'Question', backgroundColor: '#E8F5F9', color: '#219FC7', fontWeight: '400', borderRadius: '2px' },
  { status: 'For Testing', backgroundColor: '#EEEAF4', color: '#5B3694', fontWeight: '400', borderRadius: '2px' },
  { status: 'Postpone', backgroundColor: '#FDEBF3', color: '#E43988', fontWeight: '400', borderRadius: '2px' },
  { status: 'Closed', backgroundColor: '#EDF7EE', color: '#53AD57', fontWeight: '400', borderRadius: '2px' },
]

const initialState = {
  loading: false,
  loadingLogs: false,
  modal: false,
  checked: [],
  expanded: [],
  nodes: loadingNodes,
  filteredNodes: loadingNodes,
  loadingNodes: loadingNodes,
  filter: {
    priority: ["High","Mid","Low"],
    active_points: true,
    status: [],
    project: { value: 'all', label: 'All' },
    client: { value: 'all', label: 'All' },
    search: ''
  },
  expandFilter: localStorage.getItem('filterExpand') === 'true' || false,
  filterStyle: {},
  options: {
    projects: [{ label: 'All', value: 'all' }],
    clients: [{ label: 'All', value: 'all' }],
    defaultProjects: []
  },
  timelogs: [],
  prev_timelogs: [],
}

export default ({ dateState: { dateValue, setDateValue, setDateReceived, dateReceived }, context, time, loadPlottedDates }) => {
  const { user } = useContext(AuthContext)
  const { client } = useContext(ApolloContext)
  const [state, dispatch] = useReducer(TimelogReducer, initialState)
  const unsavedLogs = JSON.stringify(state.prev_timelogs.map(log => ({...log, start_time: time(log.start_time), end_time: time(log.end_time) }))) !== JSON.stringify(state.timelogs.map(log => ({...log, start_time: time(log.start_time), end_time: time(log.end_time)})))
  
  useEffect(() => {
    client.query({ query: PROJECT_CLIENT_QUERY('true') }).then(({data}) => {
      dispatch({ 
        type: 'SET_STATE', 
        payload: { 
          options: { 
            projects: state.options.projects.concat(data.projects.map(e => ({ label: e.proj_name, value: e.id, client_id: e.client_id }))),
            defaultProjects: state.options.projects.concat(data.projects.map(e => ({ label: e.proj_name, value: e.id, client_id: e.client_id }))),
            clients: state.options.clients.concat(data.clients.map(e => ({ label: e.clientname, value: e.id })))
          }
        }
      })
    })

    // LOAD ESSENTIAL DATA
    loadPoints({id: 'all', type: 'all', time_log: true, priority: state.filter.priority.join('|'), isActivePoint: state.filter.active_points}, 'Open|Question|For Testing|Draft|Postpone|Closed') 
    loadLogs(moment(), moment().format('YYYY-MM-DD'))

    // Displaying of save button
    context.dispatch({ type: 'SET_STATE', 
      payload: { 
        tooltipTittle: context.translation.save_logs[context.locale], 
        fabAction3: () => {
          dispatch({ type: 'SET_STATE', payload: { modal: !state.modal }})
        }
      } 
    }) // Show FAB      

    return () => { // Trigger if component will unmount
      context.dispatch({ type: 'SET_STATE', payload: { showFab3: false } }) // Hide FAB      
    }
  }, [])

  useEffect(() => {
    if (dateValue.value !== null) {
      if (unsavedLogs) {
        if (window.confirm(context.translation.error_unsaved_changes[context.locale])) {
          loadLogs(dateValue.value, dateValue.dateString)
        }
      } else {
        loadLogs(dateValue.value, dateValue.dateString)
      }
    }
  }, [dateValue])

  useEffect(() => {
    if (unsavedLogs) {
      context.dispatch({ type: 'SET_STATE', payload: { showFab3: true } }) // Show FAB
    } else {
      context.dispatch({ type: 'SET_STATE', payload: { showFab3: false } }) // Show FAB
    }
  }, [state.timelogs])

  useEffect(() => {
    dispatch({ type: 'SET_STATE', 
      payload: { 
        filter: {
          ...state.filter, 
          project: { 
            value: 'all', 
            label: 'All' 
          } 
        },  
        options: {
          ...state.options,
          projects: state.options.defaultProjects.filter(e => e.value === 'all' || [parseInt('all'), parseInt(e.client_id)].includes(parseInt(state.filter.client.value)))
        }
      }
    })
  }, [state.filter.client])

  useEffect(() => {
    if (state.nodes.length !== 0)
      dispatch({ type: 'SET_STATE', payload: { expanded: state.nodes.map(e => e.value).concat(...state.nodes.map(e => e.children.map(s => s.value))).concat(...state.nodes.map(e => [].concat(...e.children.map(s => s.children.map(z => z.value))))) } })
  }, [state.filteredNodes])

  function loadLogs(date_moment, date_received) {
    setDateReceived({date_moment, date_string: date_received})
    dispatch({ type: 'SET_STATE', payload: { loadingLogs: true } })
    client.query({ query: TIMELOGS_QUERY(date_received) }).then(({data}) => {
      const timelogs = data.timelogs.map(log => ({
        ...log,
        proj_name: truncate(log.proj_name, 20),
        client_name: truncate(log.client_name, 20),
        start_time: moment(log.start_time, 'HH:mm:ss'),
        end_time: moment(log.end_time, 'HH:mm:ss'),
        label: (<><span className={`badge badge-sm ${log.status === 'Draft' && "bg-light"}`} style={{ ...badgeColor.find(e => e.status === log.status) }}>{log.point_nr}</span> {truncate(log.instructions, 50)}</>),
        icon: (<span style={{ width: 'max-content', color: log.priority === 'Low'? '#FFC400': log.priority === 'Mid'? '#FF8B00': '#FF5630' }}>•</span>),
        stat: 'saved'
      }))

      dispatch({ type: 'SET_STATE', payload: { timelogs, prev_timelogs: timelogs, loadingLogs: false } })
    })
  }

  function loadPoints(filter, status) {
    dispatch({ type: 'SET_STATE', payload: { loading: !state.loading } })
    client.query({ query: POINTS_TIME_LOG(filter, status, 0, '') }).then(({data}) => {
      const nodes = data.feedbacks.filter(e => e.versions.length !== 0).sort(function (a, b) {
        if (a.proj_name == 'PH-Internal') {
            return -1;
        }
        if (a.proj_name== 'PH-Internal') {
            return 1;
        }
        return 0;
    }).map(feedback => ({
          value: 'F-' + feedback.id, 
          label: feedback.title, 
          text: feedback.title,
          showCheckbox: false,
          children: feedback.versions.map(version => ({
            value: 'V-' + version.id,
            label: <b>{feedback.proj_name + ' (' + feedback.clientname + ')'}</b>,
            text: version.version_name,
            icon: '',
            showCheckbox: false,
            children: version.points.map(point => ({
              value: 'P-' + point.id,
              point_id: point.id,
              point_nr: point.point_nr,
              user_id: user.id,
              text: point.point_nr + ' ' + truncate(point.instructions, 100),
              status: point.status,
              priority: point.priority,
              proj_name: truncate(feedback.proj_name, 20),
              client_name: truncate(feedback.clientname, 20),
              start_time: '',
              end_time: '',
              label: (<><Tooltip placement="topLeft" title={point.instructions}><span className={`badge badge-sm ${point.status === 'Draft' && "bg-light"}`} style={{ ...badgeColor.find(e => e.status === point.status) }}>{point.point_nr}</span> {truncate(point.instructions, 50)}</Tooltip></>),
              // icon: (<FontAwesomeIcon icon={faDotCircle} />)
              icon: (<span style={{ width: 'max-content', color: point.priority === 'Low'? '#FFC400': point.priority === 'Mid'? '#FF8B00': '#FF5630' }}>•</span>)
            }))
          })).filter(e => e.children.length !== 0)
      })).filter(e => e.children.length !== 0)
      // console.log(nodes)
      const filterComponent = document.getElementById('search-filter')
      if (filterComponent)
        updateNode(filterComponent.value, nodes, true)
    })
  }

  function filterNode(textSearch, node) {
    let result = null
  
    if (['', node.label.toLowerCase()].some(e => e.search(textSearch.toLowerCase()) >= 0)) {
      result = node
    }
    else {
      result = {
        ...node, 
        children: node.children.map(version => ({...version, children: version.children.filter(point => point.text.toLowerCase().search(textSearch.toLowerCase()) >= 0)})).filter(version => version.children.length !== 0)
      }
    }
    return result
  }

  function updateNode(textSearch, nodes, isLoadPoint) {
    const payload = isLoadPoint? { nodes }: {}

    dispatch({ type: 'SET_STATE', 
      payload: { 
        filteredNodes: nodes.map(feedback => filterNode(textSearch, ({
          ...feedback, 
          children: feedback.children.map(version => ({...version, children: version.children.filter(point => {
            return (
              (state.filter.status? state.filter.status.length === 0 || state.filter.status.map(status => status.label).includes(point.status): true) && state.filter.priority.includes(point.priority)
            )
          })})).filter(point => point.children.length !== 0)
        }))).filter(feedback => feedback !== null && feedback.children.length !== 0),
        loading: false,
        ...payload
        // nodes.map(feedback => {
        //   return filterNode(event.target.value, feedback)
        // }).filter(feedback => feedback !== null && feedback.children.length !== 0)
      }
    })
  }

  function truncate(text, len) {
    return text.length > len? text.substring(0, len) + '...' :  text
  }

  function handleAccessFunction() {
    if (state.timelogs.find(log => !!!log.start_time || !!!log.end_time)) {
      message.warning(context.translation.warning_timelog[context.locale]);
    } else {
      const hide = message.loading(context.translation.saving_changes[context.locale], 0);

      context.dispatch({ type: 'SET_STATE', payload: { disableButton: true } }) // Show FAB      

      client.mutate({ mutation: UPSERT_TIMELOGS, variables: { data: { timelogs: state.timelogs.map(log => ({...log, start_time: time(log.start_time), end_time: time(log.end_time)})), date: dateReceived.date_string, isFromTimelog: true } } }).then(({data}) => {
        const timelogs = state.timelogs.map(log => ({...log, stat: 'saved'}))
        dispatch({ type: 'SET_STATE', payload: { timelogs, prev_timelogs: timelogs } })
        loadPlottedDates(dateReceived.date_moment, false)

        context.dispatch({ type: 'SET_STATE', payload: { disableButton: false } }) // Show FAB      
        
        hide()
        message.success(context.translation.success_logs[context.locale])
      }).catch(error => {
        context.dispatch({ type: 'SET_STATE', payload: { disableButton: false } }) // Show FAB      
        hide()
        message.error(error.toString())  
      })
    }

    dispatch({ type: 'SET_STATE', payload: { modal: !state.modal } })
  }

  return (
    <>
      <Modal 
        title={context.translation.save_changes[context.locale]} 
        width='500px'
        modal={state.modal} 
        setModal={(e) => {dispatch({ type: 'SET_STATE', payload: { modal: e } }) }} 
        accessFunction={() => handleAccessFunction()} 
        autoFocus={true}
      >
        {context.translation.msg_save_changes[context.locale]} 
      </Modal>

      {/* <Container> */}
        <Row className="mb-3 mt-2">
          <Col lg={3} md={4} sm={12} xs={12}>
            <FeedbackList reducer={{state, dispatch}} context={context} dateReceived={dateReceived} loadPoints={loadPoints} filterNode={filterNode} updateNode={updateNode} />
          </Col>

          <Col lg={9} md={8} sm={12} xs={12}>
            <h4 style={{ display: 'inline-block', marginRight: '5px' }}>{ moment(dateReceived.date_moment).format('LL') }</h4> 
            { 
              moment(dateReceived.date_moment).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') && 
                <span>({ context.translation.today[context.locale] })</span>
            }
            <TimelogList reducer={{state, dispatch}} context={context} />
          </Col>
        </Row>
        
      {/* </Container> */}

      {/* Prevent user from switching pages when there is still unsaved logs */}
      <Prompt
        when={unsavedLogs}
        message={context.translation.error_unsaved_changes[context.locale]}
      />
    </>
  );
}
 
