import React,{ useState, useEffect, useContext } from 'react';
import { useSpring, animated } from 'react-spring';
import { Container, Row, Col, Alert } from 'reactstrap';
import { Card, Toast, SpringStyle, Spinner2, Page } from '../../common';
import { Popconfirm, message } from 'antd'
import Tooltip from '@material-ui/core/Tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import UserModals from './UserModal';
import Table from './UserTable';

import { ApolloContext } from 'react-apollo';
import { USERS_QUERY, GROUP_QUERY, SEND_EMAIL } from '../../../queries';
import { GlobalContext } from '../../../contexts/GlobalContext'
import { AuthContext } from '../../../contexts/AuthContext'

export default (props => {
  const initialFormState = {
    id: -1,
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirm: '',
    user_type_id: 6,
    client_id: '',
  }
  const initialErrorState = {
    email: {
      invalid: false,
      message: ''
    },
    password: {
      invalid: false,
      valid: false,
      message: ''
    }
  }
  const initialStatusState = {
    total_status: 0,
    active_status: 0,
    inactive_status: 0
  }
  const [selected, setSelected] = useState([]);
  const [type, setType] = useState('add');
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [isGenerate, setIsGenerate] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState();
  const [visible, setVisible] = useState(true);
  const [selectedUser, setSelectedUser] = useState('');  
  const [statusCount, setStatusCount] = useState(initialStatusState);
  const [CreateUserModOpen, toggleCreateModal] = useState(false)
  const [errors, setErrors] = useState(initialErrorState);
  const [form, setForm] = useState(initialFormState);  
  const { client } = useContext(ApolloContext);
  const { user } = useContext(AuthContext);
  const { dispatch, translation, locale } = useContext(GlobalContext);
  const [items, setItems] = useState([])
  const [tempItems, setTempItems] = useState([])

  useEffect(() => {
    dispatch({ type: 'SET_STATE', payload: { loc: 'essentials', showFab: false, tooltipTittle: translation.add_user[locale], fabAction: () => {setModal(!modal); setModalType('create')} } }) // Hide FAB      

    client.query({ query: USERS_QUERY }).then(({ data }) => {
      if (data.users) {
        data.users = data.users.map(e => {
          const action = (
            <>
              <Tooltip title={translation.update_account[locale]}>
                <button onClick={() => {setErrors(initialErrorState); setModal(!modal); setModalType('create'); setType('edit'); setForm({id: e.id, first_name: e.first_name, last_name: e.last_name, user_type_id: e.user_type_id, email: e.email, old_email: e.email, group_id: (e.group? e.group.id : 0), client_id: e.client_id || '' })}} className="btn btn-sm btn-warning">
                  <i className="fas fa-edit" style={{ color: '#fff' }}></i>
                </button>
              </Tooltip>

              {/* <Tooltip title={translation.change_password[locale]}>
                <button onClick={() => {setErrors(initialErrorState); setModal(!modal); setModalType('changePassword'); setSelectedUser({id: e.id, name: e.first_name+ ' ' + e.last_name, email: e.email})}} className="btn btn-sm btn-warning">
                  <i className="fas fa-user-lock" style={{ color: '#fff'  }}></i>
                </button>
              </Tooltip> */}

              <Popconfirm
                placement="topRight"
                title={<div style={{ width: '25vh' }}>{translation.send_email_confirmation[locale]}</div>}
                onConfirm={() => handleConfirm(e)}
                okText="Yes"
                cancelText="No"
              >
                <Tooltip title={translation.send_account_details[locale]}>
                  <button className="btn btn-sm btn-primary">
                    <i className="fas fa-envelope" style={{ color: '#fff'  }}></i>
                  </button>
                </Tooltip>
              </Popconfirm>
            </>
          )

          return { ...e, action }
        })
      }

      setClients(data.clients)
      setUsers(data.users)
      setStatusCount(data.userStatusCount);
      setForm({...form, client_id: data.clients.length !== 0 ? data.clients[0].id : ''})
    })

    client.query({ query: GROUP_QUERY }).then(({data: {groups}}) => {
      setDuo(groups)
    })

    return () => { // Trigger if component will unmount
      dispatch({ type: 'SET_STATE', payload: { loc: '', showFab: false } }) // Hide FAB      
    }
  }, []);


  useEffect(() => {
    if (!modal) {
      setIsGenerate(false)
      setType('add')
      setForm(initialFormState)
    }
  },[modal])

  function setDuo (projects) { 
    setItems(projects)
    setTempItems(projects)
  }

  async function handleConfirm(user) {
    const hide = message.loading(translation.saving_changes[locale], 0)

    try {
      const variables = {
        data: {
          id: user.id,
          email: user.email,
          first_name: user.first_name, 
          last_name: user.last_name,
        }
      }
  
      const res = await client.mutate({ mutation: SEND_EMAIL, variables })

      hide()
      message.success(translation.email_sent[locale])
    } catch (error) {
      console.log(error)
      hide()
      message.error(error)
    }
  }

  return (
    <Page  title={translation.user_management[locale]} components={
      <span style={{ position: 'absolute', right: '1.3em' }}>
        <span className="mr-2"><span style={{ fontSize: '.875rem', fontWeight: 'normal' }} className="badge badge-info"> Total {translation.users[locale]}: { statusCount.total_status }</span></span>
        {/* <span className="mr-2"><span style={{ fontSize: '.875rem', fontWeight: 'normal' }} className="badge badge-primary">Active: { statusCount.active_status }</span></span>
        <span className="mr-2"><span style={{ fontSize: '.875rem', fontWeight: 'normal' }} className="badge badge-secondary">Inactive: { statusCount.inactive_status }</span></span> */}
    </span>
    }>
      <Row className="d-flex align-items-center mb-3 mt-2">
        {/* <Col xs={12} className="d-flex align-items-center" style={{ position: 'relative' }}>
          <span className="mr-2"><span style={{ fontSize: '.875rem', fontWeight: 'normal' }} className="badge badge-info"> Total Users: { statusCount.total_status }</span></span>
          <span className="mr-2"><span style={{ fontSize: '.875rem', fontWeight: 'normal' }} className="badge badge-primary">Active: { statusCount.active_status }</span></span>
          <span className="mr-2"><span style={{ fontSize: '.875rem', fontWeight: 'normal' }} className="badge badge-secondary">Inactive: { statusCount.inactive_status }</span></span>

          <button onClick={() => {setModal(true); setModalType('create') }} className="btn btn-sm btn-outline-exact" style={{ position: 'absolute', right: '1.15em' }}>
            <i className="fas fa-user-plus"></i> Add User
          </button>
        </Col> */}

        <Col xs="5" className="d-flex align-items-center">
          <UserModals payload={{
            modalType,
            modal,
            errors,
            users,
            selectedUser,
            setSelectedUser,
            setModalType,
            selectedUser,
            selected,
            setSelected,
            initialErrorState,
            setUsers,
            setType,
            clients,
            setErrors,
            setModal,
            setIsGenerate,
            isGenerate,
            statusCount, 
            setStatusCount,
            type,
            setForm,
            form,
            items,
            handleConfirm,
            toggle: toggleCreateModal
          }} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            {/* <h5>User List</h5><br /> */}
            <Table payload={{
              users, 
              setUsers, 
              visible, 
              setVisible,
              setModal,
              modal,
              setModalType,
              selected,
              setSelected,
              clients,
              setSelectedUser, 
              setModalType,
              items,
              setItems,
              tempItems,
              setTempItems
            }} />              
          </Card>
        </Col>
      </Row>
    </Page>
  )
})