import React, { useState, useEffect, useContext } from 'react';
import { Alert } from 'reactstrap';
import { Toast, Spinner2, Empty } from '../../common';
import { Button, Popover, Icon, Popconfirm, Form, message, Input, Drawer, Select } from 'antd';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { ApolloContext } from 'react-apollo';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { UPDATE_USER, UPSERT_GROUP, DELETE_GROUP, UPDATE_GROUP_USER } from '../../../queries';

const DragHandle = sortableHandle(() => <span className="sort-draggable"><Icon type="menu" /></span>);

const SortableItem = sortableElement(({value, translation, locale, handleDeleteGroup, setDrawer, drawer, setSelectedGroup, setUserTypeId}) => 
    (
      <li className="SortableItem" style={{ backgroundColor: value.id === 0? '#FFFCF4': '#fff'}}>
        {
          <span style={{ display: 'block' }}>{value.name}</span>
        }
        {
          value.id !== 0 && 
            <Icon type="eye" className="view-group" onClick={() => {setDrawer(true); setUserTypeId(value.user_type_id); setSelectedGroup({id: value.id, user_type_id: value.user_type_id, name: value.name})}}/>
        }
        <Popconfirm
          title={translation.delete_group[locale]}
          onConfirm={handleDeleteGroup(value.id, value.name)}
          // onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <Icon type="delete" className="delete-group" />
        </Popconfirm>
        <DragHandle /> 
      </li>
    )
)

const SortableItem1 = sortableElement(({value}) => 
    (
      <li className="SortableItem">
        {
          <span style={{ display: 'block' }}>{value.name}</span>
        }
        <DragHandle /> 
      </li>
    )
)

const SortableContainer = sortableContainer(({children}) => {
  return <ul className="SortableList">{children}</ul>;
});

let initial = false
export default Form.create()(({payload, form: { getFieldDecorator, validateFields, setFields, setFieldsValue } }) => {
  const { clients, users, setUsers, visible, setVisible, setModal, modal, setModalType, selected, setSelected, items, setItems, tempItems, setTempItems } = payload
  const { SearchBar } = Search;
  const { client } = useContext(ApolloContext);
  const { translation, locale, dispatch } = useContext(GlobalContext)
  const [isOrderChanged, setIsOrderChanged] = useState([])
  const [isOrderChanged1, setIsOrderChanged1] = useState([])
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const [user, setUser] = useState([])
  const [tempUser, setTempUser] = useState([])
  const [show, setShow] = useState(false)
  const [drawer, setDrawer] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState({})
  const [userTypeId, setUserTypeId] = useState(6)

  /* TABLE SETTINGS */
  const columns = [{
    dataField: 'id',
    text: 'id',
    hidden: true,
  }, {
    dataField: 'first_name',
    text: translation.first_name[locale],
    sort: true
  }, {
    dataField: 'last_name',
    text: translation.last_name[locale],
    sort: true
  }, {
    dataField: 'email',
    text: 'Email',
    sort: true,
    validator: (newValue, row, column, done) => {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (newValue) {
        if (!re.test(String(newValue).toLowerCase())) {
          return { valid: false, message: translation.error_email[locale]};
        } else {
          return client.mutate({ mutation: UPDATE_USER, variables: { data: { [column.dataField]: newValue }, id: parseInt(row.id) } }).then(({ data }) => {
              updateUser(data)
              return done();
            }).catch(error => {
              const err = error.toString().split(':')
              console.log(err);
              return done({ valid: false, message: err[3] });
            })
        }
      } else {
        return { valid: false, message: translation.error_insert_email[locale] };
      }
      return {
        async: true
      }
    }
  }, {
    dataField: 'user_type_id',
    text: 'User Type',
    headerStyle: (colum, colIndex) => ({ width: '25%' }),
    formatter: (cell, row, rowIndex, formatExtraData) => cell == 2?  translation.administrator[locale]: cell == 6? translation.members[locale]: <>{translation.clien[locale]} <small>({!!clients.find(e => e.id == row.client_id) ? clients.find(e => e.id == row.client_id).clientname : 'Nein'})</small></>,
    editor: {
      type: Type.SELECT,
      options: [{
        value: 2,
        label: translation.administrator[locale]
      }, {
        value: 6,
        label: translation.members[locale]
      }, {
        value: 7,
        label: translation.clien[locale]
      }]
    },
    sort: true
  }, {
    dataField: 'group',
    text: translation.groups[locale],
    formatter: (cell, row, rowIndex, formatExtraData) => cell != null ? cell.name: '',
    editor: {
      type: Type.SELECT,
      options: items.map(e => ({ value: parseInt(e.id), label: e.name }))
    },
    sort: true
  }, {
    dataField: 'action',
    text: translation.action[locale],
    editable: false,
    align: 'center',
    headerAlign: 'center',
    headerStyle: (colum, colIndex) => ({ width: '6.5%' }),
    style: {
      display: 'flex',
      justifyContent: 'space-around'
    },
  }, {
    dataField: 'user_type_id',
    text: 'User Type ID',
    hidden: true
  }];

  const updateUser = (data) => {
    setUsers(users.map(e => {
      if (e.id == data.updateUser.id) 
        return {...e, ...data.updateUser};
      return e
    }));
    message.success(translation.msg_user_updated[locale])
    // Toast.showToast('success', translation.msg_user_updated[locale]);
  }

  const beforeSaveCell = async (oldValue, newValue, row, column, done) => {
    const hide = message.loading(translation.saving_changes[locale])
    if (oldValue !== newValue) {
      try {
        if (column.dataField !== 'email') {
          if (!!newValue) {
            const datafield = column.dataField === 'group'? 'group_id': column.dataField
            const { data } = await client.mutate({ mutation: UPDATE_USER, variables: { data: { [datafield]: parseInt(newValue) }, id: parseInt(row.id) } })
            hide()
            updateUser(data)
          }
        }
  
      } catch (error) {
        console.log(error);
        hide()
        message.error(error)
        // Toast.showToast('error', error);
      }
    }
  }
  /* TABLE SETTINGS END */

  function handleCancel () {
    setItems(tempItems)    
    setShow(false)
  }

  function setDuo (projects) { 
    setItems(projects)
    setTempItems(projects)
  }

  function setDuo1 (projects) { 
    setUser(projects)
    setTempUser(projects)
  }

  const handleVisibleChange = () => {
    console.log(!show)
    dispatch({ type: 'SET_STATE', payload: { modal1: !show } })
    setShow(!show)
  };

  const onSortEnd = ({oldIndex, newIndex}) => {
    if (items.length !== 0)
      setItems(arrayMove(items, oldIndex, newIndex))
  };

  const onSortEnd1 = ({oldIndex, newIndex}) => {
    if (user.length !== 0)
      setUser(arrayMove(user, oldIndex, newIndex))
  };

  function addGroup (e) {
    e.preventDefault();

    validateFields((err, fieldsValue) => {
      console.log(err)
      if (err) {
        return;
      }

      const add_group = fieldsValue['add_group'];
      if (items.find(e => e.name == add_group)) {
        setFields({
          ['add_group']: {
            errors: [new Error(translation.group_name_exist[locale])],
          },
        });

        return;
      }

      setItems([...items, { id: 0, name: add_group, sort: 99, users: [] }])
      setFieldsValue({ ['add_group']: '' });      
    })
  }

  const handleDeleteGroup = (id, name) => (e) => {
    if (id !== 0) {
      return client.mutate({ mutation: DELETE_GROUP, variables: { ids: [id] } }).then(({ data }) => {
        const temp_items = items.filter(e => e.name != name)
        const res = false
        setItems(temp_items)
        
        temp_items.forEach(e => {
          if (e.id === 0) {
            res = true
          }
        })

        if (!res) {
          setDuo(temp_items)
        }

        message.success(translation.success_delete_group[locale])
      }).catch(error => {
        message.error(translation.error_occurred[locale])
      })
    } else {
      setItems(items.filter(e => e.name != name))
    }

  }

  async function handleSaveUserOrder () {
    try {
      const userss = user.map((item, index) => {
        return {
          id: item.id,
          name: item.name,
          sort: index,
        }
      })
      setLoading1(true)

      const { data } = await client.mutate({ mutation: UPDATE_GROUP_USER, variables: { data: { groups: userss, group: { id: selectedGroup.id, user_type_id: userTypeId } } } })
      setLoading1(false)
      setDuo(items => items.map(e => e.id == selectedGroup.id ? 
        {
          ...e, 
          user_type_id: userTypeId
        } :
        e
      ))
      setDuo1(userss)
      const te_user = users.map(e => {
        const isFound = userss.find(uu => uu.id == e.id)
        return isFound? {...e, sort: isFound.sort}: {...e}
      })
      setUsers(te_user)
      message.success(translation.group_saved[locale])
    } catch (error) {
      setLoading1(false)
      message.error(translation.error_occurred[locale])
    }
  }

  async function handleGroupSave () {
    try {
      const groups = items.map((item, index) => {
        const id = item.id === 0? {} : {id : item.id}
        return {
          ...id,
          name: item.name, 
          sort: index,
        }
      })
  
      setLoading(true)
      const { data } = await client.mutate({ mutation: UPSERT_GROUP, variables: { data: { groups } } });
      setLoading(false)
      setDuo([])
      setDuo(data.upsertGroup.sort((a, b) => {
        const first = a.sort
        const next = b.sort
        return first - next
      }))
      message.success(translation.group_saved[locale])
    } catch (error) {
      setLoading(false)
      message.error(translation.error_occurred[locale])
    }
  }

  function handleUserType (e) {
    console.log(selectedGroup.user_type_id, ' | ', e)
    if (selectedGroup.user_type_id != e) 
      setIsOrderChanged1(false)
    else 
      setIsOrderChanged1(true)

    setUserTypeId(e)
  }

  useEffect(() => {
    if (items.length !== 0 && tempItems.length !== 0 && items.length === tempItems.length) {
      let result = false
      items.forEach((item, index) => {
        if (item.id != tempItems[index].id || item.id === 0)
          result = true
      })
  
      setIsOrderChanged(!result)
    } else {
      setIsOrderChanged(false)
    }
  }, [items, tempItems])

  useEffect(() => {
    if (user.length !== 0 && tempUser.length !== 0 && user.length === tempUser.length) {
      let result = false
      user.forEach((item, index) => {
        if (item.id != tempUser[index].id || item.id === 0)
          result = true
      })
  
      setIsOrderChanged1(!result)
    } else {
      setIsOrderChanged1(false)
    }
  }, [user, tempUser])

  useEffect(() => {
    if (selectedGroup != null) {
      if (selectedGroup.name !== '') {
        const userr = users.filter(e => e.group != null ? e.group.id == selectedGroup.id: false)
                        .map(e => ({ id: e.id, name: e.first_name + ' ' + e.last_name, sort: e.sort }))
                          .sort((a, b) => {
                            const first = a.sort
                            const next = b.sort
                            return first - next
                          })
        setDuo1(userr)          
      }
    }
  }, [selectedGroup])

  useEffect(() => {
    if (initial !== false) {
      setDuo(items.sort((a, b) => {
        const first = a.sort
        const next = b.sort
        return first - next
      }))
    }
  }, [items])

  return (
    <>
      <Drawer
        title={selectedGroup.name}
        width={445}
        closable={true}
        maskClosable={false}
        onClose={() => setDrawer(false)}
        visible={drawer}
      >
        <Select value={userTypeId} style={{ width: '100%', marginBottom: '12px' }} onChange={handleUserType}>
            <option value={2}>{translation.administrator[locale]}</option>
            <option value={6}>{translation.member[locale]}</option>
            <option value={7}>{translation.client[locale]}</option>
        </Select>

        <SortableContainer onSortEnd={onSortEnd1} helperClass="SortableHelper" useDragHandle>
          {
            user.length !== 0 ?  
              user.map((value, index) => (
                <SortableItem1 key={value.id} index={index} value={value} handleDeleteGroup={handleDeleteGroup} translation={translation} locale={locale} setDrawer={setDrawer} drawer={drawer} setSelectedGroup={setSelectedGroup}/>
              )) :
              <Empty style={{ margin: '20px auto' }} />
          }
        </SortableContainer>

        <div
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e8e8e8',
            padding: '10px 16px',
            textAlign: 'right',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px',
          }}
        >
          <Button
            style={{
              marginRight: 8,
            }}
            onClick={() => setDrawer(false)}
          >
            {translation.cancel[locale]}
          </Button>
          <Button disabled={isOrderChanged1} loading={loading1} onClick={handleSaveUserOrder} type="primary">
            {translation.save[locale]}
          </Button>
        </div>
      </Drawer>

      <ToolkitProvider
        keyField='id'
        bootstrap4
        columns = { columns }
        data={ users } 
        search
      >
        {
          props => (
            <>
              {/* <Alert color="info" isOpen={visible} toggle={() => setVisible(false)}>
                <i className = "fas fa-info-circle"></i> {translation.msg_double_click[locale]}
              </Alert> */}

              {/* <button onClick={() => {setModal(!modal); setModalType('delete')}} className="btn btn-sm btn-exactplace" >
                <i className="fas fa-trash-alt"></i> Delete
              </button> */}

              {/* <Tooltip title="Add">
                  <IconButton aria-label="Add" onClick={() => {setModal(true); setModalType('create') }}>
                    <AddIcon
                    />
                  </IconButton>
              </Tooltip> */}

              <Fab
                id="step-6-5"
                variant="extended"
                size="small"
                color="primary"
                aria-label="Add"
                onClick={() => { setModal(!modal); setModalType('create') }}  
              >
                <AddIcon />
                <span>{translation.add_user[locale]}</span>
              </Fab>
              {' '}
              <Popover 
                title={translation.groups[locale]}
                trigger="click"
                visible={show}
                onClick={handleVisibleChange}
                placement="bottom"
                content={(
                  <>
                    <Form onSubmit={addGroup}>
                      <Form.Item style={{ marginBottom: '5px' }}>
                        {getFieldDecorator('add_group', {
                          rules: [{ required: true, message: translation.required_group_name[locale] }],
                        })(
                          <Input placeholder={translation.group_name[locale]} />,
                        )}
                      </Form.Item>
                        <SortableContainer onSortEnd={onSortEnd} helperClass="SortableHelper" useDragHandle>
                        {
                          items.length !== 0 ?  
                            items.map((value, index) => (
                              <SortableItem key={value.id} index={index} value={value} handleDeleteGroup={handleDeleteGroup} translation={translation} locale={locale} setDrawer={setDrawer} drawer={drawer} setSelectedGroup={setSelectedGroup} setUserTypeId={setUserTypeId}/>
                            )) :
                            <Empty style={{ margin: '20px auto' }} />
                        }
                        {/* <Button id="btn-add-group" onClick={addGroup} icon="plus" type="dashed">{translation.add_group[locale]}</Button> */}
                      </SortableContainer>

                      <div style={{ textAlign: 'right' }}>
                        <Button type="link" onClick={handleCancel}>{translation.cancel[locale]}</Button>
                        <Button id="group-btn-save" type="primary" disabled={isOrderChanged} loading={loading} onClick={handleGroupSave}>{translation.save[locale]}</Button>
                      </div>
                    </Form>
                  </>
                )} 
              >
                <Button id="step-6" type="link">{translation.groups[locale]}</Button>
              </Popover>
              {' '}

              <Tooltip title="delete">
                  <IconButton className={`${(selected.length !== 0)? 'scale-in-center': 'scale-hide'}`} aria-label="Delete" onClick={() => {setModal(!modal); setModalType('delete')}}>
                    <DeleteIcon />
                  </IconButton>
              </Tooltip>

              <SearchBar placeholder={translation.search[locale]} { ...props.searchProps } />

              <BootstrapTable 
                { ...props.baseProps }
                selectRow={{
                  mode: 'checkbox',
                  selected,
                  onSelect: (row, isSelect, rowIndex, e) => {
                    if (isSelect) 
                      setSelected([ ...selected, row.id ])
                    else 
                      setSelected(selected.filter(x => x !== row.id))
                  },
                  onSelectAll: (isSelect, rows, e) => {
                    const ids = rows.map(r => r.id);
                    if (isSelect) 
                      setSelected(ids)
                    else
                      setSelected([])
                  }
                }}
                // cellEdit={ 
                //   cellEditFactory({
                //     mode: 'dbclick',
                //     blurToSave: true,
                //     beforeSaveCell
                //   }) 
                // }
                noDataIndication={ () => <Spinner2 /> }
                bordered={ false }
                hover 
                />
          </>
          )
        }
      </ToolkitProvider> 
    </>
  )
})
