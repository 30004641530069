import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';


const Toast = () => {
  return (
    <div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable
          pauseOnHover
          />
    </div>
  );
}

Toast.showToast = (type, message) => {
  const icon = {
    info: '🔎',
    success: '✔️',
    warning: '⚠️',
    error: '❌',
  }
  toast[type](`${icon[type]} ${message}`, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  });
}

export default Toast;