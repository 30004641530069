import React, { useEffect, useContext, useState } from 'react';
import DataSet from "@antv/data-set";
import moment from 'moment'
import { G2, Chart, Geom, Axis, Tooltip, Coord, Label, Legend, View } from "bizcharts";

let counter = {
  client: 0,
  internal: 0,
  name: '',
  name_temp: ''
}
export default ({ reducer: { state, dispatch, points }, context }) => {
  const [dv, setDv] = useState()
  const ds = new DataSet();
  useEffect(() => {
    if (state.assignedTasks.length !== 0) {
      let fields = []
      let dataView = ds.createView()
                        .source(state.assignedTasks
                          .map((user, index) => { 
                              let projects = {}
                              let order = {}
                              user.points.forEach(point => {
                                const total_time = point.total_time_estimate || '0'
                                const total_time_parsed = parseInt(total_time.replace(':', ''))
                                const proj_name = `${point.proj_name} (${point.clientname})`
                                const project = total_time_parsed !== 0 && !!total_time_parsed ? {[proj_name]: total_time_parsed}: {}
                                order[proj_name] = point.order

                                if (total_time_parsed !== 0 && !fields.find(e => e === proj_name))
                                  fields.push(proj_name)

                                projects = { ...projects, ...project }
                              })

                              return { name: user.name, order, index, ...projects, group: user.group, sort: user.sort }
                            }) //Sort name to ascending
                          )
                          // console.log( state.assignedTasks.map(user => user.points.map(point => point.proj_name)).flat())
      dataView.transform({
        type: 'fold',
        fields,
        key: "project",
        value: "time",
        retains: ["name", "order", "index", "sort", "group"],
      }).transform({
        type: 'sort',
        callback(a, b) { 
          let order1 = Object.entries(a.order)
          let order2 = Object.entries(b.order)
          let o1 = 0
          let o2 = 0
           order1.forEach((e,i) => {
          if (e[0] === a['project'])
              o1 = e[1]
          })
          order2.forEach((e,i) => {
            if (e[0] === b['project'])
                o2 = e[1]
          })

          return (a.group ? a.group.sort : 0) > (b.group ? b.group.sort : 0) ? -1 : (b.group ? b.group.sort : 0) > (a.group ? a.group.sort : 0)? 1 : 
                  a.sort > b.sort ? -1 : b.sort > a.sort? 1 : 
                    o1 > o2 ? 1: o2 > o1 ? -1: 0
        }
      })

      setDv(dataView)
    }
  }, [state.assignedTasks])

  const colors = {
    internal: '#3AA0FF',
    internal_next: '#6eb8fd',
    client: '#FAD337',
    client_next: '#f5dc78'
  }

  const getColors = (project, name) => {
    // if (counter.name !== name) {
    //   counter.client = 0
    //   counter.internal = 0
    //   counter.name = name
    // }

    // if (project.split('(')[1] !== 'exact construct Gmbh)'){ //commented because a new internal tasks structure was set up
    if (project.split('(')[1] !== '_1_Marketing)' && 
        project.split('(')[1] !== '_2_Accounting)' &&
        project.split('(')[1] !== '_3_HR and Management)' &&
        project.split('(')[1] !== '_4_Holidays and Absences)' &&
        project.split('(')[1] !== '_5_Training)' &&
        project.split('(')[1] !== '_6_Process Optimization)' &&
        project.split('(')[1] !== '_7_Exactflow)' &&
        project.split('(')[1] !== '_8_Exactfinance)') {
      // counter.client++
      // if (counter.internal > 0)
      //   counter.internal = 0

      // if (counter.client % 2 !== 0)
        return colors.client
      // else
        // return colors.client_next
    } else {
      // counter.internal++
      // if (counter.client > 0)
      //   counter.client = 0

      // if (counter.internal % 2 !== 0)
        return colors.internal
      // else
        // return colors.internal_next
    }
  }

  const scale = {
    time: {
      min: 0,
      max: 4000,
      minLimit: 0,
      maxLimit: 4000,
      tickCount: 6,
      tickInterval: 800
      // formatter: function(value) {
        
      // },
    }
  };

  function getMonday(currentDate) {
    if (currentDate.isoWeekday(6))  // If current date is Saturday, then get date on monday next week
      return currentDate.add('1', 'week').day(1)
    else  // else (current date is Sunday) get the date of Monday of current week
      return currentDate.day(1)
  }

  const insert = (str, value) => str.substr(0, str.length-2) + value + str.substr(str.length-2)

  return (
    <div>
      <Chart 
        height={800} 
        data={dv} 
        padding={[ 20, 'auto', 60, 'auto' ]} 
        scale={scale} 
        onTooltipChange={ev => {
          ev.items.forEach(item => {
            const user = state.assignedTasks.find(user => user.name == item.title)
            const total_hours_used = user.points.find(point => `${point.proj_name} (${point.clientname})` == item.name).total_hours_used_individual
            let total_hours_used_parsed = moment.duration(parseInt(total_hours_used), 'seconds').format("HH:mm")
            total_hours_used_parsed = total_hours_used_parsed != '00' ? 
                                        total_hours_used_parsed: 
                                        total_hours_used_parsed.length == 2 ? 
                                          '00:' + total_hours_used_parsed : total_hours_used_parsed

            // Display hours used over estimeted time
            item.value = `${total_hours_used_parsed} / ${insert(item.value.padStart(4, '0'), ':')}`
          })
        }} forceFit>
        {/* <Legend /> */}
        <Coord transpose />
        <Axis
          name="time"
          label={{ 
            offset: 25,
            formatter(value, item, index) {
              let currentDate = moment()

              /*  ============================
                  Values on isoWeekday()
                  1 = Monday
                  6 = Saturday
                  7 = Sunday
                  ============================ */
              if (value != 0) {  // return date if current value is equals to next day
                if ([6,7].includes(currentDate.isoWeekday()))
                  currentDate = getMonday(currentDate)
                return currentDate.add((value / 800), 'day').format('LL')  // Time (HH:mm without the colon) divided by 8 hours (1 day working hours)
              } else if ([6,7].includes(currentDate.isoWeekday())) {  // return date if current value is 0 (today)
                return getMonday(currentDate).format('LL')
              } else {
                return `${currentDate.format('LL')}\n(${context.translation.today[context.locale]})`
              }
            },
          }}
        />
        <Tooltip />
        <Geom
          type="intervalStack"
          position="name*time"
          style={{
            lineWidth: 2,
            stroke: '#fff'
          }}
          color={["project*name", (project, name) => getColors(project, name)]}
        />
      </Chart>
    </div>
  );
}
