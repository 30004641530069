const publicVapidKey = 'BCvDAsmu8DC3MMwReaTKW-xAIWK2t7yG7Up0TIazvI0BU3lBEInPVldZZrO3sdXAx16AepwJYtf7_uvMx6mNK68'

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export default async () => {
  if ('serviceWorker' in navigator) {
    const register = await navigator.serviceWorker.register('/sw.js', { 
      scope: '/'
    });
    const reg =  await register.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
    });

    return reg
  } else {
    return {}
  }
}