import React, { useContext, useEffect } from 'react';
import { Empty, Spinner2 } from '../../../common';
import Moment from 'react-moment';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone, PaginationTotalStandalone } from 'react-bootstrap-table2-paginator';
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

/* TABLE SETTINGS */
const selectOptions = {
  'Open': 'Open',
  'Question': 'Question',
  'For Testing': 'For Testing',
  'Closed': 'Closed',
};


const status = {
  All: -1,
  Draft: -1,
  Postpone: -1,
  Open: -1,
  Question: -1,
  For_Testing: -1,
  Closed: -1,
  Archive: -1
}

const badgeColorStatus = [
  { name: 'Open', border: '1px solid #CF0F22', backgroundColor: '#FAE6E8', color: '#CF0F22', fontWeight: '400', borderRadius: '2px' },
  { name: 'Question', border: '1px solid #219FC7', backgroundColor: '#E8F5F9', color: '#219FC7', fontWeight: '400', borderRadius: '2px' },
  { name: 'For Testing', border: '1px solid #5B3694', backgroundColor: '#EEEAF4', color: '#5B3694', fontWeight: '400', borderRadius: '2px' },
  { name: 'Postpone', border: '1px solid #E43988', backgroundColor: '#FDEBF3', color: '#E43988', fontWeight: '400', borderRadius: '2px' },
  { name: 'Closed', border: '1px solid #53AD57', backgroundColor: '#EDF7EE', color: '#53AD57', fontWeight: '400', borderRadius: '2px' },
]

const badgeColorPriority = [
  { name: 'High', border: '1px solid #CF0F22', backgroundColor: '#FAE6E8', color: '#CF0F22', fontWeight: '400', borderRadius: '2px' },
  { name: 'Mid', border: '1px solid #FF8B00', backgroundColor: '#FFF3E5', color: '#FF8B00', fontWeight: '400', borderRadius: '2px' },
  { name: 'Low', border: '1px solid #FFC400', backgroundColor: '#FFF9E5', color: '#FFC400', fontWeight: '400', borderRadius: '2px' },
]

export default ({ state, dispatch, points }) => {
  const context = useContext(GlobalContext)
  const { SearchBar } = Search;

  const columns = [{
    dataField: 'id',
    text: 'id',
    hidden: true,
  }, {
    dataField: 'point_nr',
    text: 'ID',
    sort: true,
    headerStyle: (colum, colIndex) => ({ width: '35px' })
  }, {
    dataField: 'instructions',
    text: context.translation.instruction[context.locale],
    headerStyle: (colum, colIndex) => ({ width: context.locale === 'de'? '35%': '45%' })
  }, {
    dataField: 'time_estimate',
    text: context.translation.time_estimate[context.locale],
    align: 'center',
    headerAlign: 'center',
    hidden: state.project_role !== 'None' ? state.project_role === 'Feedback Provider' : true,
  }, {  
    dataField: 'priority',
    text: context.translation.priority[context.locale],
    align: 'center',
    headerAlign: 'center',
    hidden: state.project_role !== 'None' ? state.project_role === 'Feedback Provider': true,
    formatter: (cell, row, rowIndex, formatExtraData) => <span className={`badge badge-sm`} style={{ ...badgeColorPriority.find(e => e.name === cell) }}>{context.translation[cell.toLowerCase()][context.locale]}</span>,
  }, {
    dataField: 'deadline',
    text: context.translation.deadline[context.locale],
    align: 'center',
    headerAlign: 'center',
    hidden: state.project_role !== 'None' ? state.project_role === 'Feedback Provider': true,
    headerStyle: (colum, colIndex) => ({ paddingLeft: context.locale == 'de'? 0: '0.50rem' })
  }, {
    dataField: 'members',
    text: context.translation.working_by[context.locale],
    align: 'center',
    headerAlign: 'center'
  }, {
    dataField: 'status',
    text: 'Status',
    align: 'center',
    headerAlign: 'center',
    formatter: (cell, row, rowIndex, formatExtraData) => <span className={`badge badge-sm ${cell === 'Draft' && "bg-light"}`} style={{ ...badgeColorStatus.find(e => e.name === cell) }}>{context.translation.status[cell.toLowerCase().replace(' ', '_')][context.locale]}</span>,
    // formatter: cell => {
      //   console.log(cell)
      //   return selectOptions[cell]
      // },
      // filter: selectFilter({
        //   options: selectOptions,
        //   placeholder: 'All',
        //   // defaultValue: 'Open'
        // })
      }, {
        dataField: 'creator',
        text: context.translation.created_by[context.locale],
        align: 'center',
        headerAlign: 'center'
      }, {
        dataField: 'date',
        text: context.translation.update[context.locale],
        formatter: (cell, row, rowIndex, formatExtraData) => <Moment locale={context.locale} interval={60000} fromNow>{ cell }</Moment>
  }];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">{' '}
      {context.translation.showing_rows[context.locale]} { from } {context.translation.to[context.locale]} { to } {context.translation.of[context.locale]} { size } {context.translation.results[context.locale]}
    </span>
  ) 

  const options = {
    custom: true,
    paginationSize: 5,
    paginationTotalRenderer: customTotal,
    showTotal: true,
    totalSize: points.length,
    sizePerPageList: [{
      text: '5', value: 5
    }]
  };  

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <ToolkitProvider
      keyField='id'
      bootstrap4
      columns = { columns }
      data={ points.filter(e => state.status !== 'All'? e.status == state.status: true) } 
      search
    >
      {
        props => (
          <>
            <InputGroup style={{ width: '220px', display: 'inline-block', verticalAlign: 'middle' }}>
              <InputGroupAddon style={{ display: 'inline-block', marginTop: '1px' }} addonType="prepend">{context.translation.filter[context.locale]}</InputGroupAddon>
              <Input style={{ width: '130px', display: 'inline-block' }} type="select" name="status" id="status" value={state.status} onChange={e => dispatch({ type: 'SET_STATE', payload: { status: e.currentTarget.value } })}>
                {
                  Object.entries(status).map((el, index) => { 
                    return (
                      <option key={el[0]} value={el[0].split('_').toString().toString().replace(',',' ')}>{context.translation.status[el[0].toLowerCase()][context.locale]}</option>    
                    )
                  })
                }
              </Input>
            </InputGroup>

            <Tooltip title={context.translation.delete[context.locale]}>
                <IconButton className={`${(state.selected.length !== 0)? 'scale-in-center': 'scale-hide'}`} aria-label={context.translation.delete[context.locale]} onClick={() => {
                    dispatch({ type: 'SET_STATE',
                      payload: {
                        modal: !state.modal,
                        modalType: 'delete',
                        deleteType: 'point'
                      }
                    })
                }}>
                  <DeleteIcon
                   />
                </IconButton>
            </Tooltip>

            <SearchBar placeholder={context.translation.search[context.locale]} { ...props.searchProps } />
            <span style={{ float: 'right', marginRight: '10px' }}><PaginationListStandalone { ...paginationProps } /></span>

            <BootstrapTable 
              { ...props.baseProps }
              { ...paginationTableProps }
              rowEvents={{
                onClick: (e, row, rowIndex) => {
                  const {last_change, feedback_id, creator_id, creator, clientname, comments, deadline, deadlineMoment, files, date, date_created, team: users, time_estimate, hours_worked, time_approve, version, version_name, instructions, priority, status} = row
                  let editItem = (row.id > 0)? { id: row.id, point_nr: row.point_nr }: {}

                  const payload = {
                    editedItem: {
                      ...state.editedItem,
                      ...editItem,
                      comments, 
                      last_change,
                      feedback_id,
                      clientname,
                      users,
                      creator: creator === 'Deleted User' ? context.translation.deleted_user[context.locale] : creator,
                      date,
                      date_created,
                      creator_id, 
                      time_estimate: time_estimate === 'N/A'? '' : time_estimate, 
                      hours_worked: hours_worked === 'N/A'? '' : hours_worked, 
                      version: version === 'N/A'? state.versions[0].id + "|" + state.versions[0].version_name: version, 
                      version_name, 
                      time_approve,
                      instructions, 
                      priority, 
                      status,
                      deadline: deadlineMoment,
                      deadlineDisplay: deadline,
                      rowIndex
                    },
                    files: (row.id > 0) ? files.map(e => ({source: `project_${state.project.id}/feedback_${state.id}/point_${row.point_nr}_${row.id}/${e.filename}`, options: { type: 'local' }, filename: e.filename})): files,
                  }
                  
                  dispatch({ type: 'SET_STATE', 
                    payload: { 
                      modal: true, 
                      modalType: 'update',
                      ...payload,
                      rowData: {...payload} // For default data when cancel is clicked
                      // gallery: files.map(e => ({ filename: e.filename, src: `${context.uri}?load=project_${state.project.id}/feedback_${state.id}/point_${row.point_nr}_${row.id}/${e.filename}`, width: 1, height: 1 }))
                    } 
                  })
                } 
              }}
              selectRow={{
                mode: 'checkbox',
                selected: state.selected,
                onSelect: (row, isSelect, rowIndex, e) => {
                  if (isSelect) 
                    dispatch({ type: 'SET_STATE', payload: { selected: [ ...state.selected, row.id ] } })
                  else 
                    dispatch({ type: 'SET_STATE', payload: { selected: state.selected.z(x => x !== row.id) } })
                },
                onSelectAll: (isSelect, rows, e) => {
                  const ids = rows.map(r => r.id);
                  if (isSelect) 
                    dispatch({ type: 'SET_STATE', payload: { selected: ids } })
                  else
                    dispatch({ type: 'SET_STATE', payload: { selected: [] } })
                }
              }}
              filter={ filterFactory() }
              noDataIndication={ () => state.loading? <Spinner2 />: <Empty /> }
              bordered={ false }
              hover 
              />
              <SizePerPageDropdownStandalone { ...paginationProps } />
              <PaginationTotalStandalone { ...paginationProps } />
              <span style={{ float: 'right' }}><PaginationListStandalone { ...paginationProps } /></span>
        </>
        )
      }
    </ToolkitProvider> 
  )

  return (
    <div id="feedback-points-table">
      <PaginationProvider
        pagination={
          paginationFactory(options)
        }
      >
        { contentTable }
      </PaginationProvider>
    </div >
  );
}