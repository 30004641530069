import React, { useReducer, useState, useContext, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faUserCircle, faFolder, faBell } from '@fortawesome/free-regular-svg-icons';
import Scrollbar from 'react-smooth-scrollbar';
import { Badge as Badger } from 'reactstrap';
import { Skeleton, List, Avatar, Badge as BadgeAntd, Icon, Spin, Button } from 'antd';
import moment from 'moment';

import Modal from '../exactplace/feedback/form/FeedbackFormModal'

import AssignedPointIcon from '../../brand/common/assigned_point.svg'
import CommentIcon from '../../brand/common/comment.svg'
import FeedbackIcon from '../../brand/common/feedback.svg'
import PointIcon from '../../brand/common/point.svg'
import FileUpload from '../../brand/common/file_upload.svg'
import StatusChange from '../../brand/common/status_change.svg'
import ProjectIcon from '../../brand/common/project.svg'
import { Empty } from './index.js';
// import Skeleton from 'react-loading-skeleton';

import { NOTIFICATIONS_QUERY, NOTIF_SEEN, NEW_NOTIF, POINT_NOTIF_QUERY } from '../../queries';
import { GlobalContext } from '../../contexts/GlobalContext'
import { ApolloContext } from 'react-apollo'; 
import { AuthContext } from '../../contexts/AuthContext'
import { useSubscription } from 'react-apollo-hooks';
import NotifReducer from './NotifReducer'

const StyledBadge2 = withStyles(theme => ({
  badge: {
    backgroundColor: '#FD5D93',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid #FD5D93',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const badgeColorStatus = [
  { name: 'Open', border: '1px solid #CF0F22', backgroundColor: '#FAE6E8', color: '#CF0F22', fontWeight: '400', borderRadius: '2px' },
  { name: 'Question', border: '1px solid #219FC7', backgroundColor: '#E8F5F9', color: '#219FC7', fontWeight: '400', borderRadius: '2px' },
  { name: 'For Testing', border: '1px solid #5B3694', backgroundColor: '#EEEAF4', color: '#5B3694', fontWeight: '400', borderRadius: '2px' },
  { name: 'Postpone', border: '1px solid #E43988', backgroundColor: '#FDEBF3', color: '#E43988', fontWeight: '400', borderRadius: '2px' },
  { name: 'Closed', border: '1px solid #53AD57', backgroundColor: '#EDF7EE', color: '#53AD57', fontWeight: '400', borderRadius: '2px' },
]

const badgeColorPriority = [
  { name: 'High', border: '1px solid #CF0F22', backgroundColor: '#FAE6E8', color: '#CF0F22', fontWeight: '400', borderRadius: '2px' },
  { name: 'Mid', border: '1px solid #FF8B00', backgroundColor: '#FFF3E5', color: '#FF8B00', fontWeight: '400', borderRadius: '2px' },
  { name: 'Low', border: '1px solid #FFC400', backgroundColor: '#FFF9E5', color: '#FFC400', fontWeight: '400', borderRadius: '2px' },
]

const useStyles = makeStyles(theme => ({
  root: {
    width: 'inherit',
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

const initialEditedItem = {
  user: { id: 0, name: '' },
  instructions: '',
  status: 'Open',
  deadline: '',
  time_estimate: '',
  hours_worked: '',
  version: 0,
  priority: 'Low',
  comments: [],
  users: [],
  deadline: moment(),
  deadlineDisplay: 'N/A',
  comment: '',
}

const editField = {
  editPoint: false,
  editInstructions: false,
  editStatus: false,
  editTimeEstimate: false,
  editHoursWorked: false,
  editDeadline: false,
  editPriority: false,
  editAssignedUsers: false,
  editVersion: false,
}

const initialState = {  
  ...editField,
  id: -1,
  selected: [],
  modal: false,
  modalType: '',
  files: [],
  removefiles: [],
  project: {
    id: -1,
    name: ''
  },
  role: '',
  gallery: [],
  members: [],
  editedItem: {...initialEditedItem},
  editPoint: false,
  uploadNewFiles: false,
  loadURI: '',
  rowData: {files: []},
  dupFiles: [],
  versions: [],
  points: [],
  isFeedbackList: 'notif',
  isLoading: false,
}

let counter = 0;
export default withRouter((props) => {  
  const [state, dispatch] = useReducer(NotifReducer, initialState)
  const context = useContext(GlobalContext)
  const { client } = useContext(ApolloContext)
  const { user } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const open = Boolean(anchorEl);
  const [notif, setNotif] = useState([])
  const [initLoading, setInitLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [notifCount, setNotifCount] = useState(0)
  const [filter, setFilter] = useState(0)
  const [isNewNotif, setIsNewNotif] = useState(false)
  const [unseenNotifs, setUnseenNotifs] = useState({0:0,1:0,2:0,3:0})
  const newNotif = useSubscription(NEW_NOTIF, {variables: { data: {user_id: user.id} }});
  const scrollbar = useRef(null)
  const { history } = props
  
  useEffect(() => {
    // console.log(newNotif)
    if (newNotif.data){
      setNotif([...newNotif.data.newNotif, ...notif])
    }
  }, [newNotif.data])

  useEffect(() => {
    counter = 0
    loadMore('init')
  }, [])

  useEffect(() => {
    if (notif.length !== 0) {
      setIsNewNotif(!!notif.find(e => e.stats === 0))
      setUnseenNotifs({
        0: notsi(0).filter(e => e.stats === 0).length,
        1: notsi(1).filter(e => e.stats === 0).length,
        2: notsi(2).filter(e => e.stats === 0).length,
        3: notsi(3).filter(e => e.stats === 0).length,
      })
    }
  }, [notif])

  useEffect(() => {
    if (!state.modal)
      dispatch({ type: 'SET_STATE', payload: { editedItem: {...initialEditedItem}, files: [], removefiles: [], gallery: [], editPoint: false, id: -1, ...editField } })
    else 
      dispatch({ type: 'SET_STATE', payload: { isLoading: false } })

  }, [state.modal])

  function loadMore(type) {
    client.query({ query: NOTIFICATIONS_QUERY(counter++ * 6) }).then(({data}) => {
      if (data.notifications)
        setNotif([...notif, ...data.notifications])
      setNotifCount(data.notifCount.totalCount)

      if (type === 'init')
        setInitLoading(false)
      else
        setLoading(false)
    })
  }

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick = (index, stats, loc, type, status, point_id) => {
    if (stats === 0) {
      client.mutate({ mutation: NOTIF_SEEN, variables: { data: {notif_id: index, stats: 1} } }).then(({ data }) => {
        setNotif(notif.map(e => e.id === index? {...e, stats: 1}: {...e} ))
      })
    }

    // If type is point created or assigned point change the status from the url to the current status of the point
    // const locate = (type === 'Point Created' || type === 'Assigned Point') ? '/' + loc.split('/').splice(1,3).join('/') + '/' + status.toLowerCase().replace(' ','_') :loc
    // history.push(locate)

    dispatch({ type: 'SET_STATE', payload: { isLoading: true } })
    client.query({ query: POINT_NOTIF_QUERY(point_id) }).then(({data}) => {
      const {id, last_change, point_nr, proj_name, feedback_id, title, creator_id, creator, comments, clientname, deadline, deadlineMoment, date, date_created, files, team: users, hours_worked, time_estimate, time_approve, version, version_name, instructions, priority, status, proj_id} = data.point
      const members =  data.point.proj_team
      let editItem = (id > 0)? { id: id, point_nr: point_nr }: {}

      handleClose();
       
      let project_role = 'None'
      members.forEach(e => {
        if (e.id == user.id) 
          project_role = e.project_role
      })

      const payload = {
        editedItem: {
          ...state.editedItem,
          ...editItem,
          comments, 
          clientname, 
          last_change,
          users,
          date,
          date_created,
          creator_id, 
          creator,
          time_estimate: time_estimate === 'N/A'? '' : time_estimate, 
          hours_worked: hours_worked === 'N/A' ? '' : hours_worked,
          version: version === 'N/A'? state.versions[0].id + "|" + state.versions[0].version_name: version, 
          version_name, 
          time_approve,
          instructions, 
          priority, 
          status,
          deadline: deadlineMoment,
          deadlineDisplay: deadline,
        },
        files: (id > 0) ? files.map(e => ({source: `project_${proj_id}/feedback_${feedback_id}/point_${point_nr}_${id}/${e.filename}`, options: { type: 'local' }, filename: e.filename})): files,
      }

      dispatch({ type: 'SET_STATE', 
        payload: { 
          id: feedback_id,
          modal: true, 
          title,
          modalType: 'update',
          project: {
            id: proj_id,
            name: proj_name
          },
          project_role,
          members,
          versions: data.point.project_versions.map(e => ({id: e.id, version_name: e.version_name})),
          ...payload,
          rowData: {...payload},
          // For default data when cancel is clicked
          // gallery: files.map(e => ({ filename: e.filename, src: `${context.uri}?load=project_${state.project.id}/feedback_${state.id}/point_${row.point_nr}_${row.id}/${e.filename}`, width: 1, height: 1 }))
        } 
      })
    })
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const markAll = () => {
    client.mutate({ mutation: NOTIF_SEEN, variables: { data: { stats: -1 } }}).then(({ data }) => {
      setNotif(notif.map(e => ({...e, stats: 1})))
    })
  }

  const turncate = par => {
    if(par.length > 250){
        par = par.substring(0, 250) + '...';
    }
    return(par);
  }

  const notsi = (fill) => notif.filter(e => (
    fill === 0? true:
      fill === 1? e.type === 'Feedback': 
        fill === 2? e.type === 'Point Created' || e.type === 'Assigned Point' || e.type === 'Status Changed' || e.type === 'File Upload':
          fill === 3 ? e.type === 'Comment':
            e.type === 'Project'
  ))

  function onLoadMore() {
    setLoading(true)
    setNotif([...notif].concat([...new Array(3)].map(() => ({ loading: true, type: null }))))

    loadMore('loading')
  }

  const loadMoreComponent =
      (!initLoading && !loading) && (((counter * 6) < notifCount) && (6 < notifCount))? (
        <div
          style={{
            textAlign: 'center',
            marginTop: 12,
            height: 32,
            lineHeight: '32px',
          }}
        >
          <Button onClick={onLoadMore}>{ context.translation.fetch_data[context.locale] }</Button>
        </div>
      ) : null;
  
  return (
    <>
      <Modal state={state} dispatch={dispatch} />
      
      <Box className="notification-b" display="flex" justifyContent="flex-end" style={{ width: '100%' }}>
        <Box m={1}>
          <a id="notif-bell" onClick={handleMenu}>
            <BadgeAntd count={unseenNotifs[0]}>
              <span className="head-example">
                <FontAwesomeIcon icon={faBell} style={{ verticalAlign: 'middle', height: '100%', width: '100%' }}/> 
              </span>
            </BadgeAntd>
          </a>
          <Menu
            id="menu-notif"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
            style={{ top: '34px' }}
          >
            <Typography
              component="span"
              variant="body2"
              className={classes.inline}
              color="textPrimary"
              style={{ marginLeft: '10px' }}
            >
              { context.translation.notifications[context.locale] }
            </Typography>
            <Typography
              component="span"
              variant="body2"
              className={classes.inline}
              color="textPrimary"
              style={{ float: 'right', marginRight: '10px' }}
            >
              <a href='#' className="mark-all" onClick={() => markAll()}>{ context.translation.mark_all[context.locale] }</a>
            </Typography>

            <div className="notif-tabs">
              <div onClick={() => setFilter(0)} className="tabits">
                <div className={`tabi ${filter === 0 && 'tabiactive'}`} >{context.translation.status.all[context.locale]} 
                  {
                    unseenNotifs[0] !== 0 && <Badger pill>{unseenNotifs[0]}</Badger>
                  }
                </div>
              </div>
              {/* <div onClick={() => setFilter(1)} className="tabits">
                <div className={`tabi ${filter === 1 && 'tabiactive'}`}>{context.translation.feedbacks[context.locale]} 
                  {
                    unseenNotifs[1] !== 0 && <Badger pill>{unseenNotifs[1]}</Badger>
                  }
                </div>
              </div> */}
              <div onClick={() => setFilter(2)} className="tabits">
                <div className={`tabi ${filter === 2 && 'tabiactive'}`}>{context.translation.point[context.locale]} 
                  {
                    unseenNotifs[2] !== 0 && <Badger pill>{unseenNotifs[2]}</Badger>
                  }
                </div>
              </div>
              <div onClick={() => setFilter(3)} className="tabits">
                <div className={`tabi ${filter === 3 && 'tabiactive'}`}>{context.translation.comments[context.locale]} 
                  {
                    unseenNotifs[3] !== 0 && <Badger pill>{unseenNotifs[3]}</Badger>
                  }
                </div>
              </div>
              {/* <div onClick={() => setFilter(3)} className="tabits">
                <div className={`tabi ${filter === 3 && 'tabiactive'}`}>{context.translation.nav.project[context.locale]} 
                  {
                    unseenNotifs[3] !== 0 && <Badger pill>{unseenNotifs[3]}</Badger>
                  }
                </div>
              </div> */}
            </div>

            <Scrollbar id="scroll-notif" ref={scrollbar} alwaysShowTracks={true} renderByPixels={true} continuousScrolling={true} style={{ maxHeight: '55vh', minHeight: 'fit-content', width: '100%', paddingBottom: 0 }}>
              <div id="fetch-container" className={state.isLoading? 'show-loading': ''}>
                <div id="fetch-more">
                  <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
                  <span>{ context.translation.fetch_more[context.locale] }</span> <br />
                </div>
              </div>

              <List
                id="notif-content"
                className="demo-loadmore-list"
                loading={initLoading}
                itemLayout="horizontal"
                loadMore={loadMoreComponent}
                dataSource={notsi(filter)}
                renderItem={item => (
                  <List.Item>
                    <Skeleton avatar title={false} loading={item.loading} active>
                      <List.Item.Meta
                        className={`${item.stats === 0 && 'unread-notif'}`} 
                        avatar={
                          <Avatar src={ 
                          // item.type === 'Feedback'? FeedbackIcon: 
                            item.type === 'Point Created'? PointIcon:
                              item.type === 'Assigned Point'? AssignedPointIcon:
                                item.type === 'Project'? ProjectIcon: 
                                  item.type === 'Comment'? CommentIcon:
                                    item.type === 'File Upload'? FileUpload: 
                                      item.type == null? '': StatusChange }/>
                        }
                        title={ item.type != null && context.translation[(item.type).toLowerCase().replace(' ', '_')][context.locale] }
                        description={ item.type != null &&
                          <>
                            <span style={{ fontWeight: 400, color: '#525252' }}>{context.translation[item.header.split(' ')[0].toLowerCase()][context.locale] + ' ' + item.header.split(' ')[1]}</span>
                            {` — ${item.type !== 'Point Created' && item.type !== 'Assigned Point' && item.type !== 'Comment' ? item.creator_name: ''} ${turncate(item.message)}${(item.type === 'Comment')? '"': ''}`}

                            <div className="footer">
                              <div className="left">
                                <div className="inner-left">
                                  {
                                    (item.type === 'Point Created' || item.type === 'Assigned Point') && 
                                      <div className="badges">
                                        <span className={`badge badge-sm ${item.status === 'Draft' && "bg-light"}`} style={{ ...badgeColorStatus.find(s => s.name === item.status) }}>{item.status}</span> 
                                        <span className={`priority badge badge-sm`} style={{ ...badgeColorPriority.find(s => s.name === item.priority) }}>{context.translation[item.priority.toLowerCase()][context.locale]}</span>
                                      </div>
                                  }

                                  <small style={{height: '17px'}}><FontAwesomeIcon icon={faFolder}/> { item.proj_name }</small>
                                  { 
                                    (item.type === 'Point Created' || item.type === 'Assigned Point') && 
                                      <small style={{height: '17px'}}><FontAwesomeIcon icon={faUserCircle}/> { item.creator }</small>
                                  }
                                  <small style={{height: '17px'}}><FontAwesomeIcon icon={faClock}/> <Moment  interval={60000} fromNow>{ item.date_received }</Moment></small>
                                </div>

                                {
                                  item.deadline !== 'N/A' && item.deadline != null && item.deadline != '' && 
                                    <span className={`deadline badge badge-sm`}><span className="deadline-label">{ context.translation.deadline[context.locale] }</span> <span className="deadline-moment"><Moment format="ll" locale={context.locale}>{ item.deadlineDisplay }</Moment></span></span>
                                }
                              </div>
                            </div>
                          </>
                        }
                        onClick={() => item.type != null && handleClick(item.id, item.stats, item.location, item.type, item.status, item.point_id)}
                      />
                    </Skeleton>
                  </List.Item>
                )}
              />
            </Scrollbar>
            <span style={{ display: 'block', margin: '5px 10px 0 10px', color: '#dddddd' }}>Beta (v1)</span>
          </Menu>
        </Box>
      </Box>
    </>
  )
})