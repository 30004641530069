import React from 'react';
import { withRouter } from 'react-router-dom';
import { Typography } from 'antd';

const { Paragraph } = Typography; 

export default withRouter((props) => {
  const { history } = props;

  return (
    <>
      <h2 style={{ marginBottom: '20px' }}>Introduction</h2>

      <Paragraph>
        In dieser Online-Applikationen werden Feedbacks erfasst, bearbeitet und kommentiert. Der Status jedes einzelnen Feedbacks kann jederzeit eingesehen werden. D.h. ein Projektfortschritt ist unmittelbar für den Kunden erkennbar.
      </Paragraph>

      <Paragraph>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Amet tellus cras adipiscing enim eu turpis egestas pretium aenean. Accumsan in nisl nisi scelerisque eu ultrices vitae. Donec et odio pellentesque diam volutpat commodo. Laoreet id donec ultrices tincidunt arcu non sodales. Fames ac turpis egestas integer eget aliquet. Donec adipiscing tristique risus nec feugiat in fermentum posuere. Lacus viverra vitae congue eu consequat ac. Nisl suscipit adipiscing bibendum est ultricies integer. Lobortis mattis aliquam faucibus purus in. Etiam sit amet nisl purus in mollis nunc. Congue eu consequat ac felis donec. Vulputate enim nulla aliquet porttitor lacus luctus accumsan tortor. Sed euismod nisi porta lorem mollis aliquam ut. Tincidunt id aliquet risus feugiat in.
      </Paragraph>
      Sit amet est placerat in egestas erat imperdiet. Et netus et malesuada fames ac turpis egestas integer. Ut eu sem integer vitae. Id velit ut tortor pretium viverra suspendisse potenti nullam ac. Sollicitudin aliquam ultrices sagittis orci a scelerisque. Nec dui nunc mattis enim ut tellus elementum sagittis. Tempus quam pellentesque nec nam. Volutpat blandit aliquam etiam erat velit scelerisque in dictum. Maecenas pharetra convallis posuere morbi leo urna. Lacus laoreet non curabitur gravida arcu ac tortor dignissim. Nunc id cursus metus aliquam eleifend mi in nulla posuere. Ornare aenean euismod elementum nisi quis eleifend quam adipiscing vitae. Id venenatis a condimentum vitae sapien pellentesque. Amet consectetur adipiscing elit duis tristique. Sed cras ornare arcu dui vivamus arcu felis bibendum.

      <Paragraph>
        Ac auctor augue mauris augue neque. Tellus id interdum velit laoreet id donec ultrices. Accumsan lacus vel facilisis volutpat est. Nibh venenatis cras sed felis eget velit. Pretium viverra suspendisse potenti nullam ac tortor vitae purus. Nunc mi ipsum faucibus vitae aliquet nec ullamcorper sit amet. Et netus et malesuada fames ac. Convallis posuere morbi leo urna. Morbi tempus iaculis urna id volutpat lacus laoreet. Et sollicitudin ac orci phasellus egestas. Elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus. Facilisis volutpat est velit egestas. Fames ac turpis egestas sed tempus urna. Eu non diam phasellus vestibulum.
      </Paragraph>

    </>
  )
})