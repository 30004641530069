import React from 'react';
import ModalForm from '../../../../../user_plan/user/UserModalCreate';

export default ({ state, dispatch, context}) => {
  function updateFormState (event, object_name) {
    dispatch({ type: 'SET_STATE', payload: { form: { ...state.form, [object_name]: event.target.value } } });
  }

  function setIsGenerate(e) {
    dispatch({ type: 'SET_STATE', payload: { isGenerate: e } })
  }

  return (<ModalForm payload={{ state: state, form: state.form, errors: state.errors.user, type: 'add', isChangePass: false, updateFormState, items: state.groups, clients: state.clients, setIsGenerate, isGenerate: state.isGenerate }} />)
}