import React from 'react';
import { withRouter } from 'react-router-dom';
import { Typography, Row, Col, Icon, Button } from 'antd';

import SampleImage from '../../../brand/addproject.png';

const { Paragraph } = Typography;

export default withRouter((props) => {
  const { history, context } = props;

  function startTour() {
    context.dispatch({ type: 'SET_STATE', payload: { filteredCategory: 'project', isTourOpen: true } })
  }

  return (
    <>
      <span><h2 style={{ marginBottom: '20px', display: 'inline-block' }}>Project</h2> <Button onClick={startTour} type="link" icon="caret-right" style={{ verticalAlign: 'text-bottom' }}/></span>

      <Row gutter={[16, 16]}>
        <Col sm={24} md={14}>
          <Paragraph>
            In dieser Online-Applikationen werden Feedbacks erfasst, bearbeitet und kommentiert. Der Status jedes einzelnen Feedbacks kann jederzeit eingesehen werden. D.h. ein Projektfortschritt ist unmittelbar für den Kunden erkennbar.
          </Paragraph>
        </Col>

        <Col sm={24} md={10}>
          <img style={{ width: '100%', float: 'right' }} src={SampleImage} />
        </Col>
      </Row>
    </>
  )
})