import React, { useContext } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import { Button } from '..';

/* Import Context Provider and Reducer */
import { GlobalContext } from '../../../contexts/GlobalContext';
import BackgroundPhoto from "./404.svg";

const ComponentNotFound = withRouter(({ location }) => {
    const { translation, locale } = useContext(GlobalContext);

    return (
        <div>
            <Row style={{ marginTop: '8%' }}>
                <Col md={{ size: 6, offset: 0 }} sm={{ size: 14, offset: 5 }}>
                    <img alt="example" src={ BackgroundPhoto } style={{ float: 'right' }} />
                </Col>
                <Col md={{ size: 6, offset: 0 }} sm={{ size: 22, offset: 1 }} style={{ textAlign: 'left', marginTop: '50px'} }>   
                    <h1>Oops!</h1> 
                    <h4 level={4}>404 - {translation.page_not_found.title[locale]}</h4>
                    <p>{translation.page_not_found.description[locale]}</p>
                    <Link to="/"><Button className="btn-exact shadow">{translation.page_not_found.homepage[locale]}</Button></Link>
                </Col>
            </Row>
        </div>
    )
})

export default ComponentNotFound;
