import React, { useContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignLeft } from '@fortawesome/free-solid-svg-icons';
import { Navbar, Button, Col, Nav, NavItem, NavLink } from 'reactstrap';
import { GlobalContext} from '../../contexts/GlobalContext';

import ArrowLeftIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const NavigationLinks = ({ items }) => {
    return items ? (
        <Nav className="ml-auto" navbar>
            {items.map((link, i) => {
                return (
                    <NavItem key={i}>
                        <NavLink href="#">{link}</NavLink>
                    </NavItem>
                )
            })}
        </Nav>
    ) : '';
}

const Components = ({ components }) => {
    return components ? components : '';
}

const CustomTabs = ({ tabs }) => {
    return tabs ? tabs : '';
}

export default withRouter(({ title, items, components, back, history, location, customTabs }) => {
  const { dispatch, isOpen } = useContext(GlobalContext);

  function goBack() {
    // (location.pathname.split('/')[2] === 'view') ? history.goBack() : history.push('/feedbacks')
    history.push('/feedbacks')
  }

  return (
      <Navbar color="light" light className="navbar shadow-sm mb-2 bg-white rounded nav-header" expand="md" style={{ padding: '0.85rem 1rem', height: '57.19px' }}>
          <div style={{ position: 'absolute' }}>
            {/* <Button className="btn-outline-exact" onClick={() => {dispatch({ type: 'SET_IS_OPEN', isOpen: !isOpen })}} style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faAlignLeft}/></Button> */}
            { back ? (
              <Tooltip title="Back">
                  <IconButton aria-label="Back" onClick={() => goBack()} style={{ marginRight: '5px', pointerEvents: 'all' }}>
                    <ArrowLeftIcon />
                  </IconButton>
              </Tooltip>
            ): ''}
            {' '}
            <h5 style={{ display: 'inline-block', verticalAlign: 'middle' }}>{title}</h5>
          </div>

          <CustomTabs tabs={customTabs} />

          <NavigationLinks items={items} />
          <Components components={components} />
          {/* <NavbarToggler onClick={() => {dispatch({ type: 'SET_IS_OPEN', isOpen: !isOpen })}} /> */}
      </Navbar>
  );
})