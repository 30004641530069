import React, { useState, useContext, useEffect } from 'react';
import { useSpring, animated } from 'react-spring'
import { Row, Col, Alert, Form, FormGroup, Label, Input, FormFeedback, Spinner } from 'reactstrap';
import SpringStyle from './SpringStyle'
import Button from '@material-ui/core/Button';
import { Toast } from '../../common';
import { Switch, Icon, message } from 'antd';

/* Import Context Provider */
import { GlobalContext } from '../../../contexts/GlobalContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { ApolloContext } from 'react-apollo';
import { UPDATE_ACCOUNT_SETTINGS, UPDATE_EMAIL_AUTHENTICATION, CURRENT_USER_QUERY } from '../../../queries';

export default props => {
  const context = useContext(GlobalContext)
  const { user, dispatch: authDispatch  } = useContext(AuthContext)
  const { client } = useContext(ApolloContext)
  const { state, dispatch } = props.reducer
  const [isLoading, setLoading] = useState(false);
  const [activeSecurity, setActiveSecurity] = useState(false);
  const [oldPass, setOldPass] = useState('password')
  const [newPass, setNewPass] = useState('password')
  const [confirmNewPass, setConfirmNewPass] = useState('password')
  const history = props.history

  useEffect(() => {
    dispatch({ type: 'SET_STATE', payload: { old_password: '', passwod: '', c_password: '' } })


    // Get value of email authentication
    setLoading(loading => !loading)
    client.query({ query: CURRENT_USER_QUERY }).then(({ data }) => {
      setActiveSecurity(!!data.currentUser.active_security)
      setLoading(loading => !loading)
    }).catch(err => {
      setLoading(loading => !loading)
    })
  }, [])

  const saveChanges = async () => {
    try {
      if (state.password === state.c_password) {
        dispatch({ type: 'SET_STATE', payload: { isLoading: true, errors: {...state.defaultErrors} } })
        const { data } = await client.mutate({ mutation: UPDATE_ACCOUNT_SETTINGS, variables: { data: { old_password: state.old_password, password: state.password, type: 'security', lang: context.locale } } })
    
        if (data.updateAccountInfo.status === 400) {
          dispatch({ type: 'SET_STATE', payload: { errors: { ...state.errors, password: { invalid: true, message: context.translation.same_old_password_error[context.locale] } } } })
        } else if (data.updateAccountInfo.status === 401) {
          dispatch({ type: 'SET_STATE', payload: { errors: { ...state.errors, old_password: { invalid: true, message: context.translation.old_password_error[context.locale] } } } })
        } else if (data.updateAccountInfo.status === 200) {
          if (user.first_login === 0)
          history.push('/dashboard')
          dispatch({ type: 'SET_STATE', payload: { old_password: '', password: '', c_password: '' } })
          authDispatch({ type: 'SET_STATE', payload: { user: data.updateAccountInfo.user } })
          message.success(context.translation.password_change_msg[context.locale])
          // Toast.showToast('success', context.translation.password_change_msg[context.locale])
        }
      } else {
        dispatch({ type: 'SET_STATE', payload: { errors: { ...state.errors, password: { invalid: true, message: context.translation.password_not_match[context.locale] }} } })
      }
    } catch (error) {
      console.log(error)
    }
    dispatch({ type: 'SET_STATE', payload: { isLoading: false } })
  }

  function switchAuth(checked) {
    setLoading(loading => !loading)
    client.mutate({ 
      mutation: UPDATE_EMAIL_AUTHENTICATION, 
      variables: {  
        data: !activeSecurity
      } 
    }).then(({ data }) => {
      setActiveSecurity(checked)
      message.success(context.translation.update_successful[context.locale])
      setLoading(loading => !loading)
    }).catch(err => {
      console.log(err)
      setActiveSecurity(false)
      message.error(context.translation.error_occurred[context.locale])
      setLoading(loading => !loading)
    })
  }

  return (
    <animated.div style={useSpring(SpringStyle)}>
      <Form onSubmit={async (e) => {
          e.preventDefault();
          saveChanges()
      }} name="modal-form">
        <h5 style={{ paddingBottom: '0.41rem', borderBottom: '1px solid #e3e3e3', width: '100%' }}>{ context.translation.security_settings[context.locale] }</h5><br />
        {
          user.first_login === 0 &&
            <Alert color="warning">{context.translation.first_signin[context.locale]}</Alert>
        }

        {/* <Row>
          <Col xs={12}>
            <FormGroup style={{ position: 'relative' }}>
              <Label for="old_password">{context.translation.email_authentication[context.locale]}</Label>
              <Switch
                onChange={switchAuth}
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
                checked={activeSecurity}
                loading={isLoading}
                style={{ display: 'block' }}
              />
            </FormGroup>
          </Col>
        </Row> */}

        <Row>
          <Col xs={12}><span style={{ margin: '12px 0', fontWeight: 500 }}>{ context.translation.change_password[context.locale] }</span></Col>
          <hr />
          <Col xs={12}>
            <FormGroup style={{ position: 'relative' }}>
              <Label for="old_password">{context.translation.old_password[context.locale]}</Label>
              <Input type={oldPass} name="old_password" id="old_password" invalid={state.errors.old_password.invalid} value={state.old_password} onChange={(e) => { dispatch({ type: 'SET_STATE', payload: { old_password: e.target.value } })}} bsSize="sm" required />
              <FormFeedback>{state.errors.old_password.message}</FormFeedback>
              <i onClick={() => setOldPass(oldPass == 'password' ? 'text': 'password')} className={`fas ${oldPass == 'password'? 'fa-eye': 'fa-eye-slash'} view-password`} style={{ position: 'absolute', top: '34px', right: '10px', color: '#007BFF', fontSize: '14px' }}></i>
            </FormGroup>
          </Col>

          <Col xs={12}>
            <FormGroup style={{ position: 'relative' }}>
              <Label for="password">{context.translation.new_password[context.locale]}</Label>
              <Input pattern='^(?=.*[A-Za-z0-9])[A-Za-z0-9, .!@#$%^&*()_]{6,25}$' title={context.translation.msg_password[context.locale]} type={newPass}  name="password" id="password" invalid={state.errors.password.invalid} value={state.password} onChange={(e) => { dispatch({ type: 'SET_STATE', payload: { password: e.target.value } })}} bsSize="sm" required />
              <FormFeedback>{state.errors.password.message}</FormFeedback>
              <i onClick={() => setNewPass(newPass == 'password' ? 'text': 'password')} className={`fas ${newPass == 'password'? 'fa-eye': 'fa-eye-slash'} view-password`} style={{ position: 'absolute', top: '34px', right: '10px', color: '#007BFF', fontSize: '14px' }}></i>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FormGroup style={{ position: 'relative' }}>
              <Label for="c_password">{context.translation.confirm_new_password[context.locale]}</Label>
              <Input type={confirmNewPass}  name="c_password" id="c_password" value={state.c_password} onChange={(e) => { dispatch({ type: 'SET_STATE', payload: { c_password: e.target.value } })}} bsSize="sm" required />
              <i onClick={() => setConfirmNewPass(confirmNewPass == 'password' ? 'text': 'password')} className={`fas ${confirmNewPass == 'password'? 'fa-eye': 'fa-eye-slash'} view-password`} style={{ position: 'absolute', top: '34px', right: '10px', color: '#007BFF', fontSize: '14px' }}></i>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Button disabled={state.isLoading} variant="contained" color="primary" style={{ background: (state.isLoading)? 'rgba(41,72,255,0.6)': 'linear-gradient(-45deg, #396afc, #2948ff)' }} onClick={() => saveChanges()}><Spinner color="light" size="sm" style={{ marginRight: '10px', display: (state.isLoading)? 'inline-block' : 'none' }} /> <span>{context.translation.update_password[context.locale]}</span></Button>
          </Col>
        </Row>
      </Form>
    </animated.div>
  );
}