import React, { useContext, useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { withRouter, Route, Switch } from 'react-router-dom'
import '../style.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

/* Import Common Components */
import { Page, Filter, Tabs } from '../../common';
import DashboardWorkplace from './workplace/DashboardWorkplace'
import DashboardAnalysis from './analysis/DashboardAnalysis'

/* Import Context Provider */
import { GlobalContext } from '../../../contexts/GlobalContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { ApolloContext } from 'react-apollo'; 
import { PROJECT_CLIENT_QUERY, STATUS_QUERY, IS_TOUR_OPEN } from '../../../queries';

let isLoading = false
export default withRouter(props => {
  let initialLoad = false
  const { client } = useContext(ApolloContext);
  const { user, dispatch } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState('1');
  const [loadTableChange, setLoadTableChange] = useState(null)
  const context = useContext(GlobalContext);
  const [value, setValue] = useState(getCurrentValue());
  const [project, setProjects] = useState([]);
  const handleChange = (event, newValue) => {
    props.history.push(newValue === 0? '/dashboard/analysis': '/dashboard/workplace')
    setValue(newValue);
  };

  const customTabs = user.role !== 'Client'? {
    customTabs: (
    <Tabs 
      reducer={{ value, handleChange }} 
      tabs={[context.translation.analysis[context.locale], context.translation.workplace[context.locale]]} 
    />
  )}: {}

  useEffect(() => {
    if (props.location.pathname.toLowerCase().split('/', 3).join('/') === '/dashboard/analysis')
      setValue(0)
    else 
      setValue(1)
  }, [props.location])
  
  useEffect(() => {
    const val = getCurrentValue()

    // if (!isLoading) {
      isLoading = true
      client.query({ query: PROJECT_CLIENT_QUERY(val === 0 ? 'false' : 'true') }).then(({ data }) => {
        const { projects, clients } = data;
        let payload = {
          projects,
          clients
        }
        if (val === 0) {
          setProjects(projects)
          payload = {
            payload: {
              clients
            }
          }
        }

        isLoading = false
        context.dispatch({ type: val === 0? 'SET_STATE': 'SET_PROJECT_CLIENT_DATA', ...payload })
      })
    // }
  }, [value]);

  function getCurrentValue () {
    if (props.location.pathname.toLowerCase().split('/', 3).join('/') === '/dashboard/analysis')
      return 0
    else 
      return 1
  }

  useEffect(() => {
    (async () => {
      if (!initialLoad) {
        if (context.projects.length !== 0) {
          if (context.projects[0].id !== 0) {
            initialLoad = true
        
            await client.query({ query: STATUS_QUERY(context.selectedFilter) }).then(({ data }) => {
              const { statusCount } = data;
              
              context.dispatch({ type: 'SET_STATUS_DATA', status: statusCount })
            })
            handleLoadTable(context.selectedFilter, activeTab, 15, 'filter');
          }
        } else {
          context.dispatch({ type: 'SET_STATUS_DATA', isEmptyProject: true })
        }
      }
    })();
  }, [context.selectedFilter])

  function handleLoadTable(filter, tab, offset, loc) {
    setLoadTableChange([filter, tab, offset, loc])
  }

  return (
    <Page 
      title="Dashboard" 
      {...customTabs}
      components={
        value === 1 &&
        <Filter activeTab={activeTab} 
          loadTable={handleLoadTable} 
          projects={context.projects} 
          clients={context.clients} 
          selectedFilter={context.selectedFilter} 
          dispatch={context.dispatch} 
        />
      }
    >
      <br />
      <Switch>
          <Route path="/dashboard/analysis" render={ () => <DashboardAnalysis context={{ translation: context.translation, locale: context.locale, temp_projects: project, projects: context.projects, clients: context.clients, dispatch: context.dispatch, params: props.match.params, history: props.history }} />} />
          <Route path="/dashboard/workplace" render={ () => <DashboardWorkplace selectedFilter={context.selectedFilter} status={context.status} user={user} context={{ translation: context.translation, locale: context.locale, dis: context.dispatch, projects: context.projects, fetchingPoints: context.fetchingPoints }} tabs={{ activeTab, setActiveTab }} loadTableChange={loadTableChange} />} />
      </Switch>
    </Page>
  )
})