
import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom'
import { message } from 'antd'
import ModalForm, { saveProject } from './FeedbackFormModalForm';
import { Modal, Toast } from '../../../common';
import { Modal as Modaly, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

import { ApolloContext } from 'react-apollo';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { AuthContext } from '../../../../contexts/AuthContext';
import { CREATE_FEEDBACK_POINTS, DELETE_POINT, UPSERT_POINT, FEEDBACKS_QUERY, DELETE_FEEDBACK } from '../../../../queries';
import axios from 'axios'

export default withRouter(({ state, dispatch, history, match }) => {
  const context = useContext(ApolloContext);
  const { user } = useContext(AuthContext)
  const global = useContext(GlobalContext)


  /* MODAL OK METHOD */
  const confirmDelete = async (e) => {
    e.preventDefault();
    try{
      if (state.deleteType === 'point') {
        state.selected.sort((a, b) => a - b);

        if (!state.isNew) {
          const { data } = await context.client.mutate({ mutation: DELETE_POINT, variables: { ids: state.selected } });
    
          dispatch({ type: 'SET_STATE', payload: { 
            points: state.points.filter(e => {
              let isMatch = false
    
              data.deletePoint.forEach(es => {
                if (e.id == es.id)
                  isMatch = true
              })
              return !isMatch
            }),
            selected: []
          }})
        } else {
          dispatch({ type: 'SET_STATE', payload: { 
            points: state.points.filter(e => {
              let isMatch = false
    
              state.selected.forEach(es => {
                if (e.id == es) 
                  isMatch = true
              })
              return !isMatch
            }),
            selected: []
          }})
        }
          
        Toast.showToast('success', global.translation.msg_point_deleted[global.locale])
      
      } else {
        const { data } = await context.client.mutate({ mutation: DELETE_FEEDBACK, variables: { data: { id: state.id } } })
        history.push('/feedbacks')
        Toast.showToast('success', `Feedback "${data.deleteFeedback.title}" ${global.translation.msg_success_remove[global.locale]}!`)
      }
    } catch (error) {
      const err = error.toString().split(':')
      console.log(err);
      Toast.showToast('error', `${err[3]}`)
    }
    
    return true;
  }

  const confirmUpdate = (e) => saveProject(e, 'update', state, dispatch, global)
  const confirmCreate = (e) => saveProject(e, 'create', state, dispatch, global)
  const saveChanges = e => async isNew => {
    let files = [];
    
    const hide = message.loading(global.translation.saving_changes[global.locale], 0)
    try {
      dispatch({ type: 'SET_STATE', payload: { loading: true } })

      let points = (state.isFeedbackList == 'feedback')? [...state.feedbacks] : [...state.points]
      let updatedData = {}  // Holds feedback or points

      const { data } = await context.client.mutate({ mutation: UPSERT_POINT, variables: { data: state.payloadPoints, feedback: { id: state.id, title: state.title, proj_id: state.project.id, project_role: state.project_role } } });
      const loadURI = `/project_${state.project.id}/feedback_${data.upsertPoint.feedback_id}`

      /* Remove files form system file */
      let deletedFiles = { files: state.dupFiles.map(fileItem => { 
        fileItem.setMetadata('points', `point_${data.upsertPoint.point_nr}_${data.upsertPoint.id}`)
        fileItem.setMetadata('feedback', `feedback_${data.upsertPoint.feedback_id}`)
        files = files.concat(fileItem)
        return fileItem.file
      }) }

      if (state.removefiles.length !== 0) {
        await axios.delete(global.uri + loadURI, {data: { files: state.removefiles, point: {id: state.payloadPoints[0].id, point_nr: state.payloadPoints[0].point_nr} }}, { headers: { 'Content-Type': 'application/json;charset=utf-8', accept: 'application/json;charset=utf-8' } })
        deletedFiles = { files: state.dupFiles.filter(e => e.file.name !== state.removefiles.filename).map(fileItem => { 
          fileItem.setMetadata('points', `point_${data.upsertPoint.point_nr}_${data.upsertPoint.id}`)
          fileItem.setMetadata('feedback', `feedback_${data.upsertPoint.feedback_id}`)

          return fileItem.file
        }) }
      }
      

      if (state.payloadPoints[0].id > 0) {
        points = state.isFeedbackList == 'feedback'? 
          points.map(e => e.id == data.upsertPoint.feedback_id? // FEEDBACKS
            {
              ...e,
              versions: e.versions.map(version => version.id == data.upsertPoint.version.split('|')[0]? 
                {
                  ...version,
                  points: version.points.map(point => point.id == data.upsertPoint.id? {...data.upsertPoint, ...deletedFiles}: {...point})
                }: {...version})
            }: {...e}):
          points.map(e => e.id === state.payloadPoints[0].id? // POINTS
            {
              ...data.upsertPoint, 
              ...deletedFiles
            }: {...e})
      } else {
        points.push({...data.upsertPoint, date: data.upsertPoint.date === 'Invalid Date'? new Date().toString(): data.upsertPoint.date })
      }
      
      if (state.isFeedbackList == 'feedback') 
        updatedData = { feedbacks: [...points] } // Feedbacks
      else 
        updatedData = { points } // Points
      
      /* If no files where added, close modal after save */
      hide()
      let noFileUpload = { editPoint: false }
      if ((state.removefiles.length !== 0 && state.files.length < state.rowData.files.length) || (state.removefiles.length === 0 && state.files.length === state.rowData.files.length))  {
        let unassignedPoints = {}, pointy = points.filter(e => state.isFeedbackList != 'dashboard'? true : e.team.length == 0 ? true : false)
        /* Reduce Notif Count on dashboard   */
        if (state.isFeedbackList == 'dashboard' && pointy.length < points.length)
        unassignedPoints = { unassignedPoints: parseInt(state.unassignedPoints) - parseInt(1) }
        
        
        noFileUpload = { editPoint: false, points: pointy, ...unassignedPoints }
        message.success(`${state.modalType === 'create'? global.translation.msg_point_success[global.locale]: global.translation.msg_point_updated[global.locale]}`)
      }

      if (state.isNew && state.files.length === 0) {
        history.push(`/feedback/view/${data.upsertPoint.feedback_id}/${data.upsertPoint.status}`)
      } else if (state.isNew && state.files.length !== 0) {
        // global.dispatch({ type: 'SET_STATE', payload: { uploadFiles: true, files, fileName: `/project_${data.upsertPoint.proj_id}/feedback_${data.upsertPoint.feedback_id}` } })
        // history.push(`/feedback/view/${data.upsertPoint.feedback_id}/${data.upsertPoint.status}`)
        noFileUpload = {...noFileUpload, newFeedbackFileUpload: true}
      } else if (!state.isNew && (state.editedItem.id == null || state.editedItem.id <= 0)) {
        noFileUpload = {...noFileUpload, newFeedbackFileUpload: true}
      }

      dispatch({ type: 'SET_STATE', payload: { ...updatedData, modalType: 'update', confirmModal: !state.confirmModal, editedItem: {...state.editedItem, priority: data.upsertPoint.priority, id: data.upsertPoint.id, point_nr: data.upsertPoint.point_nr, feedback_id: data.upsertPoint.feedback_id, instructions: data.upsertPoint.instructions}, rowData: { editedItem: {...state.editedItem, instructions: data.upsertPoint.instructions}, files: (state.removefiles.length !== 0)? deletedFiles.files: state.rowData.files}, uploadNewFiles: false, loadURI, ...noFileUpload, ...deletedFiles, loading: false } })
    } catch (error) {
      dispatch({ type: 'SET_STATE', payload: { loading: false,  uploading: false } })

      hide()
      console.log(error)
      Toast.showToast('error', `${global.translation.error_point_failed[global.locale]}!`)
    }
  }

  const confirmSave = async (e) => {
    // if (state.isNew) {
    //   e.preventDefault()
    //   let fileExist = false
    //   let files = []
    //   let points = []

    //   const hide = message.loading(global.translation.saving_changes[global.locale], 0)
    //   try {
    //     dispatch({ type: 'SET_STATE', payload: { loading: true } })

    //     const { data } = await context.client.mutate({ 
    //       mutation: CREATE_FEEDBACK_POINTS, 
    //       variables: { data: state.points, feedback: {title: state.title, proj_id: state.project.id, proj_name: state.project.name}, lang: global.locale },
    //       // update: async (cache, { data }) => {
    //         // await state.points.forEach(async point => {
    //         //   try {
    //         //     let res = await cache.readQuery({ query: FEEDBACKS_QUERY(state.filter, point.status) });
    //         //     console.log(point.status)
    //         //     if (res) {
    //         //       if (!res.feedbacks.find(feedback => feedback.id === data.id)) {
    //         //         res.feedbacks.push(data.createFeedbackPoints);
    //         //       }
    //         //     } else {
    //         //       res.feedbacks = [data.createFeedbackPoints];
    //         //     }
    //         //     await cache.writeQuery({
    //         //       query: FEEDBACKS_QUERY(state.filter, 'Open'),
    //         //       data: { feedbacks: res.feedbacks }
    //         //     });
    //         //   } catch (e) {
    //         //     console.log(e)
    //         //       // We should always catch here,
    //         //       // as the cache may be empty or the query may fail
    //         //   }
    //         // })
    //       // }
    //     });
    //     dispatch({ type: 'SET_STATE', payload: { loading: false } })
    //     hide()

    //     state.points.forEach((e, i) => {
    //       if (e.files.length !== 0) {
    //         fileExist = true

    //         const file = e.dupFiles.map(e => {
    //           e.setMetadata('points', `point_${data.createFeedbackPoints.points[i].point_nr}_${data.createFeedbackPoints.points[i].id}`)
    //           return e
    //         })

    //         files = files.concat(file)
    //       }
    //     })

    //     if (fileExist) 
    //       global.dispatch({ type: 'SET_STATE', payload: { uploadFiles: true, files, fileName: `/project_${data.createFeedbackPoints.proj_id}/feedback_${data.createFeedbackPoints.id}`, point_no: points } })

    //     if (!fileExist) {
    //       message.success(`${global.translation.msg_feedback_added[global.locale]}!`)
    //       // Toast.showToast('success', `${global.translation.msg_feedback_added[global.locale]}!`)
    //       history.push('/feedbacks/Open')
    //     }

    //     return true
    //   } catch (error) {
    //     hide()
    //     console.log(error)
    //     error.message = error.message.replace('GraphQL error: ', '')
    //     console.log(error.message)
    //     dispatch({ type: 'setErrors', payload: JSON.parse(error.message) })
    //     return false
    //   }
    // } else {
      saveChanges(!!state.isNew)
    // }
  }

  const modalSettings = {
    method: {
      delete: confirmDelete,
      create: confirmCreate,
      update: confirmUpdate,
      save: confirmSave,
      prompt: (e) => {e.preventDefault(); return true;}
    },
    title: {
      delete: state.deleteType === 'point' ? global.translation.remove_point[global.locale]: global.translation.remove_feedback[global.locale],
      create: global.translation.add_point[global.locale],
      update: (state.editedItem.id > 0)? 
        ( 
          <>
            {`Point #${state.editedItem.point_nr}`} {' '}
            {/* <Tooltip style={{display: (!state.editPoint && user.first_name + ' ' + user.last_name === state.editedItem.creator)? 'initial': 'none' }} title="Edit">
              <IconButton id="edit-feedback-title" aria-label="Edit"  onClick={() => dispatch({ type: 'SET_STATE', payload: { editPoint: true } })}>
                <EditIcon/>
              </IconButton>
            </Tooltip> */}
            {/* {
              state.editPoint &&
                <small style={{ color: 'gray' }}>(Edit)</small>
            } */}
          </>
        )
          : global.translation.edit_point[global.locale],
      save: global.translation.save_changes[global.locale],
      prompt: (state.title !== '')? global.translation.empty_points[global.locale]: global.translation.empty_title[global.locale]
    },
    body: {
      delete: `${state.deleteType === 'point'? global.translation.msg_delete_point[global.locale]: global.translation.msg_delete_feedback[global.locale]}?`,
      create: <ModalForm state={state} dispatch={dispatch} history={history}/>,
      update: <ModalForm state={state} dispatch={dispatch} history={history}/>,
      save: `${global.translation.msg_save_changes1[global.locale]} '${state.title}'`,
      prompt: (state.title !== '')? global.translation.msg_add_one_point[global.locale] : global.translation.msg_write_feedback[global.locale]
    }
  }
  /* MODAL OK METHOD END */

  return (
    <>
      <Modal 
        saveLabel={(state.modalType === 'delete' && global.translation.proceed[global.locale])}
        title={modalSettings.title[state.modalType]} 
        width={((!state.isSubmitFeedbackForm) ? (state.modalType !== 'save' && state.modalType !== 'prompt' && state.modalType !== 'delete')? "1000px": '500px' : '450px')} 
        modal={state.modal} 
        setModal={(e) => {dispatch({ type: 'SET_STATE', payload: { modal: e } }) }} 
        cancelFunction={(!!state.rowData.editedItem) ? false:  { editedItem: {...state.editedItem}, rowData: {...state.rowData} } } 
        accessFunction={modalSettings.method[state.modalType]} 
        uploading={state.uploading}
        hideActions={(state.editedItem.id > 0 && !state.editPoint)}
        cancelLabel={state.modalType === 'prompt' && 'Ok'}
        autoFocus={state.modalType === 'delete' || state.modalType === 'prompt' || state.modalType === 'save'}
        disable={state.loading || (state.editedItem.time_estimate.trim().length === 0 || state.editedItem.time_estimate.trim() === ':'? false : state.editedItem.time_estimate.trim().length !== 5 ? true : (state.editedItem.time_estimate.trim().replace(':', '').split('').every(num => num !== ' ') ? false : true))}
      >
        {modalSettings.body[state.modalType]}
      </Modal>

      <Modaly isOpen={state.confirmModal} toggle={() => dispatch({ type: 'SET_STATE', payload: { confirmModal: !state.confirmModal } })}>
        <ModalHeader toggle={() => dispatch({ type: 'SET_STATE', payload: { confirmModal: !state.confirmModal } })}>{ global.translation.save_changes[global.locale] }</ModalHeader>
        <ModalBody>
          { global.translation.msg_save_changes[global.locale] }
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => dispatch({ type: 'SET_STATE', payload: { confirmModal: !state.confirmModal } })}>{ global.translation.cancel[global.locale] }</Button>{' '}
          <Button disabled={state.loading} id="ok_modal" color="primary" onClick={saveChanges(false)}>{ global.translation.save[global.locale] }</Button>
        </ModalFooter>
      </Modaly>
    </>
  )
})