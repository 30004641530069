
import React, { useContext } from 'react';
import ModalForm, { saveProject } from './ProjectModalForm';
import { message } from 'antd'
import { Modal } from '../../common';

import { ApolloContext } from 'react-apollo';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { DELETE_PROJECT } from '../../../queries';


export default ({ state, dispatch, clearState, updateProject, createTable, initialErrorState }) => {
  const context = useContext(ApolloContext);
  const { translation, locale, dispatch: condis, currentStep } = useContext(GlobalContext)

  /* MODAL OK METHOD */
  const confirmDelete = async (e) => {
    e.preventDefault();
    state.selected.sort((a, b) => a - b);

    const hide = message.loading(translation.saving_changes[locale], 0)
    try{
      const { data } = await context.client.mutate({ mutation: DELETE_PROJECT, variables: { ids: state.selected } });
      if (data.deleteProject.length !== 0) {
        data.deleteProject.forEach(e => {
          if (e.status === 'Open')
            state.statusCount.open_projects--
          else 
            state.statusCount.closed_projects--

          state.statusCount.total_projects--
        })
      }

      dispatch({ type: 'INITIAL_STATE', 
        payload: {
          projects: state.projects.filter(x => !state.selected.includes(x.id)),
          projectsTable: state.projectsTable.filter(x => !state.selected.includes(x.id)),
          statusCount: { ...state.statusCount },
          selected: []
        }
      })
      // Toast.showToast('success', translation.msg_project_success[locale])
      hide()
      message.success(translation.msg_project_success[locale])
    } catch (error) {
      const err = error.toString().split(':')
      console.log(err);
      // Toast.showToast('error', `${err[3]}`)
      hide()
      message.success(`${err[3]}`)
    }
    
    return true;
  }

  const confirmUpdate = (e) => saveProject(e, 'update', state, dispatch, clearState, context, initialErrorState, updateProject, createTable, translation, locale)
  const confirmCreate = (e) => saveProject(e, 'create', state, dispatch, clearState, context, initialErrorState, updateProject, createTable, translation, locale)


  const modalSettings = {
    method: {
      delete: confirmDelete,
      create: confirmCreate,
      update: confirmUpdate,
      warning: (e) => {
        state.confirmWarning();
        return true;
      }
    },
    title: {
      delete: translation.delete_project[locale],
      create: translation.add_project[locale],
      update: translation.edit_project[locale],
      warning: translation.close_project[locale]
    },
    body: {
      delete: translation.msg_delete_project[locale],
      create: <ModalForm translation={translation} locale={locale} condis={condis} currentStep={currentStep} state={state} dispatch={dispatch} initialErrorState={initialErrorState}/>,
      update: <ModalForm translation={translation} locale={locale} condis={condis} currentStep={currentStep} state={state} dispatch={dispatch} initialErrorState={initialErrorState}/>,
      warning: translation.msg_proceed[locale]
    }
  }
  /* MODAL OK METHOD END */
  return (
    <Modal 
      location={14} 
      saveLabel={(['delete'].includes(state.modalType) && translation.proceed[locale])} 
      isHide={state.modalType === 'warning'} 
      cancelLabel={state.modalType === 'warning' && translation.cancel[locale]} 
      title={modalSettings.title[state.modalType]} 
      modal={state.modal} 
      setModal={(e) => { dispatch({ type: 'setModal', payload: e }) }} 
      accessFunction={modalSettings.method[state.modalType]}
    >
        {modalSettings.body[state.modalType]}
    </Modal>
  )
}