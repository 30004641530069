import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'input-moment/dist/input-moment.css'
import 'font-awesome/css/font-awesome.css';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import './css/css/ionicons.min.css'
import 'antd/dist/antd.css'; 
import './index.css';
import App from './App';
import { message } from 'antd'
// import * as serviceWorker from './serviceWorker'
import { GlobalProvider } from './contexts/GlobalContext';

/* Import Apollo */
import ApolloClient from 'apollo-client';
import { createUploadLink } from "apollo-upload-client";
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from "apollo-link-error";
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import { ApolloLink, split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css';

const err_msg = {
  'de': 'Ihre Sitzung ist abgelaufen.',
  'en': 'Your session has been expired.'
}
let counter = 0
// require('dotenv').config()
// get the authentication token from local storage if it exists
const uri = (process.env.NODE_ENV === 'development')? 'http://localhost:4001/graphql': '/graphql'
const uris = (process.env.NODE_ENV === 'development')? 'ws://localhost:4001/graphql': '/graphql' 
// exact-main
// const uri = 'http://localhost:4001/graphql'
// const uris = 'ws://localhost:4001/graphql'
// const httpLink = new HttpLink({
//   uri
//     // uri: 'http://exactplacev2.exactconstruct.synology.me/graphql'
//     // uri: 'http://localhost:3001/graphql'
//     // uri: 'http://exactconstruct.synology.me:8084/graphql'
//     // uri: 'https://still-eyrie-16728.herokuapp.com/graphql'
// });

const httpLink = createUploadLink({
  uri: uri,
});

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri: uris,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async () => ({
      authorization: localStorage.getItem('techVoiceToken') || '',
    })
  } 
});


const authLink = setContext((_, { headers }) => {
  // Return the headers to the context so httpLink can read them
  const token = localStorage.getItem('techVoiceToken');

  return {
    headers: {
      ...headers,
      authorization: token || "",
    }
  }
});

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (!!networkError) {
    if (networkError.statusCode === 500 && graphQLErrors[0].message === 'Context creation failed: TokenExpiredError: jwt expired' && counter === 0) {
      const locale = (localStorage.getItem('locale') === null || localStorage.getItem('locale') === undefined)? 'de' : localStorage.getItem('locale')
      message.warning(err_msg[locale])
  
      localStorage.clear()
      
      if (window.location.pathname !== '/') {
        counter++
        setTimeout(() => {
          window.location = '/' 
        }, 2000);
      }
    }
  }
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const terminatingLink = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return (kind === 'OperationDefinition' && operation === 'subscription');
  },
  wsLink,
  errorLink.concat(authLink.concat(httpLink)),
);

const link = ApolloLink.from([terminatingLink]);
  
const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  queryDeduplication: false,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <ApolloHooksProvider client={client}>
      <GlobalProvider>
        <App />   
      </GlobalProvider>
    </ApolloHooksProvider>
  </ApolloProvider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
