import React, { useState, useContext, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Badge } from 'reactstrap';
import Button from '@material-ui/core/Button';
// import { makeStyles } from '@material-ui/core/styles';
import { GlobalContext } from '../../contexts/GlobalContext'
import { AuthContext } from '../../contexts/AuthContext'

export default () => {
  const [modal, setModal] = useState(false)
  const { translation, locale, dispatch, feature_modal } = useContext(GlobalContext)
  const { user } = useContext(AuthContext)

  const toggle = () => dispatch({ type: 'SET_STATE', payload: { feature_modal: !feature_modal } })

  useEffect(() => {
    if (user)
      dispatch({ type: 'SET_STATE', payload: { feature_modal: user.update_seen === 0 } })
  }, [user])

  return (
    <>
      {/* <h4 id="development-sign" onClick={toggle}><span id="first-sign">{translation.development[locale]}</span><span id="second-sign">{translation.whats_new[locale]}</span></h4> */}
      <div>
        <Modal isOpen={feature_modal} toggle={toggle}>
          <ModalHeader toggle={toggle} style={{ borderBottom: 'none', position: 'absolute', right: 0, zIndex: 1 }}></ModalHeader>
          <ModalBody>
            <h4 style={{ marginTop: '5px' }}>{translation.whats_new[locale]}</h4>
            <h5 style={{ marginBottom: '10px', display: 'inline-block' }}>November 06, 2020</h5> <Badge color="danger" style={{ verticalAlign: 'text-top' }}>NEW</Badge><br />

            <strong>Issues Fixed / Changes:</strong><br />
            <ul style={{ marginLeft: '18px' }}>
              <li>Fixed developer task info table (dashboard -> staff -> developer)</li>
              <li>Feedbacks table can now search by point instructions</li>
              <li>Added new column on dashboard "Last changed by"</li>
              <li>Open request tab added on dashboard (for PLs)</li>
              <li>[Fixed] Point counter on closed points tab (dashboard)</li>
            </ul>
            <hr /> 
            {/* ================== END ================== */}
            <h5 style={{ marginBottom: '10px' }}>September 30, 2020</h5> 

            <strong>Issues Fixed / Changes:</strong><br />
            <ul style={{ marginLeft: '18px' }}>
              <li>Estimated time per point and what's new message is hidden to client account type.</li>
              <li>Reopening points will be reassigned to the user who last worked on it.</li>
              <li>[Fixed] Copy and paste issue on comment box</li>
              <li>[Fixed] File upload</li>
              <li>Number of open points should be the same on the actual open points on dashboard</li>
              <li>Project team will see by default the Internal Comment, Clients see by default the (Client) Comment</li>
            </ul>
            <hr /> 
            {/* ================== END ================== */}
            <h5 style={{ marginBottom: '10px' }}>July 16, 2020</h5>

            <strong>Issues Fixed / Changes:</strong><br />
            <ul style={{ marginLeft: '18px' }}>
              <li>Date update points should update when trying to add comments and logging in time</li>
              <li>Hide the closed projects from the "Project Priority" list</li>
              <li>Timelog export fix</li>
              <li>Hours worked calculation fixed (Dashboard -> Staff)</li>
              <li>Red flag on dashboard staff changed to whole row, for the specific point, red text on date updated.</li>
            </ul>
            <hr /> 
            {/* ================== END ================== */}
            <h5 style={{ marginBottom: '10px' }}>March 10, 2020</h5>

            <strong>Issues Fixed / Changes:</strong><br />
            <ul style={{ marginLeft: '18px' }}>
              <li>Image uplaod issue fixed</li> 
              <li>Filtered client accounts upon assigning users on a project</li> 
              <li>Hovering timelogs on summary, displays breakdown of log details</li> 
              <li>New project status added: Archive (Not visible to clients)</li> 
              <li>Only closed projects can set to archive. (Open -> close -> archive)</li> 
              <li>New line issue fixed on instructions / comment field</li> 
              <li>Time estimate is added on viewing points (Dashboard analysis -> assigned tasks -> open points/points in question</li> 
              <li>Clickable clieont/project on dashboard analysis</li>
            </ul>
            {/* ================== END ================== */}

            <hr /> 
            <h5 style={{ marginBottom: '10px'}}>February 14, 2020</h5> 

            <strong>Issues Fixed / Changes:</strong><br />
            <ul style={{ marginLeft: '18px' }}>
              <li>Upload issue</li>
              <li>Max file size upload is set to 30 MB</li> 
              <li>Maximum time estimation is set to 8 hours</li> 
            </ul>
            {/* ================== END ================== */}

            <hr /> 
            <h5 style={{ marginBottom: '10px'}}>January 27, 2020</h5>

            <strong>Added Feature:</strong><br />
            <ul style={{ marginLeft: '18px', marginBottom: 0 }}>
              <li>Delete confirmation on uploaded attachments</li>
            </ul>

            <strong>Changes:</strong><br />
            <ul style={{ marginLeft: '18px' }}>
              <li>Shows info on points upon clicking a point on notifications</li>
              <li>Adding of new feedback automatically saves after adding 1 point.</li> 
            </ul>
            {/* ================== END ================== */}

            <hr /> 
            <h5 style={{ marginBottom: '10px' }}>January 17, 2020</h5>

            <strong>Added Feature:</strong><br />
            <ul style={{ marginLeft: '18px', marginBottom: 0 }}>
              <li>Added notification bell (BETA)</li>
            </ul>

            <strong>Issues Fixed:</strong><br />
            <ul style={{ marginLeft: '18px' }}>
              <li>Lazy loading implemented on dashboard workplace</li> 
            </ul>
            {/* ================== END ================== */}

            <hr /> 
            <h5 style={{ marginBottom: '10px' }}>January 10, 2020</h5>

            <strong>Added Feature:</strong><br />
            <ul style={{ marginLeft: '18px', marginBottom: 0 }}>
              <li>Users can now view points under assigned tasks in dashboard analysis</li>
            </ul>

            <strong>Issues Fixed:</strong><br />
            <ul style={{ marginLeft: '18px' }}>
              <li>Doesn't display all assigned users on a point (Dasbhoard).</li> 
              <li>Closed projects are still visible.</li> 
            </ul>
            {/* ================== END ================== */}

            <hr /> 
            <h5 style={{ marginBottom: '10px' }}>December 19, 2019</h5> 

            <strong>Added Feature:</strong><br />
            <ul style={{ marginLeft: '18px', marginBottom: 0 }}>
              <li>Push Notifications: (Allow notifications on exactplace for the feature to work)</li>
              <li>Users will get notified if: </li>
              <ul>
                <li>1. New feedback is added in a project that he/she is assigned.</li>
                <li>2. User is assigned to a point</li>
                <li>3. Any user commented on a point that he/she is assigned to it.</li>
                <li>4. User is mentioned on a point whether he/she is assigned to that point or not. (Example of mention: @_name_of_user_)</li>
              </ul>
            </ul>

            <strong>Issues Fixed / Changes:</strong><br />
            <ul style={{ marginLeft: '18px' }}>
              <li>Added client name on feedback list for every feedback and also at the timelogs page</li> 
              <li>Added loading state upon adding feedback / points</li> 
            </ul>
            {/* ================== END ================== */}

            <hr /> 
            <h5 style={{ marginBottom: '10px' }}>December 10, 2019</h5> 

            <strong>Added Feature:</strong><br />
            <ul style={{ marginLeft: '18px', marginBottom: 0 }}>
              <li>Capacity Calendar</li>
              <li>Setting of project priorities on projects page</li>
            </ul>

            <strong>Issues Fixed / Changes:</strong><br />
            <ul style={{ marginLeft: '18px' }}>
              <li>Timelog total hours fixed </li> 
              <li>Can't detect project leader upon adding projects</li> 
            </ul>
            {/* ================== END ================== */}

            <hr /> 
            <h5 style={{ marginBottom: '10px' }}>December 2, 2019</h5> 

            <strong>Added Feature:</strong><br />
            <ul style={{ marginLeft: '18px', marginBottom: 0 }}>
              <li>Users can now download files from uploaded attachments</li>
            </ul>

            <strong>Issues Fixed / Changes:</strong><br />
            <ul style={{ marginLeft: '18px' }}>
              <li>Popup confirmation will show when trying to delete a version on a project.</li> 
              <li>Moving to dashboard/feedbacklist after removing self on a project</li> 
              <li>Cannot select project if name has "|" upon adding new feedback</li> 
              <li>Time log validation and incorrect total worked hours</li> 
              <li>Users can now view hours worked on points</li> 
              <li>Doesn't show error upon adding a project with missing fields</li> 
              <li>Administrator accounts can only delete projects/clients</li> 
              <li>Point number on timelogs fixed</li> 
            </ul>
            {/* ================== END ================== */}

            <hr /> 
            <h5 style={{ marginBottom: '10px' }}>November 28, 2019</h5>

            <strong>Added Feature:</strong><br />
            <ul style={{ marginLeft: '18px', marginBottom: 0 }}>
              <li>Users can now mention someone on the comments</li>
            </ul>

            <strong>Changes:</strong><br />
            <ul style={{ marginLeft: '18px' }}>
              <li>Added "All" filter on projects</li> 
            </ul>
            {/* ================== END ================== */}

            <hr /> 
            <h5 style={{ marginBottom: '10px' }}>November 25, 2019</h5>

            <strong>Added Features:</strong><br />
            <ul style={{ marginLeft: '18px', marginBottom: 0 }}>
              <li>Setting active status on points (means point/task in progress when active)</li>
              <li>Timelog feature with summary view</li>
              <li>Dashboard analysis (Daily report)</li>
            </ul>

            <strong>Changes:</strong><br />
            <ul style={{ marginLeft: '18px' }}>
              <li>Added popup message when trying to add a feedback that he/she is not assigned to.</li> 
              <li>Comment box can now add new line.</li> 
              <li>User can delete his/her own comment.</li> 
              <li>Added 'Client' user type on accounts level.</li> 
            </ul>
            {/* ================== END ================== */}

            <hr /> 
            <h5 style={{ marginBottom: '10px' }}>October 29, 2019</h5>

            <strong>Added Feature:</strong><br />
            <ul style={{ marginLeft: '18px', marginBottom: 0 }}>
              <li>Assign point to self (Project Staff)</li>
            </ul>

            <strong>Issues Fixed:</strong><br />
            <ul style={{ marginLeft: '18px' }}>
              <li>Feedback Provider can update project info</li> 
              <li>Cannot add new points in the existing feedback. the modal confirmation is empty. No text and button.</li> 
              <li>The version is not updated at the modal form after changing it.</li> 
              <li>The number of unassigned points is not automatically updated after assigning it to a user. Only when reloading the page.</li>
            </ul>
             {/* ================== END ================== */}

            <hr />
            <h5 style={{ marginBottom: '10px' }}>October 25, 2019</h5>

            <strong>Added Feature:</strong><br />
            <ul style={{ marginLeft: '18px', marginBottom: 0 }}>
              <li>Real-time data</li>
              <li>Feedback List data fetching speed optimized</li>
            </ul>

            <strong>Issues Fixed:</strong><br />
            <ul style={{ marginLeft: '18px' }}>
              <li>The number of unassigned points is not automatically updated after assigning it to a user. Only when reloading the page.</li> 
              <li>Adding points to existing feedback</li> 
              <li>Updating deadline & version issue</li>
            </ul>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}