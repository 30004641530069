import React, { useEffect, useContext } from 'react';
import { Spinner2, Empty, Users } from '../../common';
import { withRouter } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory, {
	PaginationProvider,
	PaginationListStandalone,
	SizePerPageDropdownStandalone,
	PaginationTotalStandalone,
} from 'react-bootstrap-table2-paginator';
import 'abortcontroller-polyfill';

import VersionList from './VersionList';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { ApolloContext } from 'react-apollo';
import { FEEDBACKS_QUERY } from '../../../queries';

const { SearchBar } = Search;
let nameFilter;
let timeout_id = 0;
let currentPage = 0;

const RemoteAll = ({
	data,
	options,
	onTableChange,
	expandRow,
	state,
	context,
	paginationProps,
	paginationTableProps,
}) => (
	<div>
		<ToolkitProvider
			keyField='id'
			columns={data.columns}
			data={data.feedbacks}
			bootstrap4
			search
		>
			{(toolkitprops) => (
				<div>
					<PaginationListStandalone {...paginationProps} />

					<SearchBar
						placeholder={context.translation.search[context.locale]}
						{...toolkitprops.searchProps}
					/>

					<BootstrapTable
						{...toolkitprops.baseProps}
						{...paginationTableProps}
						noDataIndication={() =>
							state.loading || state.tableLoading ? <Spinner2 /> : <Empty />
						}

						expandRow={expandRow}
						loading={state.loading}
						bordered={false}
						remote={{ search: true, pagination: true }}
						onTableChange={onTableChange}
						// hover
					/>
				</div>
			)}
		</ToolkitProvider>
		<SizePerPageDropdownStandalone {...paginationProps} />
		<PaginationTotalStandalone {...paginationProps} />
		<span style={{ float: 'right' }}>
			<PaginationListStandalone {...paginationProps} />
		</span>
	</div>
);

export default withRouter((props) => {
	const { state, dispatch, feedbacks, loadTable } = props.reducer;
	const { priority, setPriority } = props;
	// const { SearchBar } = Search;
	console.log(props)
	const context = useContext(GlobalContext);
	const { user } = useContext(AuthContext);
	const { client } = useContext(ApolloContext);
	let abortController = new AbortController();
	console.log(props)
	/* TABLE SETTINGS */
	const columns = [
		{
			dataField: 'title',
			text: 'ID',
			style: {
				fontWeight: 600,
				paddingBottom: 0,
				// color: '#007BFF'
			},
			headerStyle: (colum, colIndex) => ({ width: '45px' }),
			attrs: { colSpan: 2 },
			// sort: true,
			// hidden: true,
			// }, {
			//   dataField: 'proj_name', //Display Project name
			//   text: 'Instructions',
			//   headerStyle: (colum, colIndex) => ({ width: '20%'}),
			//   // style: {
			//   //   fontWeight: 600
			//   // },
			//   // sort: true
		},
		{
			dataField: 'titles',
			text: context.translation.instruction[context.locale],
			headerStyle: (colum, colIndex) => ({ width: '40%' }),
			// sort: true
		},
		{
			dataField: 'status',
			text: 'Status',
			headerAlign: 'center',
			// sort: true
		},
		// {
		// 	dataField: 'priority',
		// 	text: context.translation.priority[context.locale],
		// 	headerAlign: 'center',
		// 	headerFormatter: (...args) => priceFormatter(...args)(),
		// 	// sort: true
		// },
		{
			dataField: 'team',
			text: context.translation.assignd_to[context.locale],
			headerAlign: 'center',
			hidden: user.role === 'Client',
			// headerFormatter: (...args) => priceFormatter(...args)(),
			// formatter: (cell, row, rowIndex, formatExtraData) => (
			// 	{cell}
			// ),
			// sort: true
		},
		{
			dataField: 'es',
			text: context.translation.time_estimate[context.locale],
			headerAlign: 'center',
			hidden: user.role === 'Client',
			// sort: true
		},
		{
			dataField: 'es',
			text: context.translation.hours_worked[context.locale],
			headerAlign: 'center',
			hidden: user.role === 'Client',
			// sort: true
		},
		{
			dataField: 'datee',
			text: context.translation.update[context.locale],
			headerAlign: user.role === 'Client' ? 'left' : 'center',
			style: {
				display: 'none',
			},
			// sort: true
		},
		{
			dataField: 'versions',
			text: 'versions',
			hidden: true,
		},
		{
			dataField: 'id',
			text: 'versions',
			hidden: true,
		},
	];

	useEffect(() => {
		if (state.feedbacks.length !== 0) {
			let expand = state.feedbacks.map((e) => e.id);
			dispatch({ type: 'SET_STATE', payload: { expand } });
		}
	}, [state.feedbacks.length]);

	// useEffect(() => {
	// 	if (prevTab != activeTab) {
	// 		setColumns((prevState) => {
	// 			const s = [...prevState];
	// 			return s.map((e) => changeHiddenColumn(activeTab, e));
	// 		});
	// 		setPrevTab(activeTab);
	// 	}
	// }, [activeTab]);

	// function changeHiddenColumn(tab, e) {
	// 	const n = parseInt(tab);
	// 	const hidden = dynamicColumns[n][e.dataField];
	// 	const headerFormatter =
	// 		e.dataField === 'priority'
	// 			? { headerFormatter: (...args) => priceFormatter(...args)(n) }
	// 			: {};
	// 	return {
	// 		...e,
	// 		...headerFormatter,
	// 		hidden:
	// 			typeof hidden !== 'undefined'
	// 				? dynamicColumns[tab][e.dataField]
	// 				: e.dataField === 'id'
	// 				? true
	// 				: false,
	// 	};
	// }

	// function priceFormatter(column, colIndex) {
	// 	return function (tab) {
	// 		return (
	// 			<div>
	// 				{'Assigned To'}
	// 				<div>
	// 					<select
	// 						defaultValue='All'
	// 						value={priority}
	// 						onChange={(e) => {
	// 							setPriority(e.target.value);
	// 							loadTable(
	// 								{ ...context.selectedFilter, priority: e.target.value },
	// 								tab,
	// 								0,
	// 								true
	// 							);
	// 						}}
	// 					>
	// 						<option value='All'>
	// 							{Users}
	// 						</option>
	// 						<option value='Low'>
	// 							Low
	// 						</option>
	// 						<option value='Mid'>
	// 							Mid
	// 						</option>
	// 						<option value='High'>
	// 							High
	// 						</option>
	// 					</select>
	// 				</div>
	// 			</div>
	// 		);
	// 	};
	// }

	const customTotal = (from, to, size) => (
		<span
			className='react-bootstrap-table-pagination-total'
			style={{ margin: '8px', display: 'inline-block' }}
		>
			{' '}
			{context.translation.showing_rows[context.locale]} {from}{' '}
			{context.translation.to[context.locale]} {to}{' '}
			{context.translation.of[context.locale]} {size}{' '}
			{context.translation.results[context.locale]}
		</span>
	);

	const handleOnExpand = (row, isExpand, rowIndex, e) => {
		props.history.push(`/feedback/view/${row.id}/${props.match.params.status}`);
		if (isExpand)
			dispatch({ type: 'SET_STATE', payload: { expand: [...state.expand] } });
		else
			dispatch({ type: 'SET_STATE', payload: { expand: [...state.expand] } });
	};

	const options = {
		page: state.page,
		custom: true,
		paginationSize: 5,
		showTotal: true,
		paginationTotalRenderer: customTotal,
		totalSize: state.feedbackPageLength,
		sizePerPageList: [
			{
				text: '5',
				value: 5,
			},
		],
	};

	const expandRow = {
		renderer: (row) => (
			<VersionList
				reducer={{
					state,
					dispatch,
					versions: row.versions
						.filter((e) => e.points.length !== 0)
						.map((e, i) => {
							const version_name = (
								<React.Fragment>
									{i === 0 && (
										<>
											<span style={{ fontStyle: 'initial', display: 'block' }}>
												{context.translation.clien[context.locale] +
													': ' +
													row.clientname}
											</span>
											<span
												style={{
													fontStyle: 'initial',
													display: 'block',
													marginBottom: '-5px',
												}}
											>
												{context.translation.nav.project[context.locale] +
													': ' +
													row.proj_name}
											</span>
											<br />
										</>
									)}
									{/* Version: {e.version_name} */}
									{/* <Tooltip title="Open">
            <span> 
              <i className="fas fa-circle open-txt"></i>12
            </span>
          </Tooltip> */}
									{/* <Tooltip title="For Testing">
            <span> 
              <i className="fas fa-circle testing-txt"></i>12 
            </span>
          </Tooltip>
          <Tooltip title="Question">
            <span> 
              <i className="fas fa-circle question-txt"></i>12
            </span>
          </Tooltip>
          <Tooltip title="Postpone">
            <span> 
              <i className="fas fa-circle postpone-txt"></i>12
            </span>
          </Tooltip>
          <Tooltip title="Closed">
            <span> 
              <i className="fas fa-circle closed-txt"></i>12
            </span>
          </Tooltip> */}
								</React.Fragment>
							);
							return {
								...e,
								clientname: row.clientname,
								version_name,
								proj_id: row.proj_id,
								proj_name: row.proj_name,
								points: e.points.map((point) => ({ ...point, title: e.title })),
							};
						}),
				}}
			/>
		),
		// showExpandColumn: true,
		expanded: state.expand,
		onExpand: handleOnExpand,
	};

	const updateTable = (page, searchText, type) => {
		currentPage = type === 'page' ? (page - 1) * 5 : 0;
		client
			.query({
				query: FEEDBACKS_QUERY(
					context.selectedFilter,
					state.status.replace(/^\w/, (c) => c.toUpperCase()),
					currentPage,
					searchText
				),
			})
			.then(async ({ data }) => {
				if (currentPage === data.currentPage.page) {
					// TEMPORARY FIX, CANCELATION OF PREVIOUS HTTP REQUEST STILL IMPLEMENTING AS OF THIS TIME
					let prev =
						data.feedbacks.length === 5 && searchText === ''
							? { prevFeedbacks: data.feedbacks }
							: {};
					dispatch({
						type: 'SET_STATE',
						payload: {
							feedbacks: data.feedbacks,
							tableLoading: false,
							...prev,
							feedbackPageLength: data.feedbacksCount[0].totalCount,
						},
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleTableChange = async (type, { page, searchText }) => {
		dispatch({
			type: 'SET_STATE',
			payload: {
				tableLoading: true,
				feedbacks: [],
				offset: 20,
				page: state.page !== page ? page : 1,
				feedbackPageLength: state.page !== page ? state.feedackPageLength : 0,
			},
		});
		if (state.page !== page) {
			updateTable(page, searchText, 'page');
		} else {
			if (state.prevFeedbacks.length !== 0 && searchText === '') {
				clearTimeout(timeout_id);
				dispatch({
					type: 'SET_STATE',
					payload: {
						feedbacks: [...state.prevFeedbacks],
						tableLoading: false,
						feedbackPageLength: state.prevFeedbackPageLength,
					},
				});
			} else {
				clearTimeout(timeout_id);
				timeout_id = setTimeout(async () => {
					updateTable(page, searchText, 'text');

					// console.log(result)
				}, 2000);
			}
		}
	};

	const handlePagination = (e, i) => {
		[1, 2, 3, 4, 5].forEach((e, i) =>
			document.getElementsByClassName('page-item')[i].classList.remove('active')
		);

		e.currentTarget.classList.add('active');
		// dispatch({ type: 'SET_STATE', payload: { loading: true } })
		loadTable(context.selectedFilter, 0, i * 5);
	};

	// const contentTable = ({ paginationProps, paginationTableProps }) => (
	//   <div>
	//     <ToolkitProvider
	//       keyField="id"
	//       columns={ columns }
	//       data={ feedbacks }
	//       bootstrap4
	//       search
	//     >
	//       {
	//         toolkitprops => (
	//           <div>
	//             <PaginationListStandalone { ...paginationProps } />
	//             {/* <Spinner color="primary" size="sm" style={{ marginLeft: '10px', marginTop: '6px', display: (state.paginationLoading)? 'inline-block' : 'none' }} />  */}
	//             {/* <div>
	//               <ul className="pagination react-bootstrap-table-page-btns-ul">
	//                 {
	//                   [1,2,3,4,5].map((e,i) => (
	//                       <li key={i} className={`${i == 0? 'active' : ''} page-item`} title="1" onClick={(e) => handlePagination(e, i)}><a href="#" className="page-link">{i + 1}</a></li>
	//                   ))
	//                 }
	//                 <li className="page-item" title="next page"><a href="#" className="page-link">&gt;</a></li>
	//                 <li className="page-item" title="last page"><a href="#" className="page-link">&gt;&gt;</a></li>
	//               </ul>
	//             </div> */}
	//             <SearchBar placeholder={context.translation.search[context.locale]} { ...toolkitprops.searchProps } />
	//             <BootstrapTable
	//               { ...toolkitprops.baseProps }
	//               { ...paginationTableProps }
	//               noDataIndication = { () => state.loading || state.tableLoading? <Spinner2 />: <Empty /> }
	//               expandRow = { expandRow }
	//               loading={state.loading}
	//               bordered={ false }
	//               // hover
	//             />
	//           </div>
	//         )
	//       }
	//     </ToolkitProvider>
	//     <SizePerPageDropdownStandalone { ...paginationProps } />
	//     <PaginationTotalStandalone { ...paginationProps } />
	//     <span style={{ float: 'right' }}><PaginationListStandalone { ...paginationProps } /></span>
	//   </div>
	// );

	return (
		<div>
			<PaginationProvider pagination={paginationFactory(options)}>
				{(prups) => (
					<RemoteAll
						data={{ feedbacks, columns }}
						options={options}
						expandRow={expandRow}
						context={context}
						state={state}
						paginationProps={prups.paginationProps}
						paginationTableProps={prups.paginationTableProps}
						onTableChange={handleTableChange}
					/>
				)}
			</PaginationProvider>
		</div>
	);
});
