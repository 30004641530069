import React, { useContext, useEffect, useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import { FormGroup, Input, UncontrolledButtonDropdown, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import { Cascader } from 'antd';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { AuthContext } from '../../contexts/AuthContext';
import { GlobalContext } from '../../contexts/GlobalContext';

export default ({ activeTab, projects, clients, selectedFilter, dispatch, loadTable, unsavedData }) => {
  const { user } = useContext(AuthContext)
  const { translation, locale } = useContext(GlobalContext)
  const [client_id, setClientID] = useState(0)
  const [height, setHeight] = useState(0)
  const [dropdown, setDropdown] = useState(false)

  useEffect(() => {
    if (selectedFilter.type === 'Project' && projects[0].id !== 0)
      setClientID(projects.find(e => e.id == selectedFilter.id).client_id)
  }, [selectedFilter])

  const checkActiveProject = (id, activeValue, inactiveValue) => {
    return (selectedFilter.type === 'All' && id === 'all')? activeValue :(selectedFilter.type === 'Client' && selectedFilter.id == id)? activeValue: client_id == id ? activeValue : inactiveValue
  }


  const selectFilter = (id, name, type, clientname) => {
    const sel = {...selectedFilter}
    console.log(name)
    console.log(clientname)
    sel.id = id
    sel.name = name
    sel.type = type
    // if (clientname !== 0) {
      sel.clientname = clientname
    // }

    if (!!!unsavedData) {
      dispatch({ type: 'SET_SELECTED_FILTER', selectedFilter: sel })
      loadTable(sel, '1', 0);
    } else {
      if (window.confirm(translation.error_unsaved_changes[locale])) {
        dispatch({ type: 'SET_SELECTED_FILTER', selectedFilter: sel })
        loadTable(sel, '1', 0);
      }
    }
  }

  const project_role = project => {
    let proj = project.team.find(e => user.id == e.id)
    if (proj)
      return '(' + proj.project_role + ')'

    return ''
  } 

  useEffect(() => {
    setTimeout(() => {
      if (!!document.getElementById('filtero')) {
        console.log(document.getElementById('filtero').scrollHeight)
        setHeight(document.getElementById('filtero').scrollHeight)
      }
    }, 0);
  }, [dropdown])

  return (
    // <FormGroup className="mb-0" style={{ position: 'absolute', right: '1.3em' }}>
    //   { 
    //     clients[0].clientname !== 'Loading. . .' ?
    //       <div className="input-group input-group-sm">
    //         <div className="input-group-prepend">
    //           <div className="input-group-text">Filter</div>
    //         </div>
    //         <Input type="select" name="filter" id="filter" onChange={selectFilter} defaultValue={selectedFilter.name+'|'+selectedFilter.type+'|'+selectedFilter.id}>
    //           <option value="All|All|0">All</option>

    //           <optgroup label="Clients">
              // {
              //   clients.length !== 0 ?
              //     <>
              //       {clients.map((client, index) => (
              //         <Fragment key={"client-" + index}>
              //           <option value={client.clientname + "|Client|" + client.id} >{client.clientname}</option>
              //         </Fragment>
              //       ))}
              //     </> :
              //     <option value="No Data" disabled>- No Data -</option>

              // }
    //           </optgroup> 

    //           <optgroup label="Projects">
    //           {
    //             projects.length !== 0 ?
    //             <>
    //                 {projects.map((project, index) => (
    //                   <Fragment key={"project-" + index}>
    //                     <option value={project.proj_name + "|Project|" + project.id} >{project.proj_name}</option>
    //                   </Fragment>
    //                 ))}
    //               </> :
    //               <option value="No Data" disabled>- No Data -</option>
    //           }
    //           </optgroup>
    //           </Input>
    //       </div> :
    //     <Skeleton width={150} />
    //   }
    // </FormGroup>
    <>
      <Dropdown id="filteri" className="relative" size="sm" isOpen={dropdown} toggle={() => setDropdown(prevState => !prevState)}>
        <DropdownToggle style={{ height: '55px' }} caret>
          <div style={{ position: 'absolute', right: '25px', top: '5.5px', verticalAlign: 'top' }}> {/* (selectedFilter.type !== 'Project')? '13px':  */}
            <span style={{ fontSize: '1rem', fontWeight: 500, display: 'block' }}>{ selectedFilter.name === 'All Projects' ? translation.all_projects[locale] : selectedFilter.name }</span>
            <span style={{ display: 'block' }}>{ selectedFilter.clientname === 'All Clients' ? translation.all_clients[locale] : selectedFilter.clientname }</span>
            {/* {
              (selectedFilter.type === 'Project') && (
                <>
                  <br />
                  <span>{ selectedFilter.clientname }</span>
                </>
              )
            } */}
          </div>
        </DropdownToggle>
        <DropdownMenu id="filtero"
          modifiers={{
              setMaxHeight: {
                enabled: true,
                order: 890,
                fn: (data) => {
                  const { willChange, transform, position, top, left, ...rest } = data.styles
                  return {
                    ...data,
                    styles: {
                      ...rest,
                      overflowY: 'auto',
                      overflowX: 'hidden',
                      maxHeight: 500,
                      position: 'unset',
                      width: 'min-content',
                      marginRight: '-20px',
                      marginTop: '55px'
                    },
                  };
                },
              },
            }}>
          <DropdownItem header>{translation.client[locale]}</DropdownItem>
          <ButtonDropdown size="sm" toggle={() => {}} isOpen={false} direction="left">
            <DropdownItem 
              style={{ paddingLeft: '8px', backgroundColor: checkActiveProject('all', '#2b4dec', 'transparent'), color: checkActiveProject('all', '#fff', '#000'), padding: '7px 28px' }}
              onClick={() => selectFilter('all', 'All Projects', 'All', 'All Clients')} 
            >
                {translation.status.all[locale]}
            </DropdownItem>
          </ButtonDropdown>
          {
            (projects.length !== 0 && clients.length !== 0) &&
              <>
                {
                  clients.map((client, index) => (
                      <ButtonDropdown key={index} toggle={() => {}} isOpen={false} direction="left" size="sm" style={{ position: 'unset' }} onMouseEnter={() => document.getElementsByClassName('client-dropdown')[index].classList.add('show')} onMouseLeave={() => document.getElementsByClassName('client-dropdown')[index].classList.remove('show')}>
                        <DropdownItem style={{ paddingLeft: '8px', backgroundColor: checkActiveProject(client.id, '#2b4dec', 'transparent') }}>
                          <DropdownToggle style={{ color: checkActiveProject(client.id, '#fff', '#000') }}>
                            <ChevronLeftIcon /> {client.clientname}
                          </DropdownToggle>
                        </DropdownItem>
                        <DropdownMenu className="client-dropdown" style={{ height, overflowY: 'auto', marginTop: '55px' }} onClick={() => document.getElementsByClassName('client-dropdown')[index].classList.remove('show')}>
                          <DropdownItem header>{client.clientname}</DropdownItem>
                          <DropdownItem style={{ backgroundColor: selectedFilter.id == client.id? '#2b4dec': 'transparent', color: selectedFilter.id == client.id? '#fff': '#000' }} onClick={() => selectFilter(client.id, 'All Projects', 'Client', client.clientname)}>{ translation.status.all[locale] }</DropdownItem>
                          {
                            projects.length !== 0 &&
                            <>
                              {
                                projects.map((project, index) => (project.client_id == client.id) && (
                                  <DropdownItem style={{ backgroundColor: selectedFilter.id == project.id? '#2b4dec': 'transparent', color: selectedFilter.id == project.id? '#fff': '#000' }} key={index} onClick={() => selectFilter(project.id, project.proj_name, 'Project', client.clientname)} >{project.proj_name} <small style={{ fontWeight: '600' }}>{project_role(project)}</small></DropdownItem>
                                ))
                              }
                            </>
                          }
                        </DropdownMenu>
                      </ButtonDropdown>
                  ))
                }
              </> 
          }
        </DropdownMenu>
      </Dropdown>
    </>
  )
}