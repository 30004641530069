import React from 'react'
import { Breadcrumb } from 'antd'
import Skeleton from 'react-loading-skeleton';

export default ({ items, context }) => {
    
  return (
  <Breadcrumb>
    {
      items.length !== 0 && 
        (
          items.map(e => {
            if (!e.loading) {
              return (
                <Breadcrumb.Item key={e.key}>
                  <span onClick={() => e.link && context.history.push(e.link) } className={`${ e.link ? 'link-hover' : '' }`}>{ e.label }</span>
                </Breadcrumb.Item>
              )
            } else {
              return <Breadcrumb.Item key={e.key}><Skeleton count={1} width={'10%'}/></Breadcrumb.Item>
            }
          })
        )
    }
  </Breadcrumb>
  )
}