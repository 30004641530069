import React, { useContext } from 'react'
import Tooltip from '@material-ui/core/Tooltip';

import { GlobalContext } from '../../contexts/GlobalContext'

export default ({ time_estimate, time_approve }) => {
  const context = useContext(GlobalContext)

  return (
    <>
      <span style={{ marginRight: '5px' }}>{time_estimate !== '' && time_estimate !== '00:00'? time_estimate: 'N/A'}</span> 
      <Tooltip title={context.translation.msg_wait_approval[context.locale]}>
        <i style={{ display: time_approve === 0 && time_estimate !== '' && time_estimate !== '00:00'? 'inline': 'none', color: 'orange' }} className="fas fa-clock"></i>
      </Tooltip>
      <Tooltip title={context.translation.msg_time_estimate_declined[context.locale]}>
        <i style={{ display: time_approve === -1 && time_estimate !== '' && time_estimate !== '00:00'? 'inline': 'none', color: 'red' }} className="fas fa-times-circle"></i>
      </Tooltip>
      <Tooltip title={context.translation.msg_time_estimate_approved[context.locale]}>
        <i style={{ display: time_approve === 1 && time_estimate !== '' && time_estimate !== '00:00'? 'inline': 'none', color: 'green' }} className="fas fa-check-circle"></i>
      </Tooltip>
    </>
  )
}
