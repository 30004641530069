import React, { useContext } from 'react'
import logo from './empty.svg'
import logo2 from './original.png'
import { GlobalContext } from '../../../contexts/GlobalContext'

const Empty = (props) => {
  const { translation, locale } = useContext(GlobalContext)
  const additionalProperty = props.otherLogo? {
    height: 60
  }: {}

  return (
    <div id="empty-data" style={{ textAlign: 'center', ...props.style }}>
      <div className="d-flex justify-content-center" style={{ marginBottom: '5px' }}>
        <img src={props.otherLogo? logo2: logo} alt="logo" {...additionalProperty} {...props.imgStyle} />
        <br/>
      </div>
      {
        !props.hideLabel && <span>{ translation.no_data[locale]}</span>
      }
    </div>
  )
}

export default Empty
