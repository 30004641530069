import React, { Fragment, useContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom'
import { Type } from 'react-bootstrap-table2-editor';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';
import { Empty, Spinner2, ContentCard } from '../../../common';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Badge } from 'reactstrap';
import { Popover, Popconfirm, message } from 'antd';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Toast, Table } from '../../../common';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import 'moment/locale/de';
import Fab from '@material-ui/core/Fab';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { UPDATE_POINT, DELETE_REQUEST } from '../../../../queries';
import { ApolloContext } from 'react-apollo'; 
import { AuthContext } from '../../../../contexts/AuthContext';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Moment from 'react-moment';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';


const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectFormControl: {
    width: "100%",
  },
  chip: {
    margin: theme.spacing(1),
  },
 }));

// const headers = ['Point Nr', 'Client', 'Project Name', 'Instructions', 'Created By', 'Last Worked By', 'Status', 'Priority', 'Last Update'];
const headersUnassignedPoints = ['Point Nr', 'Client', 'Project Name', 'Instructions', 'Created By', 'Status', 'Priority'];
const headersClosedPoints = ['Point Nr', 'Client', 'Project Name', 'Instructions', 'Created By', 'Status', 'Priority', 'Last Update'];
let buttonClicked = false

const Action = ({ tab, row, state, dispatch, context }) => {
  const { client } = useContext(ApolloContext);
  const updatePoint = async (type, approve) => {
    buttonClicked = true
    let payload = (type === 'time_estimate')? { time_approve: approve? 1: -1 }: { ack: 1 }
    let pointNotif = (type === 'time_estimate')? { timeEstimatePoints: --state.timeEstimatePoints }: { closedPoints: --state.closedPoints }
      
    const { data } = await client.mutate({ mutation: UPDATE_POINT, variables: { data: { id: row.id,  ...payload }, type: { table: 'point', filter: {...context.selectedFilter, table: tab}, limit: 1, offset: (15 * state.query_count) } } });

    if (type === 'time_estimate') 
      message.success(`${payload.time_approve === 1? context.translation.time_approved[context.locale] : context.translation.time_declined[context.locale] } #${row.point_nr}.`);
      // Toast.showToast('success', `${payload.time_approve === 1? context.translation.time_approved[context.locale] : context.translation.time_declined[context.locale] } #${row.point_nr}.`)
    
    const points = state.points.filter(e => e.id !== row.id)

    if (data.updatePoint)
      points.push(data.updatePoint)
    dispatch({ type: 'SET_STATE', payload: { points, ...pointNotif, ack: ++state.ack } })
  }

  return tab == 3? 
    (
      <Tooltip title='Acknowledge'>
        <button outline onClick={(e) => {updatePoint('closed'); e.stopPropagation()}} className="btn btn-sm btn-outline-success" style={{ width: '30px', height: '30px' }}>
          <i className="fas fa-check"></i>
        </button>
      </Tooltip>
    ) : 
    (
      <>
        <Tooltip title='Approve'>
          <button outline onClick={(e) => {updatePoint('time_estimate', true); e.stopPropagation()}} className="btn btn-sm btn-outline-success" style={{ width: '30px', height: '30px' }}>
            <i className="fas fa-check"></i>
          </button>
        </Tooltip>
        <Tooltip title='Decline'>
          <button outline onClick={(e) => {updatePoint('time_estimate', false); e.stopPropagation()}} className="btn btn-sm btn-outline-danger" style={{ marginLeft: '5px', width: '30px', height: '30px' }}>
            <i className="fas fa-times"></i>
          </button>
        </Tooltip>
      </>
    )
}

const badgeColorStatus = [
  { name: 'Open', border: '1px solid #CF0F22', backgroundColor: '#FAE6E8', color: '#CF0F22', fontWeight: '400', borderRadius: '2px' },
  { name: 'Question', border: '1px solid #219FC7', backgroundColor: '#E8F5F9', color: '#219FC7', fontWeight: '400', borderRadius: '2px' },
  { name: 'For Testing', border: '1px solid #5B3694', backgroundColor: '#EEEAF4', color: '#5B3694', fontWeight: '400', borderRadius: '2px' },
  { name: 'Postpone', border: '1px solid #E43988', backgroundColor: '#FDEBF3', color: '#E43988', fontWeight: '400', borderRadius: '2px' },
  { name: 'Closed', border: '1px solid #53AD57', backgroundColor: '#EDF7EE', color: '#53AD57', fontWeight: '400', borderRadius: '2px' },
]

const badgeColorPriority = [
  { name: 'High', border: '1px solid #CF0F22', backgroundColor: '#FAE6E8', color: '#CF0F22', fontWeight: '400', borderRadius: '2px' },
  { name: 'Mid', border: '1px solid #FF8B00', backgroundColor: '#FFF3E5', color: '#FF8B00', fontWeight: '400', borderRadius: '2px' },
  { name: 'Low', border: '1px solid #FFC400', backgroundColor: '#FFF9E5', color: '#FFC400', fontWeight: '400', borderRadius: '2px' },
]

const FeedbackList = withRouter(({ activeTab, toggle, history, reducers, points, initialStates: { initialErrorState, initialFormState, initialModalFieldState } }) => {
  const { client } = useContext(ApolloContext);
  const { user } = useContext(AuthContext);
  const [visible, setVisible] = useState({});
  const context = useContext(GlobalContext);
  const { state, dispatch } = reducers
  const { SearchBar } = Search;
  const { ToggleList } = ColumnToggle;
  const classes = useStyles();

  const openViewFeedback = (feedback) => {
    history.push(`/feedback/${feedback.f_id}/`);
  }

  const columns = [{
    dataField: 'id',
    text: 'id',
    hidden: true,
  }, {
    dataField: 'point_nr',
    text: 'ID',
    sort: true,
    headerStyle: (colum, colIndex) => ({ width: '35px' }),
  }, {
    dataField: 'clientname',
    text: context.translation.client[context.locale],
    sort: true,
    align: 'center',
    headerAlign: 'center',
    headerStyle: (colum, colIndex) => ({ width: '120px' })
  }, {
    dataField: 'proj_name',
    text: context.translation.nav.project[context.locale] + ' Name',
    sort: true,
    headerStyle: (colum, colIndex) => ({ width: '130px' })
  }, {
    dataField: 'instructions',
    text: context.translation.instruction[context.locale],
    sort: true,
    // formatter: (cell, row, rowIndex, formatExtraData) => cell.substring(0,275) + '...',
    style: (colum, colIndex) => ({ 
      // width: '34%' ,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    })
  }, {
    dataField: 'time_estimate',
    text: context.translation.time_estimate[context.locale],
    sort: true,
    hidden: true,
    headerStyle: (colum, colIndex) => ({ width: '140px' })
  }, {
    dataField: 'last_change',
    text: context.translation.last_change[context.locale],
    sort: true,
    headerStyle: (colum, colIndex) => ({ width: '120px' }),
    formatter: (cell, row, rowIndex, { context }) => !!cell ? (
      <span style={{ display: 'block' }}>
        <small style={{ display: 'block' }}><b>{ cell.name }</b></small>
        { context.translation.type_of_change[(cell.description).split('*')[0]][context.locale] }
      </span>
    ): '-',
    formatExtraData: { context }
  }, {
    dataField: 'members',
    text: context.translation.working_by[context.locale],
    sort: true,
    align: 'center',
    headerAlign: 'center',
    headerStyle: (colum, colIndex) => ({ width: '70px' })
  }, {
    dataField: 'status',
    text: 'Status',
    sort: true,
    align: 'center',
    headerAlign: 'center',
    formatter: (cell, row, rowIndex, formatExtraData) => cell && <span className={`badge badge-sm ${cell === 'Draft' && "bg-light"}`} style={{ ...badgeColorStatus.find(e => e.name === cell) }}>{context.translation.status[cell.toLowerCase().replace(' ', '_')][context.locale]}</span>,
    headerStyle: (colum, colIndex) => ({ width: '100px' })
  }, {
    dataField: 'priority',
    text: context.translation.priority[context.locale],
    align: 'center',
    headerAlign: 'center',
    formatter: (cell, row, rowIndex, formatExtraData) => cell && <span className={`badge badge-sm`} style={{ ...badgeColorPriority.find(e => e.name === cell) }}>{context.translation[cell.toLowerCase()][context.locale]}</span>,
    headerStyle: (colum, colIndex) => ({ width: '80px' }),
    hidden: user.projectRole === 'Client'
  }, {
    dataField: 'date',
    text: context.translation.last_update[context.locale],
    headerStyle: (colum, colIndex) => ({ width: '130px' }),
    formatter: (cell, row, rowIndex, formatExtraData) => cell && <Moment interval={60000} locale={context.locale} fromNow>{ cell }</Moment>
  }, {
    dataField: 'action',
    text: context.translation.action[context.locale],
    editable: false,
    align: 'center',
    headerAlign: 'center',
    hidden: true,
    headerStyle: (colum, colIndex) => ({ width: '100px' }),
    formatter: (cell, row, rowIndex, formatExtraData) => <Action tab={activeTab} row={row} state={formatExtraData.reducer.state} dispatch={formatExtraData.reducer.dispatch} context={formatExtraData.context} />,
    formatExtraData: {
      reducer: {
        state,
        dispatch
      },
      context
    }
  }];

  async function onClick (e, row, rowIndex) {  
    setVisible({[rowIndex]: false})
    // localStorage.setItem('buttonClicked', false)
    const {last_change, title, creator_id, creator, comments, clientname, deadline, deadlineMoment, date, date_created, files, team: users, hours_worked, time_estimate, time_approve, version, version_name, instructions, priority, status} = row
    const members =  !!context.projects.find(e => e.id == row.proj_id) ? context.projects.find(e => e.id == row.proj_id).team : []
    let editItem = (row.id > 0)? { id: row.id, point_nr: row.point_nr }: {}
      
    let project_role = 'None'
    members.forEach(e => {
      if (e.id == user.id) 
        project_role = e.project_role
    })

    const payload = {
      editedItem: {
        ...state.editedItem,
        ...editItem,
        comments, 
        last_change,
        clientname, 
        users,
        date,
        date_created,
        creator_id, 
        creator,
        time_estimate: time_estimate === 'N/A'? '' : time_estimate, 
        hours_worked: hours_worked === 'N/A' ? '' : hours_worked,
        version: version === 'N/A'? state.versions.length !== 0? state.versions[0].id + "|" + state.versions[0].version_name : null : version, 
        version_name, 
        time_approve,
        instructions, 
        priority, 
        status,
        deadline: deadlineMoment,
        deadlineDisplay: deadline,
        rowIndex
      },
      files: (row.id > 0) ? files.map(e => ({source: `project_${row.proj_id}/feedback_${row.feedback_id}/point_${row.point_nr}_${row.id}/${e.filename}`, options: { type: 'local' }, filename: e.filename})): files,
    }

    dispatch({ type: 'SET_STATE', 
      payload: { 
        id: row.feedback_id,
        modal: true, 
        title,
        modalType: 'update',
        project: {
          id: row.proj_id,
          name: row.proj_name
        },
        project_role,
        members,
        versions: !!context.projects.find(e => e.id == row.proj_id) ? context.projects.find(e => e.id == row.proj_id).project_versions.map(e => ({id: e.id, version_name: e.version_name})) : [],
        ...payload,
        rowData: {...payload},
        // For default data when cancel is clicked
        // gallery: files.map(e => ({ filename: e.filename, src: `${context.uri}?load=project_${state.project.id}/feedback_${state.id}/point_${row.point_nr}_${row.id}/${e.filename}`, width: 1, height: 1 }))
      } 
    })
  } 

  async function handleConfirm(id) {
    const hide = message.loading(context.translation.saving_changes[context.locale], 0);
    
    client.mutate({ mutation: DELETE_REQUEST, variables: { ids: id } }).then(({data}) => {
      dispatch({ type: 'SET_STATE', payload: { points: points.filter(project => project.id != id), openRequests: parseInt(state.openRequests) - 1 } })
      hide()
      message.success(context.translation.msg_success_remove[context.locale])
    }).catch(error => {
      context.dispatch({ type: 'SET_STATE', payload: { disableButton: false } }) // Show FAB      
      hide()
      message.error(error.toString())  
    })
  }

  function openRequest(proj_id, proj_name, client_id, clientname, user_id) {
    const user = state.users.find(e => e.id == user_id)

    dispatch({ 
      type: 'SET_STATE', 
      payload: { 
        errors: initialErrorState,
        modalRequest: true,
        modalField: { 
          ...initialModalFieldState,
          client: {
            ...initialModalFieldState.client, 
            id: -1,
            clientname,
            old_clientname: clientname
          }
        },
        proj_nr: '',
        proj_name,
        editVersion: { edit: false, id: 0, version_name: '' },
        version: '',
        versions: [],
        team: [],
        form: {
          ...initialFormState,
          id: user.id,
          email: user.email,
          client_id: client_id
        },
        proj_id,
        client_id
      } 
    })
  }

  return (
    <Fragment>
      <div>
        <div>
            
          <div onClick={() => toggle('1')} className="tabit" id="step-1">
            <div className={`tabi ${activeTab == '1'? 'tabiactive': ''}`} >{context.translation.assigned_points[context.locale]} <Badge color="warning" style={{ display: state.assignedPoints !== 0? 'inline-block': 'none', marginTop: '1.5px', marginLeft: '5px', backgroundColor: activeTab == '1'? '#FFF' : '#3663FD', color: activeTab == '1'? '#3765FD': '#fff' }} pill>{ state.assignedPoints }</Badge></div>
          </div>

          {
            user.projectRole !== 'Client' && (
              <div className={classnames({ active: activeTab === '2' })} onClick={() => toggle('2')} className="tabit" id="step-2">
                <div className={`tabi ${activeTab == '2'? 'tabiactive': ''}`} >{context.translation.unassigned_points[context.locale]} <Badge color="warning" style={{ display: state.unassignedPoints !== 0? 'inline-block': 'none', marginTop: '1.5px', marginLeft: '5px', backgroundColor: activeTab == '2'? '#FFF' : '#3663FD', color: activeTab == '2'? '#3765FD': '#fff' }} pill>{ state.unassignedPoints }</Badge></div>
              </div>
            )
          }
           
          {
          state.projectRole === 'Project Leader' && (
            <div className={classnames({ active: activeTab === '3' })} onClick={() => toggle('3')} className="tabit" id="step-3">
              <div className={`tabi ${activeTab == '3'? 'tabiactive': ''}`} >{context.translation.to_be_confirmed_points[context.locale]} <Badge color="warning" style={{ display: state.closedPoints !== 0? 'inline-block': 'none', marginTop: '1.5px', marginLeft: '5px', backgroundColor: activeTab == '3'? '#FFF' : '#3663FD', color: activeTab == '3'? '#3765FD': '#fff' }} pill>{ state.closedPoints }</Badge></div>
            </div>
          )
          }

          {
            state.projectRole === 'Project Leader' && (
              <div className={classnames({ active: activeTab === '4' })} onClick={() => toggle('4')} className="tabit" id="step-4">
                <div className={`tabi ${activeTab == '4'? 'tabiactive': ''}`} >{context.translation.unapproved_estimate[context.locale]} <Badge color="warning" style={{ display: state.timeEstimatePoints !== 0? 'inline-block': 'none', marginTop: '1.5px', marginLeft: '5px', backgroundColor: activeTab == '4'? '#FFF' : '#3663FD', color: activeTab == '4'? '#3765FD': '#fff' }} pill>{ state.timeEstimatePoints }</Badge></div>
              </div>
            )
          }
            
          {
            user.role === 'Administrator' && (
              <div className={classnames({ active: activeTab === '5' })} onClick={() => toggle('5')} className="tabit" id="step-4">
                <div className={`tabi ${activeTab == '5'? 'tabiactive': ''}`} >{context.translation.open_requests[context.locale]} <Badge color="warning" style={{ display: state.openRequests !== 0? 'inline-block': 'none', marginTop: '1.5px', marginLeft: '5px', backgroundColor: activeTab == '5'? '#FFF' : '#3663FD', color: activeTab == '5'? '#3765FD': '#fff' }} pill>{ state.openRequests }</Badge></div>
              </div>
            )
          }
            

          {
            context.selectedFilter.type === 'Project' && (
              <div style={{ float: 'right', boxShadow: '0 0.2rem 1rem rgba(0,0,0,.15)', padding: '0.85rem 1rem', borderRadius: '5px', backgroundColor: '#FFFFFF' }}>
                <div>
                  { 
                    state.projectRole ?
                    <>
                      <span style={{ fontWeight: 600 }}>{context.translation.project_role[context.locale]}: </span>
                      <span>{ context.translation[state.projectRole.toLowerCase().replace(' ', '_')][context.locale] }</span>
                    </>
                    : <Skeleton />
                  }
                </div>
              </div>
            )
          }

        </div>

        <div style={{ boxShadow: activeTab != 5 ? '0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12)': 'none' }}>
          <Row>
          {/* justifyContent: !state.isLoading ? points.length !== 0 ? 'space-between': 'space-around' : 'space-around' */}
            <Col sm="12" className="recent-update-table" style={activeTab == 5? { display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 300px))', gap: '53px' } : {}}>
                {/* <Table data={state.points} showScrollbar={true} headers={headers} height="480px" /> */}
                {
                  activeTab != 5 ? 
                    (
                      <ToolkitProvider
                        keyField='id'
                        bootstrap4
                        columns = { columns }
                        data={ points.map(e => {
                          let action = { }
                            
                          // if (activeTab == 3 || activeTab == 4 ) 
                          //   action = { action: <Action tab={activeTab} row={e} state={state} dispatch={dispatch} context={context} />}
                    

                            let team = ['','',''], members
                          
                          if (e.team) {
                            e.team.forEach(e => {
                              // console.log(e)
                              const suffix = (e.id === user.id)? ` (${context.translation.you[context.locale]})` : ''
                              if (e.project_role === 'Project Leader') {
                                team[0] += (e.name + suffix + '\n')
                              } else if (e.project_role === 'Project Staff') {
                                team[1] += (e.name + suffix + '\n')
                              } else { 
                                team[2] += (e.name + suffix + '\n')
                              }
                            })
                            if (team[0] !== '' || team[1] !== '' || team[2] !== '') {
                              members = team.map((e, i) => {
                                if (!!e[i])
                                  return (
                                  <Tooltip key={i} title={<div style={{whiteSpace: 'pre-line'}}>{e}</div>}>
                                    <Chip style={{ margin: 0, boxShadow: '0 5px 7px rgba(0,0,0,0.20)', marginLeft: (i > 0)? '-4px': 0, paddingLeft: '20px', width: '24px', backgroundColor: 'transparent' }} avatar={<Avatar style={{ width: '25.5px', height: '25.5px', border: '1.5px white solid' }}>{(i === 0)? 'PL': (i === 1)? 'PS' : 'FP' }</Avatar>}  className={`MuiChip-sizeSmall ` + classes.chip} />
                                  </Tooltip>
                                  )
                              })
                            } else {
                              members = 'N/A'
                            }
                            
                            return {
                              ...e, 
                              members,
                              date: e.date,
                              deadlineMoment: (e.deadline !== 'N/A')? moment(e.deadline): moment(),
                              // ...action
                            }
                          } else {
                            return {
                              ...e, 
                              // ...action
                            }
                          }
                        }) } 
                        columnToggle
                      >
                        {
                          props => (
                            <div>
                              <div id="toggle-columns">
                                <ToggleList { ...props.columnToggleProps } />
                              </div>
                              <hr />
                              <BootstrapTable 
                              { ...props.baseProps } 
                              rowEvents={{ onClick }}
                              noDataIndication={ () => state.isLoading? <Spinner2 />: <Empty /> }
                              bordered={ false }
                              hover />
                            </div>
                          )
                        }
                      </ToolkitProvider>
                    ) :
                    !state.isLoading ?
                      points.length !== 0 ?
                        points.map((project, i) => 
                          (
                            <div className="d-card" key={i}>
                              <div className="d-display">

                                <Popconfirm
                                  placement="leftBottom"
                                  title={context.translation.are_you[context.locale]}
                                  onConfirm={() => handleConfirm(project.id)}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <div className="d-close">×</div>
                                </Popconfirm>

                                <h4 className="d-header">{ project.proj_name }</h4>
                                <h5 className="d-sub-header">{ project.clientname }</h5>

                                <Popover 
                                  placement="rightTop" 
                                  visible={visible[i]}
                                  onVisibleChange={visible => setVisible({[i]: visible})}
                                  title={<><span style={{ display: 'block' }}>{project.proj_name}</span><small style={{ display: 'block', color: '#afafaf' }}>{project.clientname}</small></>} 
                                  content={
                                    <>
                                      <div id="point-card">
                                        {
                                          !!project.points ?
                                              project.points.length !== 0 ? 
                                                project.points.map((point, index) => <ContentCard key={index} data={{ point, index, hideCreator: true }} context={context} clickEvent={(e) => onClick(e, point, i)} />)
                                              : <Empty /> 
                                              : ''
                                        }       
                                      </div>
                                    </>
                                  } trigger="click">
                                    <p className="d-link-point">{ project.points.length } { context.translation.new_points[context.locale] }</p>
                                </Popover>

                              </div>

                              <div className="d-action">
                                <Fab
                                  className="d-accept"
                                  variant="extended"
                                  size="small"
                                  color="primary"
                                  aria-label="Add"
                                  onClick={() => openRequest(project.id, project.proj_name, project.client_id, project.clientname, project.points[0].creator_id)}  
                                >
                                  <span>{context.translation.accept[context.locale]}</span>
                                </Fab>

                                <br />
                                  
                                <small><FontAwesomeIcon icon={faClock} style={{ verticalAlign: 'middle' }}/> <Moment style={{ verticalAlign: 'middle' }} locale={context.locale} interval={60000} fromNow>{ project.date_created }</Moment></small>
                              </div>

                            </div>
                        ))
                      : <Empty />
                    :<Spinner2 />
                }
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  )
});

export default FeedbackList