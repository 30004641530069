import React from 'react'
import { NavHeader, SpringStyle } from './index';
import { withRouter } from 'react-router-dom'
import { useSpring, animated } from 'react-spring'
import { Container, Row, Col } from 'reactstrap'

export default (props) => {   
   const { title , components, back, customTabs } = props;
   return (
      // <animated.div style={useSpring(SpringStyle)}>
        <Container style={{ marginBottom: '20px' }} fluid>
          <Row style={{ height: '60px' }}>
            <Col id="page-header">
              <NavHeader title={title} components={components} back={back} customTabs={customTabs} />
            </Col>
          </Row>
            
          <Row>
            <Col> 
              { props.children }
            </Col>
          </Row>
         </Container>
      // </animated.div>
   )
}