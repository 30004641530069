import React, { useState, useEffect } from 'react'
import { Transfer, Button } from 'antd';

export default React.memo(({ projects, context, handleCancel, handleSaveArchive, loading, data: { archives, setArchives, tempArchives, setTempArchives }  }) => {
  const filterOption = (inputValue, option) => option.title.toLowerCase().search(inputValue.toLowerCase()) > -1;
  
  useEffect(() => {
    if (projects.length !== 0) {
      const proj = projects.filter(project => project.status === 'Archived').map(project => project.id)
      console.log(proj)
      setArchives(proj)
      setTempArchives(proj)
    }
  }, [projects]) 

  const handleChange = targetKeys => {
    console.log(targetKeys)
    setArchives(targetKeys);
  };

  function isDataNew() {
    // Checks old and new data 
    console.log(tempArchives)
    console.log(archives)
    if (tempArchives.length === archives.length) {
      console.log(tempArchives.every(e => !!archives.find(s => s == e)))
      return tempArchives.every(e => !!archives.find(s => s == e))
    } 

    return false
  }


  return (
    <>
      <Transfer
        titles={[context.translation.closed_projects[context.locale], context.translation.status.archive[context.locale]]}
        dataSource={
          projects.filter(project => (['Closed', 'Archived']).includes(project.status)).map(project => ({
            key: project.id,
            title: project.proj_name + ' (' + (!!project.client? project.client.clientname : 'Deleted Client') + ') | ' + project.date_created 
          })) 
        }
        listStyle={{
          'min-width': 250,
          width: 'fit-content',
          'min-height': 350,
        }}
        locale={{
          itemUnit: 'item', 
          itemsUnit: 'items', 
          notFoundContent: context.translation.empty_list[context.locale], 
          searchPlaceholder: context.translation.search[context.locale]
        }}
        showSearch
        filterOption={filterOption}
        targetKeys={archives}
        onChange={handleChange}
        render={item => item.title}
      />

      {/* Action Buttons */}
      <div style={{ textAlign: 'right', marginTop: '15px' }}>
        <Button type="link" onClick={handleCancel}>{context.translation.cancel[context.locale]}</Button>
        <Button type="primary" disabled={isDataNew()} onClick={handleSaveArchive} loading={loading}>{context.translation.save[context.locale]}</Button>
      </div>
    </>
  )
})