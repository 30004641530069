import React from 'react'
import { useSpring, animated } from 'react-spring'
import { withRouter } from 'react-router-dom'

const SideNav = withRouter(({ history, sideNavIsOpen }) => {
 
    const goTo  = (to) => {
        history.push(to)
    }

    return (
        <aside className={sideNavIsOpen ? "sidenav active-sn" : "sidenav"}>
            <ul>
                <li className="px-4 py-2 border-bottom border-top" onClick={ () => goTo('/dashboard') }>
                    <span className="icon-link mr-1"><i className="fas fa-tachometer-alt"></i></span> Dashboard
                    
                </li>
                <li className="px-4 py-2 border-bottom" onClick={ () => goTo('/feedbacks1') }>
                    <span className="icon-link mr-1"><i className="fas fa-comments"></i></span> Feedback List v1
                </li>
                <li className="px-4 py-2 border-bottom" onClick={ () => goTo('/feedbacks') }>
                    <span className="icon-link mr-1"><i className="fas fa-comments"></i></span> Feedback List v2
                </li>
                <li className="px-4 py-2 border-bottom border-top" onClick={ () => goTo('/users') }>
                    <span className="icon-link mr-1"><i className="fas fa-user-tag"></i></span> Users
                </li>
                <li className="px-4 py-2 border-bottom border-top" onClick={ () => goTo('/projects') }>
                    <span className="icon-link mr-1"><i className="fas fa-cubes"></i></span> Projects
                </li>
                <li className="px-4 py-2 border-bottom border-top" onClick={ () => goTo('/clients') }>
                    <span className="icon-link mr-1"><i className="fas fa-user-shield"></i></span> Clients
                </li>
            </ul>
        </aside>
    )
})

export default SideNav;
