import React, { useContext, useState, useEffect } from 'react'
import Avatar from '../common/Avatar'
import { Navbar, Collapse, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Card, CardBody, Form, FormGroup, Label, Input, FormFeedback, Spinner, Alert } from 'reactstrap'

import { Button, Spinner2 } from '../common'
import { GlobalContext } from '../../contexts/GlobalContext'
import gerFlag from '../../brand/ger-flag.png';
import engFlag from '../../brand/eng-flag.png'
import { Button as Buttona } from 'antd';

import Icon from '../common/Icon';

/* Import Apollo and Queries */
import { ApolloContext } from 'react-apollo';
import { AUTHENTICATE_TOKEN } from '../../queries';

const LoginForm = ({state, dispatch, handleSubmit, handleForgotPassword, params, handleSendVerificationKey, handleVerifyUser, goToLogin, goToRegister}) => {
  const { client } = useContext(ApolloContext)
  const { userName, password, formMessage, formPassMessage, inValidForm, isLoading, isError, errorMessage } = state
  const context = useContext(GlobalContext)

  const setActive = lang => (context.locale === lang) ? true : false;
  const setFlag = () => (context.locale === 'de') ? gerFlag : engFlag;

  useEffect(() => {
    dispatch({ type: 'SET_STATE', payload: { isError: params.search === '' && state.forgotPassword && params.type === 'resetPassword' && !state.clicked, errorMessage: params.search === '' && state.forgotPassword && params.type === 'resetPassword' && !state.clicked? `${context.translation.new_pass_error[context.locale]}!`: '', isLoading: false, inValidForm: false, formMessage: '' }})
  }, [state.forgotPassword])

  useEffect(() => {
    if (params.type === 'verifyUser' && !state.forgotPassword && !state.verifyUser)
      dispatch({ type: 'SET_STATE', payload: { isLoading: false, isNewDevice: true, verifyUser: true, forgotPassword: true, user_id: params.search !== ''? params.search.replace('?', '') : '0' }})
  }, [state.isNewDevice])

  useEffect(() => {
    (async () => {
      if (params.type === 'resetPassword') {
        if (params.search !== '') {
          dispatch({ type: 'SET_STATE', payload: { forgotPassword: true, isLoading: true, clicked: false } })
          try {
            const { data } = await client.mutate({ mutation: AUTHENTICATE_TOKEN, variables: { data: { token: params.search.substring(1) } } })
            if (data.authenticateToken.status === 200)
              dispatch({ type: 'SET_STATE', payload: { newPassword: data.authenticateToken.msg, isLoading: false } })
            else
              dispatch({ type: 'SET_ERROR', isError: true, message: context.translation.new_pass_error[context.locale] })
          } catch (error) {
            console.log(error)
          }
        } else {
          dispatch({ type: 'SET_STATE', payload: { isError: true, errorMessage: `${context.translation.new_pass_error[context.locale]}!`, isLoading: false, forgotPassword: true }})
        }
      }
    })()
  }, [])

  return (
    <Card style={{margin:'10% auto 0 auto', boxShadow: 'rgba(0, 0, 0, 0.5) 0px 0.5rem 1rem'}}> 
      <Navbar className="main-navbar flex-md-nowrap p-0" style={{ backgroundColor: 'transparent', height: '49px', position: 'absolute', right: 0 }} light expand="md">
        <Collapse className="mr-2" navbar>
          <Nav className="ml-auto" style={{ height: '50px', paddingTop: '7px' }} navbar>
            <UncontrolledDropdown id="lang" className="ml-2" style={{ marginRight: '7px' ,backgroundColor: 'transparent' }} nav inNavbar>
              <DropdownToggle nav>
                <Icon height="13" width="15" src={setFlag()}  />
              </DropdownToggle>
              <DropdownMenu right className="mb-2" style={{ fontSize: '.795rem' }}>
                <DropdownItem onClick={() => context.setLocale('en')} style={{ paddingLeft: '15px' }} active={setActive('en')}><small style={{ marginRight: '10px' }}>EN</small> English</DropdownItem>
                <DropdownItem onClick={() => context.setLocale('de')} style={{ paddingLeft: '15px' }} active={setActive('de')}><small style={{ marginRight: '10px' }}>DE</small> German</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
        <CardBody>
        {
          !state.forgotPassword? 
            <>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '65px' }}>
                  <Avatar />
                </div>
                <div>
                  <h4 id="logo-namee" style={{ fontSize: '2.5em', marginTop: '-17px', marginLeft: 0 }}>xactplace</h4>
                </div>
              </div>
              <Form onSubmit={handleSubmit} style={{margin:'0 auto'}} >
                <FormGroup>
                    <Label for="exampleEmail">Email</Label>
                    <Input invalid={inValidForm} onChange={ (e) => dispatch({type: 'INPUT', field:'userName', value: e.currentTarget.value}) } type="email" name="username" id="username" autoComplete="on" value={userName} />
                    {
                      inValidForm? <FormFeedback>{formMessage}</FormFeedback>: ''
                    }
                    
                </FormGroup>
                <FormGroup>
                    <Label for="exampleEmail">{context.translation.password[context.locale]}</Label>
                    <Input invalid={inValidForm} onChange={ (e) => dispatch({type: 'INPUT', field:'password', value: e.currentTarget.value}) } type="password" name="password" id="password" value={password} />
                    {
                      inValidForm? <FormFeedback>{formPassMessage}</FormFeedback>: ''
                    }
                </FormGroup>

                  <Alert color="danger" isOpen={isError} toggle={() => {dispatch({ type: 'SET_ERROR', isError: false, errorMessage: '' })}} >
                    {errorMessage}
                  </Alert>

                <div className="d-flex justify-content-between align-items-center">
                    <Button className="btn-exact" disabled={isLoading} style={{ background: (isLoading)? 'rgba(182, 25, 38, 0.616)': 'linear-gradient(-45deg, #B61927, #982827)', width: '100%' }}><Spinner color="light" size="sm" style={{ marginRight: '10px', display: (isLoading)? 'inline-block' : 'none' }} /> {context.translation.login[context.locale]}</Button> 
                      {/* <small className="small-link mb-1">{context.translation.create_account[context.locale]}</small> */}
                </div>
                <br />
                <div className="d-flex flex-row" style={{ justifyContent: 'space-between' }}>
                  <small className="small-link" style={{ textAlign: 'left' }} onClick={goToRegister}>{context.translation.register[context.locale]}</small>
                  <small className="small-link" style={{ textAlign: 'right' }} onClick={() => dispatch({ type: 'SET_STATE', payload: { forgotPassword: !state.forgotPassword }})}>{context.translation.forgot_password[context.locale]}?</small>
                </div>
                <br />
              </Form>
            </> :
            <>
              {
                !state.clicked && params.type === 'resetPassword'? (
                  <> 
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div style={{ width: '65px' }}>
                        <Avatar />
                      </div>
                      <div>
                        <h4 id="logo-namee" style={{ fontSize: '2.5em', marginTop: '-17px', marginLeft: 0 }}>xactplace</h4>
                      </div>
                    </div>
                    <center>
                      {
                        state.isLoading ? 
                          <Spinner2 />
                        :
                          isError ?
                            <>
                              <Alert color="danger" isOpen={isError}>
                                {errorMessage}
                              </Alert>
                              <div className="d-flex flex-column align-items-left">
                                <small className="small-link" onClick={() => dispatch({ type: 'SET_STATE', payload: { forgotPassword: !state.forgotPassword, clicked: true }})}>← {context.translation.signin[context.locale]}</small>
                              </div>
                            </>
                          :
                            <>
                              <h4>{context.translation.new_pass_msg[context.locale]}</h4>
                              <h2 style={{ marginBottom: '15px', backgroundColor: '#e3e3e3', width: 'max-content', padding: '5px 30px', borderRadius: '5px' }}>{state.newPassword}</h2>
                              <div className="d-flex flex-column align-items-left">
                                <small className="small-link" onClick={() => dispatch({ type: 'SET_STATE', payload: { forgotPassword: !state.forgotPassword }})}>← {context.translation.signin[context.locale]}</small>
                              </div>
                            </>
                      }
                    </center>
                  </>
                ): 
                !state.isNewDevice ? (
                  <Form onSubmit={handleForgotPassword} style={{margin: '20px auto'}}>                  
                    <h4>{context.translation.reset_password[context.locale]}</h4>
                    <span>{context.translation.reset_pass_msg[context.locale]}</span>
                    <FormGroup style={{ marginTop: '20px' }}>
                        <Label for="exampleEmail">Email</Label>
                        <Input invalid={inValidForm} onChange={ (e) => dispatch({type: 'INPUT', field:'userName', value: e.currentTarget.value}) } type="email" name="username" id="username" autoComplete="on" value={userName} />
                        {
                          inValidForm? <FormFeedback>{formMessage}</FormFeedback>: ''
                        }
                    </FormGroup>

                    <Alert color="danger" isOpen={isError} toggle={() => {dispatch({ type: 'SET_ERROR', isError: false, errorMessage: '' })}} >
                      {errorMessage}
                    </Alert>

                    <div className="d-flex justify-content-between align-items-center">
                        {/* <Button className="btn-exact" disabled={isLoading} style={{ background: (isLoading)? 'rgba(41,72,255,0.6)': 'linear-gradient(-45deg, #396afc, #2948ff)', width: '100%' }}><Spinner color="light" size="sm" style={{ marginRight: '10px', display: (isLoading)? 'inline-block' : 'none' }} /> {context.translation.send[context.locale]}</Button>  */}
                        <Button className="btn-exact" disabled={isLoading} style={{ background: (isLoading)? 'rgba(182, 25, 38, 0.616)': 'linear-gradient(-45deg, #B61927, #982827)', width: '100%' }}><Spinner color="light" size="sm" style={{ marginRight: '10px', display: (isLoading)? 'inline-block' : 'none' }} /> {context.translation.send[context.locale]}</Button> 
                          {/* <small className="small-link mb-1">{context.translation.create_account[context.locale]}</small> */}
                    </div>
                    <br />
                    <div className="d-flex flex-column align-items-left">
                      <small className="small-link" onClick={() => dispatch({ type: 'SET_STATE', payload: { forgotPassword: !state.forgotPassword }})}>← {context.translation.signin[context.locale]}</small>
                    </div>
                    <br />
                  </Form>
                ):
                state.isNewDevice && state.verifyUser ? (
                  <Form onSubmit={handleVerifyUser} style={{margin: '20px auto'}}>    
                    <h4>{ context.translation.enter_verification_key[context.locale] }</h4>
                    <FormGroup style={{ marginTop: '20px' }}>
                        <Label for="exampleEmail">{ context.translation.verification_key[context.locale] }</Label>
                        <Input invalid={inValidForm} onChange={ (e) => dispatch({type: 'INPUT', field: 'verificationKey', value: e.currentTarget.value}) } type="text" name="username" id="username" autoComplete="on" value={state.verificationKey} />
                        {
                          inValidForm? <FormFeedback>{formMessage}</FormFeedback>: ''
                        }
                    </FormGroup>

                      <Alert color="danger" isOpen={isError} toggle={() => {dispatch({ type: 'SET_ERROR', isError: false, errorMessage: '' })}} >
                        {errorMessage}
                      </Alert>

                    <center>
                      <div className="d-flex justify-content-between align-items-center">
                          {/* <Button className="btn-exact" disabled={isLoading} style={{ background: (isLoading)? 'rgba(41,72,255,0.6)': 'linear-gradient(-45deg, #396afc, #2948ff)', width: '100%' }}><Spinner color="light" size="sm" style={{ marginRight: '10px', display: (isLoading)? 'inline-block' : 'none' }} /> {context.translation.send[context.locale]}</Button>  */}
                          <Button className="btn-exact" disabled={isLoading} style={{ background: (isLoading)? 'rgba(182, 25, 38, 0.616)': 'linear-gradient(-45deg, #B61927, #982827)', width: '100%' }}><Spinner color="light" size="sm" style={{ marginRight: '10px', display: (isLoading)? 'inline-block' : 'none' }} /> {context.translation.send[context.locale]}</Button> 
                            {/* <small className="small-link mb-1">{context.translation.create_account[context.locale]}</small> */}
                      </div>
                      <br />
                      <div className="d-flex flex-column align-items-left">
                        <small className="small-link" onClick={goToLogin}>← {context.translation.signin[context.locale]}</small>
                      </div>
                      <br />
                    </center>              
                  </Form>
                ):
                (<>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: '65px' }}>
                      <Avatar />
                    </div>
                    <div>
                      <h4 id="logo-namee" style={{ fontSize: '2.5em', marginTop: '-17px', marginLeft: 0 }}>xactplace</h4>
                    </div>
                  </div>

                  <center>
                    <h4>{ context.translation.using_new_device[context.locale] }</h4>
                    <p>{ context.translation.confirm_subheader[context.locale] }</p>
                    <Buttona loading={state.isLoading} onClick={handleSendVerificationKey} type="default">
                      { context.translation.proceed[context.locale] }
                    </Buttona>
                  </center>
                </>)
              }
            </>
        }
          
        </CardBody>
    </Card>
  )
}

export default LoginForm
