import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap'
import { Radio, Select, Form } from 'antd';
import ModalForm from '../../../../../user_plan/project/ProjectModalForm';

const { Option } = Select;

export default Form.create()(({ state, dispatch, context, initialErrorState, form: { getFieldDecorator, setFields, setFieldsValue }  }) => {
  function onChangeRadio(e) {
    let errorsProject = initialErrorState
    if (e.target.value === 'New') 
      errorsProject = initialErrorState.project
    

    dispatch({ type: 'SET_STATE', payload: { modalField: {...state.modalField, project: {...state.modalField.project, type: e.target.value} }, errors: errorsProject } })
  }

  function onChangeSelect(e) {    
    dispatch({ type: 'SET_STATE', payload: { modalField: {...state.modalField, project: {...state.modalField.project, id: e}} } })
  }

  useEffect(() => {
    // Execute only if tab is on "Exsiting" for both project and client
    if (['project', 'client'].every(property => state.modalField[property].type !== 'New')) {
      if (state.errors.project.proj_id.invalid) {
        setFields({
          proj_id: {
            errors: [new Error(state.errors.project.proj_id.message)],
          },
        });
      }
    }
  }, [state.errors])

  useEffect(() => {
    setFieldsValue({ proj_id: state.modalField.project.id });
  }, [state.modalField.project.id])

  return (
    <Container>
      {
        state.modalField.client.type !== 'New' &&
          <Row style={{ marginBottom: '24px' }}>
            <Col sm="12">
              <center>
                <Radio.Group onChange={onChangeRadio} value={state.modalField.project.type} buttonStyle="solid">
                  <Radio.Button value="New">{ context.translation.new[context.locale] }</Radio.Button>
                  <Radio.Button value="Existing">{ context.translation.existing[context.locale] }</Radio.Button>
                </Radio.Group>
              </center>
            </Col>
          </Row>
      }
      {
        ((state.modalField.client.type !== 'New' && state.modalField.project.type === 'New') || state.modalField.client.type === 'New') ?
          <ModalForm translation={context.translation} locale={context.locale} state={state} dispatch={dispatch} initialErrorState={initialErrorState} />
        :
        <Row>
          <Col sm="12">
            <span style={{ marginBottom: '6px', display: 'block' }}>{ context.translation.project[context.locale] }</span>
            <Form wrapperCol={{ xs: { span: 24 } }}>
              <Form.Item>
                {
                  getFieldDecorator('proj_id', { initialValue: state.modalField.project.id })(
                    <Select
                      showSearch
                      id="proj_id"
                      optionFilterProp="children"
                      onChange={onChangeSelect}
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value={-1} disabled>{context.translation.search_a_project[context.locale]}</Option>
                      {
                        state.projects.filter(e => e.client_id == state.modalField.client.id).map((project) => (<Option value={project.id} key={project.id}>{project.proj_name}</Option>))
                      }                  
                    </Select>
                  )
                }
              </Form.Item>
            </Form>
          </Col>
        </Row>

      }
    </Container>
  )
})