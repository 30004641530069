export default (state, action) => {

  switch (action.type) {
    case 'SET_STATE': 
      return {
        ...state,
        ...action.payload
      }

    case 'UPDATE_UPLOAD_STATE': 
      return {
        ...state,
        uploading: !state.uploading,
      }

    case 'UPDATE_POINTS': 
      const feedbacks = state.feedbacks.map(e => ({
        ...e,
        versions: e.versions.map(version => ({
          ...version,
          points: version.points.map(point => ({
            ...point,
            files: (action.state.point_id == point.id)? action.state.data: point.files
          }))
        }))
      })) 
      
      let payload = action.payload 
      payload.rowData.editedItem = {...state.editedItem}

      return {
        ...state,
        ...payload,
        feedbacks,
      }

    case 'REMOVE_FILE':
      let points = { 
        feedbacks: state.feedbacks.map(e => ({
          ...e,
          versions: e.versions.map(version => ({
            ...version,
            points: version.points.map(point => ({
              ...point,
              files: (state.editedItem.id == point.id)? point.files.filter(e => e.filename != action.payload.filename): point.files
            }))
          }))
        })) 
      }

      return {
        ...state,
        ...points
      }

    default: 
      return state
  }
}