import React, { useContext } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { Popover, Avatar, message, Icon } from 'antd';

export default ({ team }) => {
	let members = <></>,
		teams = ['', '', ''];
	team.forEach((e) => {
		// console.log(e)
		if (e.project_role === 'Project Leader') {
			teams[0] += e.name + '\n';
		} else if (e.project_role === 'Project Staff') {
			teams[1] += e.name + '\n';
		} else {
			teams[2] += e.name + '\n';
		}
	});
	if (teams[0] !== '' || teams[1] !== '' || teams[2] !== '') {
		members = teams.map((users, i) => {
			if (!!users[i])
				return (
					<Tooltip
						key={i}
						title={<div style={{ whiteSpace: 'pre-line' }}>{users}</div>}
					>
						<Avatar>{i === 0 ? 'PL' : i === 1 ? 'PS' : 'FP'}</Avatar>
					</Tooltip>
				);
		});
	}
	return (<p style={{ whiteSpace: 'pre-line' }}>{teams}</p>);
};
